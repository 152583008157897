import { Divider, Button, Checkbox } from "@mui/material";
import { createQueryParams } from "features/config/helpers";
import {
  useQuery,
  useAppDispatch,
  useAppSelector,
} from "features/config/hooks";
import { TeiHubLinkDataFilterModel } from "features/hublink/core/domain/tei-hub-link.model";
import {
  STOCK_ORDERING_BUTTON_STYLE,
  defaultQrOptions,
} from "features/shared/constants";
import { DataTable, DataList } from "features/shared/presentation/components";
import {
  Column,
  DataTableRow,
  DataTableCell,
} from "features/shared/presentation/components/data-table";
import { useState, useEffect } from "react";
import { CiFilter } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import { TeiHubLinkPlaceOrderModal } from "../modals";
import { resetTeiHubLinkOrder } from "../slices/tei-hub-link-order.slice";

import {
  selectTeiHubLinkStores,
  teiHubLinkStores,
} from "../slices/tei-hub-link-stores.slice";
import { TeiHubLinkDataFilter } from "./tei-hub-link-data-filter";
import {
  mobileCustomStatus,
  dateSetup,
  dateTypeMapper,
  StoreIdMapper,
} from "./tei-hub-link-utils";
import {
  TeiHubLinkSessionState,
  selectTeiHubLinkSession,
} from "../slices/tei-hub-link-session.slice";
import {
  TeiHubLinkDispatchedOrders,
  selectTeiHubLinkDispatchedOrders,
  teiHubLinkDispatchedOrders,
} from "../slices/tei-hub-link-dispatched-orders.slice";
import {
  TeiHubLinkOrders,
  resetTeiHubLinkOrders,
} from "../slices/tei-hub-link-orders.slice";
import { MdLibraryAddCheck } from "react-icons/md";
import { teiHubLinkDispatchMultiple } from "../slices/tei-hub-link-dispatch-multiple-orders.slice";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { createQrCode } from "features/shared/presentation/slices/create-qr-code.slice";

interface listDataParam {
  id: string;
  trackingNumber: string;
  status: string;
  storeName: string;
  companyName: string;
  placementDate: string;
  expectedDeliveryDate: string;
  actualDeliveryDate: string;
}

interface tableDataParam {
  id: string;
  number: number;
  trackingNumber: string;
  status: string;
  storeName: string;
  companyName: string;
  placementDate: string;
  expectedDeliveryDate: string;
  actualDeliveryDate: string;
  werehouse: string;
}

interface CheckedData {
  [key: string]: {
    id: string;
    value: boolean;
  };
}

export function TeiHubLinkLogisticReceiveMultipleOrdersContent() {
  const { clientType } = useParams();

  const query = useQuery();
  const pageNo = query.get("page_no");
  const perPage = query.get("per_page");
  const orderBy = query.get("order_by");
  const order = query.get("order");
  const search = query.get("search");
  const store = query.get("store");
  const dateType = query.get("dateType");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [checkedData, setCheckedData] = useState<CheckedData>();
  const [openFilter, setOpenFilter] = useState<HTMLButtonElement | null>(null);

  const orderState = useAppSelector(selectTeiHubLinkDispatchedOrders);
  const storeState = useAppSelector(selectTeiHubLinkStores);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);

  let columns: Array<Column> = [
    { id: "number", label: "#" },
    { id: "tracking_number", label: "Tracking Number" },
    { id: "company_name", label: "Store Company" },
    { id: "store_name", label: "Store" },
    {
      id: "created_at",
      label: "Placement date",
    },
    { id: "expected_delivery", label: "Expected Delivery Date" },
    { id: "actual_delivery", label: "Actual Delivery Date" },
    { id: "warehouse_name", label: "warehouse" },
    { id: "status", label: "status" },
  ];

  useEffect(() => {
    if (teiHubLinkSessionState.status === TeiHubLinkSessionState.success) {
      if (
        !orderState.data &&
        orderState.status !== TeiHubLinkDispatchedOrders.success &&
        clientType
      ) {
        const queryParams = createQueryParams({
          page_no: pageNo,
          per_page: perPage,
          order_by: orderBy,
          order: order,
          clientType: clientType,
          search: search,
          store: store,
          dateType: dateType,
          startDate: startDate,
          endDate: endDate,
        });

        dispatch(teiHubLinkDispatchedOrders(queryParams));
      } else if (orderState.status === TeiHubLinkDispatchedOrders.inProgress) {
        const queryParams = createQueryParams({
          page_no: pageNo,
          per_page: perPage,
          order_by: orderBy,
          order: order,
          clientType: clientType,
          search: search,
          store: store,
          dateType: dateType,
          startDate: startDate,
          endDate: endDate,
        });

        dispatch(teiHubLinkDispatchedOrders(queryParams));
      }
    }
  }, [
    dispatch,
    orderState.data,
    orderState.status,
    clientType,
    search,
    order,
    orderBy,
    perPage,
    pageNo,
    store,
    dateType,
    startDate,
    endDate,
    teiHubLinkSessionState.data,
  ]);

  const ListData = (props: {
    data: listDataParam;
    checkData: (data: {
      id: string;
      value: boolean;
      trackingNumber: string;
    }) => void;
  }) => {
    const { data, checkData } = props;
    const [check, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);

      checkData({
        id: data.id,
        value: event.target.checked,
        trackingNumber: data.trackingNumber,
      });
    };

    return (
      <div className="flex flex-col border border-gray-200 rounded-md shadow-sm p-2 bg-white space-y-1">
        <div>
          <Checkbox
            checked={checkedData?.[data.trackingNumber]?.value}
            name={data.trackingNumber}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
        <div
          onClick={() =>
            navigate(`/hublink/${clientType}/order/${data.trackingNumber}`)
          }
        >
          <div className="flex justify-between text-normal">
            <span className="normal-case font-bold">{`#${data.id} ${data.trackingNumber}`}</span>
            {mobileCustomStatus(data.status)}
          </div>

          <div className="flex flex-col">
            <span className="normal-case text-base font-semibold">
              {data.storeName}
            </span>
            <span className="text-sm">{data.companyName}</span>
          </div>

          <Divider />
          <div className="flex flex-wrap gap-2">
            <div className="flex flex-col text-xs capitalize">
              <span className="font-semibold">Placement date:</span>
              <span>{dateSetup(data.placementDate, true)}</span>
            </div>
            <div className="flex flex-col text-xs capitalize">
              <span className="font-semibold">Expected Delivery Date:</span>
              <span>{dateSetup(data.expectedDeliveryDate, true)}</span>
            </div>
            {data.actualDeliveryDate && (
              <div className="flex flex-col text-xs capitalize">
                <span className="font-semibold">Actual Delivery Date:</span>
                <span>
                  {data.actualDeliveryDate
                    ? dateSetup(data.actualDeliveryDate, true)
                    : "TBA"}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const TableData = (props: {
    data: tableDataParam;
    checkData: (data: {
      id: string;
      value: boolean;
      trackingNumber: string;
    }) => void;
  }) => {
    const { data, checkData } = props;
    const [check, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);

      checkData({
        id: data.id,
        value: event.target.checked,
        trackingNumber: data.trackingNumber,
      });
    };
    return (
      <>
        <Checkbox
          checked={checkedData?.[data.trackingNumber]?.value}
          name={data.trackingNumber}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <DataTableRow
          onClick={() => {
            dispatch(resetTeiHubLinkOrder());

            navigate(`/hublink/${clientType}/order/${data.trackingNumber}`);
          }}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            "&:hover": { backgroundColor: "#f5f5f5" },
          }}
        >
          <DataTableCell>{data.number}</DataTableCell>
          <DataTableCell>{data.trackingNumber}</DataTableCell>
          <DataTableCell>{data.companyName}</DataTableCell>
          <DataTableCell>{data.storeName}</DataTableCell>
          <DataTableCell>{dateSetup(data.placementDate, true)}</DataTableCell>
          <DataTableCell>
            {dateSetup(data.expectedDeliveryDate, true)}
          </DataTableCell>
          <DataTableCell>
            {data.actualDeliveryDate
              ? dateSetup(data.actualDeliveryDate, true)
              : "TBA"}
          </DataTableCell>
          <DataTableCell>{data.werehouse}</DataTableCell>
          <DataTableCell>{data.status}</DataTableCell>
        </DataTableRow>
      </>
    );
  };

  const dataFilter = (dataFilter: {
    type: string;
    value?: string | number | boolean;
  }) => {
    const params = {
      page_no: dataFilter.type === "onPageChange" ? dataFilter.value : pageNo,
      per_page:
        dataFilter.type === "onRowsPerPageChange" ? dataFilter.value : perPage,
      order_by:
        dataFilter.type === "onRequestSort" ? dataFilter.value : orderBy,
      order:
        dataFilter.type === "onRequestSort"
          ? orderBy === dataFilter.value && order === "asc"
            ? "desc"
            : "asc"
          : order,
      search:
        dataFilter.type === "onSearch"
          ? dataFilter.value === ""
            ? null
            : dataFilter.value
          : search,
      store: store,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
    };
    const queryParams = createQueryParams(params);

    dispatch(resetTeiHubLinkOrders());

    navigate({
      pathname: "",
      search: queryParams,
    });
  };

  const customButton = () => {
    return (
      <>
        <Button
          startIcon={<CiFilter />}
          variant="contained"
          sx={STOCK_ORDERING_BUTTON_STYLE}
          onClick={(event) => {
            const storeParam = createQueryParams({
              clientType: clientType,
            });
            dispatch(teiHubLinkStores(storeParam));
            setOpenFilter(event.currentTarget);
          }}
        >
          Advance Filter
        </Button>

        <Button
          startIcon={<MdLibraryAddCheck />}
          variant="contained"
          sx={STOCK_ORDERING_BUTTON_STYLE}
          onClick={handleLogisticReceiveMultiple}
        >
          Receive Multiple Orders
        </Button>
      </>
    );
  };

  const customFilterPill = () => {
    return (
      <div className="flex space-x-2">
        {dateType && startDate && endDate && (
          <div className="border border-gray-300 rounded-full w-fit px-3 py-1 shadow-sm text-xs space-x-2">
            <span
              className="cursor-pointer"
              onClick={() => {
                const queryParams = createQueryParams({
                  page_no: pageNo,
                  per_page: perPage,
                  order_by: orderBy,
                  order: order,
                  search: search,
                  store: store,
                  dateType: null,
                  startDate: null,
                  endDate: null,
                });
                dispatch(resetTeiHubLinkOrders());
                navigate({
                  pathname: "",
                  search: queryParams,
                });
              }}
            >
              X
            </span>
            <span>{`${dateTypeMapper(dateType)}: ${dateSetup(
              startDate ?? "",
              false
            )} to ${dateSetup(endDate ?? "", false)}`}</span>
          </div>
        )}
        <div className="flex flex-wrap gap-2">
          {store &&
            storeState.data &&
            JSON.parse(store).map(
              (storeDetail: string | number, index: number) => (
                <div
                  key={index}
                  className="border border-gray-300 rounded-full w-fit px-3 py-1 shadow-sm text-xs space-x-2"
                >
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      const modifiedStores = [
                        ...JSON.parse(store).slice(0, index),
                        ...JSON.parse(store).slice(index + 1),
                      ];
                      const queryParams = createQueryParams({
                        page_no: pageNo,
                        per_page: perPage,
                        order_by: orderBy,
                        order: order,
                        search: search,
                        store:
                          modifiedStores.length !== 0
                            ? JSON.stringify(modifiedStores)
                            : null,
                        dateType: dateType,
                        startDate: startDate,
                        endDate: endDate,
                      });
                      dispatch(resetTeiHubLinkOrders());
                      navigate({
                        pathname: "",
                        search: queryParams,
                      });
                    }}
                  >
                    X
                  </span>
                  <span>{StoreIdMapper(storeDetail, storeState.data)}</span>
                </div>
              )
            )}
        </div>
      </div>
    );
  };

  const handleLogisticReceiveMultiple = () => {
    {
      const filterSelectedOrder = orderState.data?.orders
        .filter((order) => checkedData?.[order.trackingNumber]?.value === true)
        .map((order) => order.id);

      const trackingNumbers = orderState.data?.orders
        .filter((order) => checkedData?.[order.trackingNumber]?.value === true)
        .map((order) => order.trackingNumber);

      dispatch(
        openMessageModal({
          message: `This process is irrevesible. Are you sure you want to proceed?`,
          buttons: [
            {
              color: "#CC5801",
              text: "Yes",
              onClick: () => {
                dispatch(
                  teiHubLinkDispatchMultiple({
                    id: filterSelectedOrder ?? [],
                  })
                );

                trackingNumbers?.map((trackingNumbers) => {
                  const link =
                    window.location.href.replace("/dispatched", "") +
                    "/order/" +
                    trackingNumbers +
                    "/order-receive?received=true";

                  dispatch(
                    createQrCode({
                      qrFieldsData: {
                        link: link,
                        forceCreate: false,
                      },
                      title: `HubLink-trackingNo:${trackingNumbers}`,
                      format: "png",
                      qrOptions: defaultQrOptions,
                    })
                  );
                });

                dispatch(closeMessageModal());
              },
            },
            {
              color: "#22201A",
              text: "No",
              onClick: () => {
                dispatch(closeMessageModal());
              },
            },
          ],
        })
      );
    }
  };

  const handleCheckData = (data: {
    id: string;
    trackingNumber: string;
    value: boolean;
  }) => {
    const updateCheckData = {
      id: data.id,
      value: data.value,
    };

    setCheckedData((prevData) => ({
      ...prevData,
      [data.trackingNumber]: updateCheckData,
    }));
  };

  return (
    <>
      <div className="space-y-3">
        <div className="flex">
          <span className="text-secondary text-xl font-['Bebas_Neue'] flex-1 lg:text-3xl">
            {"tei hub link > Order List"}
          </span>
        </div>

        {orderState.data && (
          <div className="border border-gray-200 rounded-md w-full bg-white p-2">
            <div className="hidden lg:block">
              <DataTable
                order={order === "asc" ? "asc" : "desc"}
                orderBy={orderBy ?? "last_updated"}
                search={search ?? ""}
                emptyMessage={`No orders yet. 😔`}
                onSearch={(val) => dataFilter({ type: "onSearch", value: val })}
                onRequestSort={(column_selected) =>
                  column_selected !== "number" &&
                  dataFilter({ type: "onRequestSort", value: column_selected })
                }
                columns={columns}
                onRowsPerPageChange={(event) => {
                  if (perPage !== event.target.value) {
                    dataFilter({
                      type: "onRowsPerPageChange",
                      value: event.target.value,
                    });
                  }
                }}
                onPageChange={(event, newPage) => {
                  const pageNoInt = pageNo ? parseInt(pageNo) : null;
                  if (newPage !== pageNoInt) {
                    dataFilter({
                      type: "onPageChange",
                      value: newPage,
                    });
                  }
                }}
                totalRows={orderState.data?.pagination.totalRows}
                perPage={orderState.data?.pagination.perPage}
                page={pageNo ? Number(pageNo) : 1}
                customButton={<>{customButton()}</>}
                customPill={<>{customFilterPill()}</>}
              >
                {orderState.data?.orders.map((order, index) => (
                  <TableData
                    data={{
                      id: order.id,
                      number: index + 1,
                      trackingNumber: order.trackingNumber,
                      status: order.statusName,
                      storeName: order.storeName,
                      companyName: order.companyName,
                      placementDate: order.createDate,
                      expectedDeliveryDate: order.expectedDeliveryDate,
                      actualDeliveryDate: order.actualDeliveryDate ?? "",
                      werehouse: order.warehouseName,
                    }}
                    checkData={handleCheckData}
                  />
                ))}
              </DataTable>
            </div>

            <div className="block lg:hidden">
              <DataList
                search={search ?? ""}
                emptyMessage={`No orders yet. 😔`}
                onSearch={(val) => dataFilter({ type: "onSearch", value: val })}
                onRowsPerPageChange={(event) =>
                  perPage !== event.target.value &&
                  dataFilter({
                    type: "onRowsPerPageChange",
                    value: event.target.value,
                  })
                }
                onPageChange={(event, newPage) => {
                  dataFilter({
                    type: "onPageChange",
                    value:
                      newPage !== (pageNo ? parseInt(pageNo) : null) && newPage,
                  });
                }}
                totalRows={orderState.data?.pagination.totalRows}
                perPage={orderState.data?.pagination.perPage}
                page={pageNo ? parseInt(pageNo) : 1}
                customButton={
                  <>
                    <Button
                      startIcon={<MdLibraryAddCheck />}
                      variant="contained"
                      sx={STOCK_ORDERING_BUTTON_STYLE}
                      onClick={handleLogisticReceiveMultiple}
                    >
                      Dispatch Multiple
                    </Button>
                  </>
                }
              >
                <div className="space-y-2 mt-2">
                  {orderState.data?.orders.map((order, index) => (
                    <ListData
                      data={{
                        id: (index + 1).toString(),
                        trackingNumber: order.trackingNumber,
                        status: order.statusName,
                        storeName: order.storeName,
                        companyName: order.companyName,
                        placementDate: order.createDate,
                        expectedDeliveryDate: order.expectedDeliveryDate,
                        actualDeliveryDate: order.actualDeliveryDate ?? "",
                      }}
                      checkData={handleCheckData}
                    />
                  ))}
                </div>
              </DataList>
            </div>
          </div>
        )}
      </div>

      <TeiHubLinkDataFilter
        anchor={openFilter}
        onClose={() => setOpenFilter(null)}
        filter={(data: TeiHubLinkDataFilterModel | string) => {
          if (typeof data !== "string" && clientType) {
            const storeIds = data.store?.flatMap((store) =>
              store.flatMap((stores) => stores.storeCode)
            );

            const queryParams = createQueryParams({
              page_no: pageNo,
              per_page: perPage,
              order_by: orderBy,
              order: order,
              search: search,
              store: storeIds ? JSON.stringify(storeIds) : null,
              dateType: data.type ?? null,
              startDate: data.start ?? null,
              endDate: data.end ?? null,
            });

            dispatch(resetTeiHubLinkOrders());

            navigate({
              pathname: "",
              search: queryParams,
            });
          } else {
            navigate({
              pathname: "",
            });
          }
        }}
      />
    </>
  );
}
