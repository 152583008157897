import { Outlet } from "react-router-dom";
import { TeiHubLinkDrawer } from "./tei-hub-link-drawer";

export function TeiHubLinkSidebarWrapper() {
  return (
    <main className="flex min-h-screen">
      <TeiHubLinkDrawer />
      <section
        id="audit-main-section"
        className="flex-1 h-screen overflow-y-auto bg-paper"
      >
        <Outlet />
      </section>
    </main>
  );
}
