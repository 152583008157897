import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { GetAllCashDepositModel } from "features/sales/core/domain/get-all-cash-deposit.model";
import {
  GetAllCashDepositRepository,
  GetAllCashDepositResponse,
} from "features/sales/data/sales.repository";
import { RootState } from "features/config/store";

export enum GetAllCashDepositState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: GetAllCashDepositState;
  message: string;
  data: GetAllCashDepositModel | undefined;
}

const initialState: InitialState = {
  status: GetAllCashDepositState.initial,
  message: "",
  data: undefined,
};

export const getAllCashDeposit = createAsyncThunk(
  "getAllCashDeposit",
  async (query: string, { rejectWithValue }) => {
    try {
      const response: GetAllCashDepositResponse =
        await GetAllCashDepositRepository(query);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }

        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const getAllCashDepositSlice = createSlice({
  name: "getAllCashDeposit",
  initialState,
  reducers: {
    resetGetAllCashDepositStatus: (state) => {
      state.status = GetAllCashDepositState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCashDeposit.pending, (state) => {
        state.status = GetAllCashDepositState.inProgress;
      })
      .addCase(getAllCashDeposit.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = GetAllCashDepositState.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(getAllCashDeposit.rejected, (state, action) => {
        state.status = GetAllCashDepositState.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectGetAllCashDeposit = (state: RootState) =>
  state.getAllCashDeposit;

export const { resetGetAllCashDepositStatus } = getAllCashDepositSlice.actions;

export default getAllCashDepositSlice.reducer;
