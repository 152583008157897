import {
  StockOrderHead,
  StockOrderSettingsGeneralContent,
} from "../components";

export function StockOrderSettingsGeneralPage() {
  return (
    <>
      <StockOrderHead
        StockOrderBreadCrumbsProps={{
          home: {
            title: "Home",
            url: "/admin/material/dashboard",
          },
          className: "lg:h-[200px]",
          pageTitles: [
            {
              name: "Settings",
              url: "/admin/material/settings/set-minimum-order-amount",
            },
            {
              name: "minimum-order-amount",
              url: "/admin/material/settings/set-minimum-order-amount",
            },
          ],
        }}
      />
      <div className="p-8">
        <StockOrderSettingsGeneralContent />
      </div>
    </>
  );
}
