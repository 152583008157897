import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { selectGetHrSession } from "../slices/get-hr-session.slice";
import { logoutHr } from "../slices/logout-hr.slice";
import { useEffect } from "react";
import {
  getHrStaff180,
  selectGetHrStaff180,
} from "../slices/get-staff-180.slice";

export function Hr180ViewingDashboardPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getHrSessionState = useAppSelector(selectGetHrSession);
  const getHrStaff180State = useAppSelector(selectGetHrStaff180);

  useEffect(() => {
    dispatch(getHrStaff180());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Taters | 180 Degree Assessment</title>
      </Helmet>
      <main className="min-h-screen text-[#242424] flex flex-col  border-b-[#F2F2F2]">
        <div className="border-b h-[50px] px-[24px] flex items-center flex justify-between flex-initial">
          <img
            onClick={() => {
              navigate("/hr/dashboard");
            }}
            src="https://www.ilovetaters.com/api/assets/images/shared/logo/taters-logo.png"
            alt="Taters Logo"
            className="w-[80px] cursor-pointer"
          />
          <div className="flex items-center space-x-8">
            <div className="flex flex-col justify-center items-center">
              <img
                alt=""
                className="rounded-[50%] w-[25px] h-[25px] bg-[#F2F2F2] border border-gray "
                src="https://miro.medium.com/v2/resize:fill:32:32/1*dmbNkD5D-u45r44go_cf0g.png"
                loading="lazy"
                role="presentation"
              />
              <span className="text-[11px] text-[#6B6B6B] font-[400] hover:text-black cursor-pointer ">
                {getHrSessionState.data?.hr.user_personal_details?.first_name}{" "}
                {getHrSessionState.data?.hr.user_personal_details?.last_name}
              </span>
            </div>

            <span
              onClick={() => {
                dispatch(logoutHr());
              }}
              className="text-[11px] font-[400] hover:text-black cursor-pointer bg-red-700 px-4 pt-[1px] pb-[2px] rounded-full text-white"
            >
              Logout
            </span>
          </div>
        </div>

        <section className="py-8 px-8 space-y-2">
          <h1 className="font-bold text-lg mb-4">180 Degree</h1>

          <div className="flex flex-wrap space-x-4">
            {getHrStaff180State.data?.map((value, index) => (
              <div className="bg-white shadow-md flex flex-col cursor-pointer w-[250px] h-[80px] p-[14px] ">
                <div className="flex-1">
                  <h2 className="font-semibold text-lg">{value.name}</h2>
                </div>

                <div className="space-x-1">
                  <span className="text-[12px] font-semibold bg-[#FF9F1A] rounded-[4px] text-white px-[5px] py-[2px]">
                    {value.position}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </>
  );
}
