import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { TeiHubLinkOrdersModel } from "features/hublink/core/domain/tei-hub-link.model";
import {
  GetTeiHubLinkCompletedOrderResponse,
  GetTeiHubLinkCompletedOrderRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkCompletedOrder {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkCompletedOrder;
  message: string;
  data: TeiHubLinkOrdersModel | undefined;
}

const initialState: InitialState = {
  status: TeiHubLinkCompletedOrder.initial,
  message: "",
  data: undefined,
};

export const teiHubLinkCompletedOrder = createAsyncThunk(
  "teiHubLinkCompletedOrder",
  async (param: string, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkCompletedOrderResponse =
        await GetTeiHubLinkCompletedOrderRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkCompletedOrderSlice = createSlice({
  name: "teiHubLinkCompletedOrder",
  initialState,
  reducers: {
    resetTeiHubLinkCompletedOrder: (state) => {
      state.status = TeiHubLinkCompletedOrder.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkCompletedOrder.pending, (state) => {
        state.status = TeiHubLinkCompletedOrder.inProgress;
      })
      .addCase(teiHubLinkCompletedOrder.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = TeiHubLinkCompletedOrder.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(teiHubLinkCompletedOrder.rejected, (state, action) => {
        state.status = TeiHubLinkCompletedOrder.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectTeiHubLinkCompletedOrder = (state: RootState) =>
  state.teiHubLinkCompletedOrder;

export const { resetTeiHubLinkCompletedOrder } =
  teiHubLinkCompletedOrderSlice.actions;
export default teiHubLinkCompletedOrderSlice.reducer;
