import { REACT_APP_DOMAIN_URL } from "features/shared/constants";

export function HeaderNavEmphr() {
  return (
    <>
      <header className={`sticky w-full top-0 z-20`}>
        <div className={` w-full bg-primary shadow-2xl`}>
          <nav
            className={`flex justify-between items-center container py-2 h-[64px]`}
          >
            <img
              src={`${REACT_APP_DOMAIN_URL}api/assets/images/shared/logo/taters-logo.png`}
              alt="Taters Logo"
              className="w-[150px] lg:w-[120px]"
            />
            <div className="flex items-center justify-center pt-3 space-x-4">
              <div className="flex items-center justify-center space-x-3 lg:space-x-6"></div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
