import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { PriceIncreaseParam } from "features/stock-ordering/core/stock-ordering.params";
import {
  insertPriceIncreaseResponse,
  insertPriceIncreaseRepository,
} from "features/stock-ordering/data/stock-ordering.repository";

export enum insertPriceIncreaseState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: insertPriceIncreaseState;
  message: string;
}

const initialState: InitialState = {
  status: insertPriceIncreaseState.initial,
  message: "",
};

export const insertPriceIncrease = createAsyncThunk(
  "insertPriceIncrease",
  async (param: PriceIncreaseParam, { rejectWithValue }) => {
    try {
      const response: insertPriceIncreaseResponse =
        await insertPriceIncreaseRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const insertPriceIncreaseSlice = createSlice({
  name: "insertPriceIncrease",
  initialState,
  reducers: {
    resetinsertPriceIncrease: (state) => {
      state.status = insertPriceIncreaseState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertPriceIncrease.pending, (state) => {
        state.status = insertPriceIncreaseState.inProgress;
      })
      .addCase(insertPriceIncrease.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = insertPriceIncreaseState.success;
          state.message = message;
        }
      })
      .addCase(insertPriceIncrease.rejected, (state, action) => {
        state.status = insertPriceIncreaseState.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectinsertPriceIncrease = (state: RootState) =>
  state.insertPriceIncrease;

export const { resetinsertPriceIncrease } = insertPriceIncreaseSlice.actions;
export default insertPriceIncreaseSlice.reducer;
