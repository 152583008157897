import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { OrderDataParam } from "features/hublink/core/tei-hub-link.param";
import {
  GetTeiHubLinkPlaceOrderResponse,
  GetTeiHubLinkPlaceOrderRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkPlaceOrder {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkPlaceOrder;
  message: string;
}

const initialState: InitialState = {
  status: TeiHubLinkPlaceOrder.initial,
  message: "",
};

export const teiHubLinkPlaceOrder = createAsyncThunk(
  "teiHubLinkPlaceOrder",
  async (param: OrderDataParam, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkPlaceOrderResponse =
        await GetTeiHubLinkPlaceOrderRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkPlaceOrderSlice = createSlice({
  name: "teiHubLinkPlaceOrder",
  initialState,
  reducers: {
    resetTeiHubLinkPlaceOrder: (state) => {
      state.status = TeiHubLinkPlaceOrder.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkPlaceOrder.pending, (state) => {
        state.status = TeiHubLinkPlaceOrder.inProgress;
      })
      .addCase(teiHubLinkPlaceOrder.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = TeiHubLinkPlaceOrder.success;
          state.message = message;
        }
      })
      .addCase(teiHubLinkPlaceOrder.rejected, (state, action) => {
        state.status = TeiHubLinkPlaceOrder.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectTeiHubLinkPlaceOrder = (state: RootState) =>
  state.teiHubLinkPlaceOrder;

export const { resetTeiHubLinkPlaceOrder } = teiHubLinkPlaceOrderSlice.actions;
export default teiHubLinkPlaceOrderSlice.reducer;
