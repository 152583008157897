import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { TeiHubLinkStoresModel } from "features/hublink/core/domain/tei-hub-link.model";
import {
  GetTeiHubLinkStoresResponse,
  GetTeiHubLinkStoresRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkStores {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkStores;
  message: string;
  data: TeiHubLinkStoresModel | undefined;
}

const initialState: InitialState = {
  status: TeiHubLinkStores.initial,
  message: "",
  data: undefined,
};

export const teiHubLinkStores = createAsyncThunk(
  "teiHubLinkStores",
  async (param: string, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkStoresResponse =
        await GetTeiHubLinkStoresRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkStoresSlice = createSlice({
  name: "teiHubLinkStores",
  initialState,
  reducers: {
    resetTeiHubLinkStores: (state) => {
      state.status = TeiHubLinkStores.inProgress;
      state.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkStores.pending, (state) => {
        state.status = TeiHubLinkStores.inProgress;
      })
      .addCase(teiHubLinkStores.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = TeiHubLinkStores.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(teiHubLinkStores.rejected, (state, action) => {
        state.status = TeiHubLinkStores.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectTeiHubLinkStores = (state: RootState) =>
  state.teiHubLinkStores;

export const { resetTeiHubLinkStores } = teiHubLinkStoresSlice.actions;
export default teiHubLinkStoresSlice.reducer;
