import { useEffect } from "react";
import { AssessmentSection } from "./assessment-section";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import getAppraisalFormInfo, {
  getHrAppraisalFormInfo,
  selectGetHrAppraisalFormInfo,
} from "../slices/get-appraisal-form-info";

interface AssessmentInfoProps {
  title: string;
}

export function AssessmentInfo(props: AssessmentInfoProps) {
  const dispatch = useAppDispatch();

  const getHrAppraisalFormInfoState = useAppSelector(
    selectGetHrAppraisalFormInfo
  );

  useEffect(() => {
    let info = 1;

    switch (props.title) {
      case "180 Degree Assessment Answer":
        info = 3;
        break;
      case "180 Degree Assessment Form":
        info = 3;
        break;
      case "Management Assessment Form":
        info = 2;
        break;
      case "Employee Self Assessment Form":
        info = 1;
        break;
      case "Staff Assessment Answer":
        info = 1;
        break;
      case "180 Degree Assessment":
        info = 3;
        break;
      case "Self Assessment":
        info = 1;
        break;
      case "Management Assessment":
        info = 2;
        break;
      case "Staff Self Assessment":
        info = 1;
        break;
      default:
        break;
    }
    dispatch(getHrAppraisalFormInfo(info));
  }, [dispatch]);

  return (
    <AssessmentSection className="px-6 py-3">
      <div className="bg-primary h-[10px] absolute w-full left-0 top-0" />
      <div className="space-y-4">
        <h1 className="text-[24pt] uppercase tracking-wide">{props.title}</h1>

        <h2 className="text-[11pt]">
          {getHrAppraisalFormInfoState.data?.subtitle}
        </h2>

        <h2 className="text-[11pt]">
          <b>Note:</b> {getHrAppraisalFormInfoState.data?.note}
        </h2>
        <hr className="absolute w-full left-0" />

        <h2 className="text-[11pt] text-red-600 text-[14px] pt-3">
          * Indicates required question
        </h2>
      </div>
    </AssessmentSection>
  );
}
