import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { GetPriceHistoryModel } from "features/stock-ordering/core/domain/get-price-history.model";
import {
  GetPriceHistoryRepository,
  GetPriceHistoryResponse,
} from "features/stock-ordering/data/stock-ordering.repository";

export enum GetPriceHistoryState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: GetPriceHistoryState;
  message: string;
  data: GetPriceHistoryModel | undefined;
}

const initialState: InitialState = {
  status: GetPriceHistoryState.initial,
  message: "",
  data: undefined,
};

export const getPriceHistory = createAsyncThunk(
  "getPriceHistory",
  async (param: string, { rejectWithValue }) => {
    try {
      const response: GetPriceHistoryResponse = await GetPriceHistoryRepository(
        param
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const getPriceHistorySlice = createSlice({
  name: "getPriceHistory",
  initialState,
  reducers: {
    resetGetPriceHistory: (state) => {
      state.status = GetPriceHistoryState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPriceHistory.pending, (state) => {
        state.status = GetPriceHistoryState.inProgress;
      })
      .addCase(getPriceHistory.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = GetPriceHistoryState.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(getPriceHistory.rejected, (state, action) => {
        state.status = GetPriceHistoryState.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectGetPriceHistory = (state: RootState) =>
  state.getPriceHistory;

export const { resetGetPriceHistory } = getPriceHistorySlice.actions;
export default getPriceHistorySlice.reducer;
