import axios from "axios";
import {
  HrAppraisalSummaryModel,
  HrAppraisalSummaryWeightsModel,
} from "features/hr/core/domain/hr-appraisal-summary.model";
import { HrAppraisalDirectReportStaffModel } from "features/hr/core/domain/hr-appraisal-direct-report-staff";
import { HrAppraisalResponseModel } from "features/hr/core/domain/hr-appraisal-response.model";
import { HrAttendanceAndPunctualityGradeModel } from "features/hr/core/domain/hr-attendance-and-punctuality-grade.model";
import { HrCommentsModel } from "features/hr/core/domain/hr-comments.model";
import { HrCoreCompetencyGradeModel } from "features/hr/core/domain/hr-core-competency-grade.model";
import { HrFunctionalCompetencyAndPunctualityGradeModel } from "features/hr/core/domain/hr-functional-competency-and-punctuality-grade.model";
import { HrKraKpiGradeModel } from "features/hr/core/domain/hr-kra-kpi-grade.model";
import { HrKrasModel } from "features/hr/core/domain/hr-kras.model";
import { HrPerformanceCriteriaModel } from "features/hr/core/domain/hr-performance-criteria.model";
import { HrRatingScaleModel } from "features/hr/core/domain/hr-rating-scale.model";
import {
  GetHrEvaluateeAndEvaluatorInfoParam,
  GetHrAppraisalResponseParam,
  GetHrCommentsParam,
  GetHrCoreCompetencyGradeParam,
  GetHrFunctionalCompetencyAndPunctualityParam,
  GetHrKraKpiGradeParam,
  SubmitAssessmentParam,
  SubmitKraParam,
  UpdateKraParam,
  GetHrActionItemParam,
  SaveAssessmentParam,
} from "features/hr/core/hr.params";
import { REACT_APP_DOMAIN_URL } from "features/shared/constants";
import { HrStaff180Model } from "features/hr/core/domain/hr-staff-180.model";
import { HrEvaluateeAndEvaluatorInfoModel } from "features/hr/core/domain/hr-evaluatee-and-evaluator-info";
import { GetHrActionItemModel } from "features/hr/core/domain/get-hr-action-item.model";
import { GetHrAppraisalFormInfoModel } from "features/hr/core/domain/hr-appraisal-form-info.model";

export interface GetHrPerformanceCriteriaResponse {
  data: {
    message: string;
    data: HrPerformanceCriteriaModel;
  };
}

export interface GetHrRatingScaleResponse {
  data: {
    message: string;
    data: HrRatingScaleModel;
  };
}

export interface GetHrKraKpiGradeResponse {
  data: {
    message: string;
    data: HrKraKpiGradeModel;
  };
}

export interface GetHrCoreCompetencyGradeResponse {
  data: {
    message: string;
    data: HrCoreCompetencyGradeModel;
  };
}

export interface GetHrFunctionalCompetencyAndPunctualityGradeResponse {
  data: {
    message: string;
    data: HrFunctionalCompetencyAndPunctualityGradeModel;
  };
}
export interface GetHrAttendanceAndPunctualityGradeResponse {
  data: {
    message: string;
    data: HrAttendanceAndPunctualityGradeModel;
  };
}
export interface SubmitAssessmentResponse {
  data: {
    message: string;
  };
}

export interface SubmitKraResponse {
  data: {
    message: string;
  };
}
export interface GetHrKrasResponse {
  data: {
    message: string;
    data: HrKrasModel;
  };
}

export interface UpdateKraResponse {
  data: {
    message: string;
  };
}

export interface GetHrCommentsResponse {
  data: {
    message: string;
    data: HrCommentsModel;
  };
}

export interface GetHrAppraisalResponseResponse {
  data: {
    message: string;
    data: HrAppraisalResponseModel;
  };
}

export interface GetHrAppraisalDirectReportStaffResponse {
  data: {
    message: string;
    data: HrAppraisalDirectReportStaffModel;
  };
}

export interface GetHrAppraisalSummaryResponse {
  data: {
    message: string;
    weights: Array<HrAppraisalSummaryWeightsModel>;
    data: Array<HrAppraisalSummaryModel>;
  };
}

export interface HrImportUsersResponse {
  data: {
    message: string;
  };
}
export interface GetHrStaff180Response {
  data: {
    message: string;
    data: Array<HrStaff180Model>;
  };
}

export interface GetHrEvaluateeAndEvaluatorInfoResponse {
  data: {
    message: string;
    data: HrEvaluateeAndEvaluatorInfoModel;
  };
}

export interface GetHrActionItemResponse {
  data: {
    message: string;
    data: GetHrActionItemModel;
  };
}

export interface SaveAssessmentResponse {
  data: {
    message: string;
  };
}

export interface GetHrAppraisalFormInfoResponse {
  data: {
    message: string;
    data: GetHrAppraisalFormInfoModel;
  };
}

export function GetHrAppraisalFormInfoRepository(
  appraisal_responses_type_id: number
): Promise<GetHrAppraisalFormInfoResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/form/info/${appraisal_responses_type_id}`,
    {
      withCredentials: true,
    }
  );
}

export function SaveAssessmentRepository(
  param: SaveAssessmentParam
): Promise<SaveAssessmentResponse> {
  return axios.post(`${REACT_APP_DOMAIN_URL}api/hr/appraisal/save`, param, {
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true,
  });
}

export function GetHrActionItemRepository(
  param: GetHrActionItemParam
): Promise<GetHrActionItemResponse> {
  return axios.post(`${REACT_APP_DOMAIN_URL}api/hr/get-action-item`, param, {
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true,
  });
}

export function GetHrEvaluateeAndEvaluatorInfoRepository(
  param: GetHrEvaluateeAndEvaluatorInfoParam
): Promise<GetHrEvaluateeAndEvaluatorInfoResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hr/get-evaluatee-and-evaluator-info`,
    param,
    {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: true,
    }
  );
}

export function GetHrStaff180Repository(): Promise<GetHrStaff180Response> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hr/staff-180-degree`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
}

export function HrImportUsersRepository(
  param: FormData
): Promise<HrImportUsersResponse> {
  return axios.post(`${REACT_APP_DOMAIN_URL}api/hr/import-users`, param, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
}
export function GetHrAppraisalSummaryRepository(): Promise<GetHrAppraisalSummaryResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hr/appraisal/summary`, {
    withCredentials: true,
  });
}

export function GetHrAppraisalDirectReportStaffRepository(
  staff_id: string
): Promise<GetHrAppraisalDirectReportStaffResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hr/direct-report-staff/staff/${staff_id}`,
    {
      withCredentials: true,
    }
  );
}

export function GetHrAppraisalResponseRepository(
  param: GetHrAppraisalResponseParam
): Promise<GetHrAppraisalResponseResponse> {
  return axios.post(`${REACT_APP_DOMAIN_URL}api/hr/appraisal/response`, param, {
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true,
  });
}
export function GetHrCommentsRepository(
  param: GetHrCommentsParam
): Promise<GetHrCommentsResponse> {
  return axios.post(`${REACT_APP_DOMAIN_URL}api/hr/appraisal/comments`, param, {
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true,
  });
}

export function UpdateKraRepository(
  param: UpdateKraParam
): Promise<UpdateKraResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/update-kra-or-kpi`,
    param,
    {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: true,
    }
  );
}

export function GetHrKrasRepository(
  user_id: string
): Promise<GetHrKrasResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/kra-or-kpi/${user_id}`,
    {
      withCredentials: true,
    }
  );
}

export function SubmitKraRepository(
  param: SubmitKraParam
): Promise<SubmitKraResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/kra-or-kpi/${param.user_id}`,
    param,
    {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: true,
    }
  );
}

export function SubmitAssessmentRepository(
  param: SubmitAssessmentParam
): Promise<SubmitAssessmentResponse> {
  return axios.post(`${REACT_APP_DOMAIN_URL}api/hr/appraisal/submit`, param, {
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true,
  });
}

export function GetHrAttendanceAndPunctualityGradeRepository(): Promise<GetHrAttendanceAndPunctualityGradeResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/attendance-and-punctuality`,
    {
      withCredentials: true,
    }
  );
}
export function GetHrFunctionalCompetencyAndPunctualityGradeRepository(
  param: GetHrFunctionalCompetencyAndPunctualityParam
): Promise<GetHrFunctionalCompetencyAndPunctualityGradeResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/functional-competency-and-punctuality-grade`,
    param,
    {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: true,
    }
  );
}

export function GetHrCoreCompetencyGradeRepository(
  param: GetHrCoreCompetencyGradeParam
): Promise<GetHrCoreCompetencyGradeResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/core-competency-grade`,
    param,
    {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: true,
    }
  );
}

export function GetHrKraKpiGradeRepository(
  param: GetHrKraKpiGradeParam
): Promise<GetHrKraKpiGradeResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/kra-kpi-grade`,
    param,
    {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: true,
    }
  );
}

export function GetHrRatingScaleRepository(): Promise<GetHrRatingScaleResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hr/appraisal/rating-scale`, {
    withCredentials: true,
  });
}

export function GetHrPerformanceCriteriaRepository(): Promise<GetHrPerformanceCriteriaResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hr/appraisal/performance-criteria`,
    {
      withCredentials: true,
    }
  );
}
