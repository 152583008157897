import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { minimumOrderAmountParam } from "features/stock-ordering/core/stock-ordering.params";
import {
  updateMinimumTotalAmountResponse,
  updateMinimumTotalAmountRepository,
} from "features/stock-ordering/data/stock-ordering.repository";

export enum UpdateMinimumTotalAmountState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: UpdateMinimumTotalAmountState;
  message: string;
}

const initialState: InitialState = {
  status: UpdateMinimumTotalAmountState.initial,
  message: "",
};

export const updateMinimumTotalAmount = createAsyncThunk(
  "updateMinimumTotalAmount",
  async (param: minimumOrderAmountParam, { rejectWithValue }) => {
    try {
      const response: updateMinimumTotalAmountResponse =
        await updateMinimumTotalAmountRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const updateMinimumTotalAmountSlice = createSlice({
  name: "updateMinimumTotalAmount",
  initialState,
  reducers: {
    resetupdateMinimumTotalAmount: (state) => {
      state.status = UpdateMinimumTotalAmountState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateMinimumTotalAmount.pending, (state) => {
        state.status = UpdateMinimumTotalAmountState.inProgress;
      })
      .addCase(updateMinimumTotalAmount.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = UpdateMinimumTotalAmountState.success;
          state.message = message;
        }
      })
      .addCase(updateMinimumTotalAmount.rejected, (state, action) => {
        state.status = UpdateMinimumTotalAmountState.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectUpdateMinimumTotalAmount = (state: RootState) =>
  state.updateMinimumTotalAmount;

export const { resetupdateMinimumTotalAmount } =
  updateMinimumTotalAmountSlice.actions;
export default updateMinimumTotalAmountSlice.reducer;
