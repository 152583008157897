import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "features/config/store";

interface InitialState {
  status: boolean;
}

const initialState: InitialState = {
  status: true,
};

export const teiHubLinkSideBarSlice = createSlice({
  name: "teiHubLinkSideBar",
  initialState,
  reducers: {
    openTeiHubLinkSideBar: (state) => {
      state.status = true;
    },
    closeTeiHubLinkSideBar: (state) => {
      state.status = false;
    },
    toggleTeiHubLinkSideBar: (state) => {
      state.status = !state.status;
    },
  },
});

export const selectTeiHubLinkSideBar = (state: RootState) =>
  state.teiHubLinkSideBar;

export const {
  openTeiHubLinkSideBar,
  closeTeiHubLinkSideBar,
  toggleTeiHubLinkSideBar,
} = teiHubLinkSideBarSlice.actions;

export default teiHubLinkSideBarSlice.reducer;
