import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createQueryParams } from "features/config/helpers";
// import { TicketingTriageModal } from "../modals/ticketing-triage.modal";
import { DataList } from "features/shared/presentation/components";
import {
  useAppDispatch,
  useAppSelector,
  useQuery,
} from "features/config/hooks";
import {
  Column,
  DataTable,
  DataTableCell,
  DataTableRow,
} from "features/shared/presentation/components/data-table";
import {
  getCashDeposit,
  resetGetCashDepositStatus,
  selectGetCashDeposit,
} from "../slices/get-cash-deposit.slice";
import { MdDashboard } from "react-icons/md";

// Table Columns
const columns: Array<Column> = [
  { id: "transactionDate", label: "Transaction Date" },
  { id: "referenceId", label: "Reference ID" },
  { id: "cashierName", label: "Cashier Name" },
  { id: "actions", label: "Actions" },
];

export function CashDepositContents() {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const pageNo = query.get("page_no");
  const perPage = query.get("per_page");
  const status = query.get("status");
  const id = query.get("id");
  const orderBy = query.get("order_by");
  const order = query.get("order");
  const search = query.get("search");

  // const [openTriageModal, setOpenTriageModal] = React.useState(false);

  const getCashDepositStates = useAppSelector(selectGetCashDeposit);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getTicket(id)).then(() => {
  //       setOpenTriageModal(true);
  //     });
  //   }
  // }, [dispatch, id]);

  useEffect(() => {
    const query = createQueryParams({
      page_no: pageNo,
      per_page: perPage,
      status: status,
      order_by: orderBy,
      order: order,
      search: search,
    });
    dispatch(getCashDeposit(query));
  }, [dispatch, pageNo, status, perPage, orderBy, order, search]);

  return (
    <>
      <div className="flex flex-col px-4 lg:flex-row lg:items-end">
        <span className="text-secondary text-3xl font-['Bebas_Neue'] flex-1">
          Pending Cash Deposit
        </span>
        <div className="flex flex-col space-y-1 lg:flex-row lg:space-x-4 lg:space-y-0">
          <div>
            <Link
              to="../cash-deposit"
              className="inline-flex items-center px-4 tracking-wide py-1  bg-button font-['Varela_Round'] text-white text-xs rounded-md font-700"
            >
              <MdDashboard size={20} />
              <span>&nbsp;&nbsp;Dashboard</span>
            </Link>
          </div>
        </div>
      </div>

      {getCashDepositStates.data?.cash_deposits ? (
        <>
          {/* Mobile View */}
          <div className="p-4 lg:hidden">
            <DataList
              search={search ?? ""}
              emptyMessage="No data yet."
              onSearch={(val) => {
                const params = {
                  page_no: null,
                  per_page: perPage,
                  status: status,
                  order_by: orderBy,
                  order: order,
                  search: val === "" ? null : val,
                };

                const queryParams = createQueryParams(params);

                navigate({
                  pathname: "",
                  search: queryParams,
                });
              }}
              onRowsPerPageChange={(event) => {
                if (perPage !== event.target.value) {
                  const params = {
                    page_no: pageNo,
                    per_page: event.target.value,
                    status: status,
                    search: search,
                  };

                  const queryParams = createQueryParams(params);

                  dispatch(resetGetCashDepositStatus());
                  navigate({
                    pathname: "",
                    search: queryParams,
                  });
                }
              }}
              onPageChange={(event, newPage) => {
                const pageNoInt = pageNo ? parseInt(pageNo) : null;
                if (newPage !== pageNoInt) {
                  const params = {
                    page_no: newPage,
                    per_page: perPage,
                    status: status,
                    search: search,
                  };

                  const queryParams = createQueryParams(params);

                  dispatch(resetGetCashDepositStatus());
                  navigate({
                    pathname: "",
                    search: queryParams,
                  });
                }
              }}
              totalRows={getCashDepositStates.data.pagination.total_rows}
              perPage={getCashDepositStates.data.pagination.per_page}
              page={pageNo ? parseInt(pageNo) : 1}
            >
              <hr className="mt-4" />

              {getCashDepositStates.data.cash_deposits.map((row, i) => (
                <div
                  key={i}
                  className="flex justify-between items-center px-4 py-2 border-b"
                >
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-600">
                      <strong>Transaction Date:</strong> {row.cashier_name}
                    </span>
                    <span className="text-xs text-gray-600">
                      <strong>Reference ID:</strong> {row.user_ref_id}
                    </span>
                    <span className="text-xs text-gray-600">
                      <strong>Cashier Name:</strong> {row.cashier_name}
                    </span>
                  </div>
                  <Link
                    to={`../cash-deposit/input/${row.id}`}
                    className="inline-flex items-center px-4 tracking-wide py-1  bg-button font-['Varela_Round'] text-white text-xs rounded-md font-700"
                  >
                    <span>Edit</span>
                  </Link>
                </div>
              ))}
            </DataList>
          </div>

          {/* Desktop View */}
          <div className="hidden p-4 lg:block">
            <DataTable
              order={order === "asc" ? "asc" : "desc"}
              orderBy={orderBy ?? "id"}
              emptyMessage="No data yet."
              search={search ?? ""}
              onSearch={(val) => {
                const params = {
                  page_no: pageNo,
                  per_page: perPage,
                  status: status,
                  order_by: orderBy,
                  order: order,
                  search: val === "" ? null : val,
                };

                const queryParams = createQueryParams(params);

                navigate({
                  pathname: "",
                  search: queryParams,
                });
              }}
              onRequestSort={(column_selected) => {
                // if (
                //   column_selected !== "actions" &&
                //   column_selected !== "dateCreated" // 👈 REMOVE THIS LINE, if okay na sa database hehe: 👤 Dale
                // ) {
                //   const isAsc = orderBy === column_selected && order === "asc";
                //   const params = {
                //     page_no: pageNo,
                //     per_page: perPage,
                //     status: status,
                //     order_by: column_selected,
                //     order: isAsc ? "desc" : "asc",
                //     search: search,
                //   };
                //   const queryParams = createQueryParams(params);
                //   dispatch(resetGetCashDepositStatus());
                //   navigate({
                //     pathname: "",
                //     search: queryParams,
                //   });
                // }
              }}
              columns={columns}
              onRowsPerPageChange={(event) => {
                if (perPage !== event.target.value) {
                  const params = {
                    page_no: pageNo,
                    per_page: event.target.value,
                    status: status,
                    order_by: orderBy,
                    order: order,
                    search: search,
                  };

                  const queryParams = createQueryParams(params);

                  dispatch(resetGetCashDepositStatus());
                  navigate({
                    pathname: "",
                    search: queryParams,
                  });
                }
              }}
              onPageChange={(event, newPage) => {
                const pageNoInt = pageNo ? parseInt(pageNo) : null;
                if (newPage !== pageNoInt) {
                  const params = {
                    page_no: newPage,
                    per_page: perPage,
                    status: status,
                    order_by: orderBy,
                    order: order,
                    search: search,
                  };

                  const queryParams = createQueryParams(params);

                  dispatch(resetGetCashDepositStatus());
                  navigate({
                    pathname: "",
                    search: queryParams,
                  });
                }
              }}
              totalRows={getCashDepositStates.data.pagination.total_rows}
              perPage={getCashDepositStates.data.pagination.per_page}
              page={pageNo ? parseInt(pageNo) : 1}
            >
              {getCashDepositStates.data.cash_deposits.map((row, i) => (
                <>
                  <DataTableRow key={i}>
                    <DataTableCell>{row.transaction_date}</DataTableCell>
                    <DataTableCell>{row.user_ref_id}</DataTableCell>
                    <DataTableCell>{row.cashier_name}</DataTableCell>
                    <DataTableCell>
                      <Link
                        to={`../cash-deposit/input/${row.id}`}
                        className="px-3 py-1 border rounded-lg border-secondary font-['Varela_Round']"
                      >
                        <span>Edit</span>
                      </Link>
                    </DataTableCell>
                  </DataTableRow>
                </>
              ))}
            </DataTable>
          </div>
        </>
      ) : null}

      {/* <TicketingTriageModal
        open={openTriageModal}
        onClose={() => {
          const params = {
            page_no: pageNo,
            per_page: perPage,
            status: status,
            order_by: orderBy,
            order: order,
            search: search,
          };

          const queryParams = createQueryParams(params);

          navigate({
            pathname: "",
            search: queryParams,
          });
          setOpenTriageModal(false);
        }}
      /> */}
    </>
  );
}
