import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { MaterialInput } from "features/shared/presentation/components";
import { useEffect, useState, FormEvent } from "react";
import { Helmet } from "react-helmet";

import {
  selectGetSurvey,
  getSurvey,
  GetSurveyState,
} from "features/emphrsurvey/presentation/slices/get-survey.slice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { CustomerSurveyQuestionResponseAnswer } from "features/emphrsurvey/core/survey.interface";
import { useNavigate } from "react-router-dom";
import { SurveyRating } from "../components";
import {
  insertCustomerSurveyResponse,
  InsertCustomerSurveyResponseState,
  selectInsertCustomerSurveyResponse,
} from "../slices/insert-customer-survey-response.slice";
import { HeaderNavEmphr } from "features/shared/presentation/components";

export function SurveyHr() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formState, setFormState] =
    useState<CustomerSurveyQuestionResponseAnswer>({});

  const [surveySection, setSurveySection] = useState(0);

  const getSurveyState = useAppSelector(selectGetSurvey);
  const insertCustomerSurveyResponseState = useAppSelector(
    selectInsertCustomerSurveyResponse
  );

  useEffect(() => {
    dispatch(getSurvey());
  }, [dispatch]);

  useEffect(() => {
    if (
      insertCustomerSurveyResponseState.status ===
      InsertCustomerSurveyResponseState.success
    ) {
      navigate("../emphrsurvey/success");
    }
  }, [dispatch, insertCustomerSurveyResponseState.status]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [surveySection]);

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      getSurveyState.status === GetSurveyState.success &&
      getSurveyState.data &&
      getSurveyState.data.length > 0
    ) {
      dispatch(
        insertCustomerSurveyResponse({
          answers: formState,
        })
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>TEI Employee Satisfaction Survey</title>
      </Helmet>

      <main className="min-h-screen bg-paper">
        <HeaderNavEmphr></HeaderNavEmphr>
        {getSurveyState.data && getSurveyState.data.length > 0 ? (
          <section className="container pt-4 pb-24 mx-auto">
            <form onSubmit={handleFormSubmit} className="space-y-6">
              <h1 className='text-secondary text-6xl font-["Bebas_Neue"]'>
                TEI Employee Satisfaction Survey
              </h1>
              <p className="text-base text-secondary ">
                Please take a few minutes (it shouldn’t take you more than two
                minutes) to complete this survey. Type an “X” in the box that
                best matches how you feel (strongly disagree, disagree, agree
                and strongly agree). The information you provide will be
                completely anonymous. Do not think too hard about the
                statements. Mark the boxes quickly based on your perceptions and
                how you feel about the statements. Please answer independently
                and do not ask your fellow co-workers. There are no wrong or
                right answers, just be honest. Thanks!
              </p>

              <div className="space-y-4">
                <div className='text-4xl font-bold text-center mt-4 text-secondary font-["Bebas_Neue"]'>
                  {getSurveyState.data[surveySection].section_name}
                </div>
                {getSurveyState.data[surveySection].surveys.map((survey) => (
                  <div className="pb-4">
                    <span className="text-xl font-bold text-secondary">
                      {survey.description}{" "}
                      {survey.is_required ? "" : "( Optional )"}
                    </span>
                    <div className="flex flex-col">
                      {survey.answers.length > 0 ? (
                        <FormControl>
                          <RadioGroup
                            value={
                              formState[survey.id.toString()]
                                ?.surveyQuestionAnswerId ?? ""
                            }
                            name={survey.id.toString()}
                            onChange={(e) => {
                              if (getSurveyState.data) {
                                const surveyQuestionAnswerId = e.target.value;
                                const surveyQuestionId = survey.id;

                                setFormState({
                                  ...formState,
                                  [e.target.name]: {
                                    surveyQuestionAnswerId,
                                    surveyQuestionId,
                                  },
                                });
                              }
                            }}
                          >
                            {survey.answers.map((answer) => (
                              <FormControlLabel
                                value={answer.id}
                                control={
                                  <Radio
                                    required={survey.is_required}
                                    size="small"
                                    color="secondary"
                                  />
                                }
                                label={answer.text}
                              />
                            ))}
                            {survey.others ? (
                              <FormControlLabel
                                value="others"
                                control={
                                  <Radio
                                    required
                                    size="small"
                                    color="secondary"
                                  />
                                }
                                label={
                                  <MaterialInput
                                    variant="standard"
                                    colorTheme="black"
                                    label="Others"
                                    onFocus={() => {
                                      const surveyQuestionId = survey.id;
                                      setFormState({
                                        ...formState,
                                        [survey.id.toString()]: {
                                          surveyQuestionAnswerId: "others",
                                          surveyQuestionId,
                                        },
                                      });
                                    }}
                                    required={
                                      formState[survey.id.toString()]
                                        ?.surveyQuestionAnswerId === "others"
                                    }
                                    value={
                                      formState[survey.id.toString()]?.others ??
                                      ""
                                    }
                                    onChange={(e) => {
                                      const others = e.target.value;
                                      const surveyQuestionId = survey.id;
                                      setFormState({
                                        ...formState,
                                        [e.target.name]: {
                                          surveyQuestionAnswerId: "others",
                                          others,
                                          surveyQuestionId,
                                        },
                                      });
                                    }}
                                    fullWidth
                                    name={survey.id.toString()}
                                    className="!mb-4"
                                  />
                                }
                              />
                            ) : null}
                          </RadioGroup>
                        </FormControl>
                      ) : (
                        <>
                          {survey.is_text_area ? (
                            <MaterialInput
                              colorTheme="black"
                              type={survey.is_email ? "email" : "text"}
                              value={
                                formState[survey.id.toString()]?.text ?? ""
                              }
                              onChange={(e) => {
                                const text = e.target.value;
                                const surveyQuestionId = survey.id;
                                setFormState({
                                  ...formState,
                                  [e.target.name]: {
                                    text,
                                    surveyQuestionId,
                                  },
                                });
                              }}
                              name={survey.id.toString()}
                              multiline
                              rows={4}
                              fullWidth
                              required={survey.is_required}
                            />
                          ) : null}
                          {survey.is_text_field ? (
                            <MaterialInput
                              colorTheme="black"
                              type={survey.is_email ? "email" : "text"}
                              value={
                                formState[survey.id.toString()]?.text ?? ""
                              }
                              onChange={(e) => {
                                const text = e.target.value;
                                const surveyQuestionId = survey.id;
                                setFormState({
                                  ...formState,
                                  [e.target.name]: {
                                    text,
                                    surveyQuestionId,
                                  },
                                });
                              }}
                              name={survey.id.toString()}
                              fullWidth
                              required={survey.is_required}
                            />
                          ) : null}
                        </>
                      )}
                      {survey.ratings.length > 0 ? (
                        <div className="flex flex-col w-full space-y-8 sm:items-center sm:justify-center ">
                          {survey.ratings.map((rating, i) => (
                            <SurveyRating
                              key={i}
                              surveyName={
                                survey.id.toString() +
                                "_" +
                                rating.id.toString()
                              }
                              rate={
                                formState[
                                  survey.id.toString() +
                                    "_" +
                                    rating.id.toString()
                                ]?.rate ?? ""
                              }
                              onRateSelect={(rate) => {
                                const surveyQuestionId = survey.id;

                                setFormState({
                                  ...formState,
                                  [survey.id.toString() +
                                  "_" +
                                  rating.id.toString()]: {
                                    surveyQuestionId,
                                    surveyQuestionRatingId:
                                      rating.id.toString(),
                                    rate,
                                  },
                                });
                              }}
                              rating={rating}
                            />
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}

                <div className="flex flex-col items-center justify-end pb-1 space-y-2 lg:space-y-0 lg:flex-row">
                  <button
                    type="submit"
                    className={`text-white border border-secondary order-1 lg:order-2 lg:ml-2 text-xl flex space-x-2 justify-center items-center bg-secondary py-2 w-full lg:w-[300px]  rounded-lg shadow-lg`}
                  >
                    <span className="text-2xl font-['Bebas_Neue'] tracking-[3px] font-light mt-1">
                      {getSurveyState.data.length - 1 === surveySection
                        ? "Submit"
                        : "Continue"}
                    </span>
                  </button>

                  {surveySection > 0 ? (
                    <button
                      type="button"
                      onClick={() => {
                        setSurveySection(surveySection - 1);
                      }}
                      className={`text-white border order-2 lg:order-1 border-secondary text-xl flex space-x-2 justify-center items-center bg-secondary py-2 w-full lg:w-[300px] rounded-lg shadow-lg`}
                    >
                      <span className="text-2xl font-['Bebas_Neue'] tracking-[3px] font-light mt-1">
                        Go Back
                      </span>
                    </button>
                  ) : null}
                </div>
              </div>
            </form>
          </section>
        ) : null}
      </main>
    </>
  );
}
