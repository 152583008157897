import { Box, Drawer } from "@mui/material";
import { FaBars } from "react-icons/fa";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

import { useAppSelector, useAppDispatch } from "features/config/hooks";
import { useNavigate } from "react-router-dom";
import { TeiHubLinkDrawerMenu } from "./tei-hub-link-drawer-menu";
import {
  selectTeiHubLinkSideBar,
  toggleTeiHubLinkSideBar,
} from "../../slices/tei-hub-link-sidebar.slice";
import { selectTeiHubLinkSession } from "../../slices/tei-hub-link-session.slice";

const drawerWidth = "17rem";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `5rem`,
});

const Drawers = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const SideBarContent = () => {
  const teiHubLinkSidebar = useAppSelector(selectTeiHubLinkSideBar);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);
  const { first_name, last_name } =
    teiHubLinkSessionState.data?.hublink.user_details || {};
  const dispatch = useAppDispatch();

  return (
    <Box className="relative h-screen bg-secondary font-['Varela_Round'] duration-500 z-10 overflow-y-auto overflow-x-hidden">
      <DrawerHeader className="!min-h-[0px] px-4 relative flex justify-end text-white top-5 space-x-3">
        <FaBars
          className={`cursor-pointer ${
            !teiHubLinkSidebar.status && "-translate-x-4"
          }`}
          onClick={() => {
            dispatch(toggleTeiHubLinkSideBar());
          }}
        />
      </DrawerHeader>

      <div className="flex items-center px-4 space-x-2">
        <img
          src={require("assets/favicon.png")}
          className={`duration-500 bg-white border-4 rounded-full cursor-pointer border-primary -mt-2
            ${!teiHubLinkSidebar.status && "translate-y-14"}`}
          alt="taters admin logo"
        />
        <h1
          className={`whitespace-pre duration-300 text-white origin-left font-medium -mt-2 pr-8
          ${
            !teiHubLinkSidebar.status &&
            "opacity-0 translate-x-28 overflow-hidden"
          }`}
        >
          HubLink Ordering
        </h1>
      </div>

      <div
        className={`whitespace-pre duration-300 px-4 mt-3 text-white 
                ${
                  !teiHubLinkSidebar.status &&
                  "opacity-0 translate-x-28 overflow-hidden "
                }`}
      >
        <div>
          <div className="text-lg capitalize">{`${first_name} ${last_name}`}</div>

          <div className="flex flex-col text-xs">Test Company</div>

          {/* <div className="flex flex-col text-xs">
            {getAdminSessionState.data?.admin.user_details.sos_groups.map(
              (row, index) => (
                <span key={index}>{row.description}</span>
              )
            )}
          </div> */}
        </div>
      </div>

      <TeiHubLinkDrawerMenu />
    </Box>
  );
};

export function TeiHubLinkDrawer() {
  const teiHubLinkSidebar = useAppSelector(selectTeiHubLinkSideBar);

  const dispatch = useAppDispatch();

  return (
    <>
      <div className="md:hidden">
        <Drawer
          className="md:hidden"
          anchor={"left"}
          open={teiHubLinkSidebar.status}
          onClose={() => {
            dispatch(toggleTeiHubLinkSideBar());
          }}
        >
          {SideBarContent()}
        </Drawer>
      </div>

      <div className="hidden md:block">
        <Drawers
          anchor="left"
          variant="permanent"
          sx={{ zIndex: 10 }}
          open={teiHubLinkSidebar.status}
          onClose={() => {
            dispatch(toggleTeiHubLinkSideBar());
          }}
        >
          {SideBarContent()}
        </Drawers>
      </div>
    </>
  );
}
