import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createQueryParams } from "features/config/helpers";
import {
  useAppDispatch,
  useQuery,
  useAppSelector,
} from "features/config/hooks";
import {
  TeiHubLinkOrderedProductsModel,
  TeiHubLinkStoreModel,
} from "features/hublink/core/domain/tei-hub-link.model";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import { MaterialDateTimeInput } from "features/shared/presentation/components";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { useState, useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { selectTeiHubLinkOrderData } from "../slices/tei-hub-link-order-data.slice";
import { teiHubLinkPlaceOrder } from "../slices/tei-hub-link-place-order.slice";
import { TeiHubLinkOrderTable } from "./tei-hub-link-order-table";
import { dateSetup, dynamicDivider } from "./tei-hub-link-utils";

interface TeiHubLinkOrderConfirmationProps {
  orderPlaced: (close: boolean) => void;
}

export function TeiHubLinkOrderConfirmation(
  props: TeiHubLinkOrderConfirmationProps
) {
  const query = useQuery();
  const pageNo = query.get("page_no");
  const perPage = query.get("per_page");
  const orderBy = query.get("order_by");
  const order = query.get("order");
  const search = query.get("search");
  const store = query.get("store");
  const dateType = query.get("dateType");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");

  const { clientType } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const orderDataState = useAppSelector(selectTeiHubLinkOrderData);

  const [isEdit, setIsEdit] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [orderedProduct, setOrderedProduct] =
    useState<TeiHubLinkOrderedProductsModel | null>();

  const [
    openExpectedDeliveryDateCalendar,
    setOpenExpectedDeliveryDateCalendar,
  ] = useState(false);

  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState<Date | null>(
    null
  );

  const [calculatedDeliveryDate, setCalculatedDeliveryDate] =
    useState<Date | null>(null);

  useEffect(() => {
    if (!orderDataState.data) {
      dispatch(
        openMessageModal({
          useHtml: true,
          message: `<b>Warning</b> Problem encountered while confirming your order try again after a few minutes`,
          buttons: [
            {
              color: "#CC5801",
              text: "Ok",
              onClick: () => {
                props.orderPlaced(true);
                dispatch(closeMessageModal());
              },
            },
          ],
        })
      );
    } else {
      const orderedDataMapping: TeiHubLinkOrderedProductsModel = {
        products:
          orderDataState.data.productInformation.products?.map((product) => ({
            id: product.id,
            categoryName: product.categoryName,
            subCategoryName: product.subCategoryName,
            shelfLife: product.shelfLife,
            productSKUCode: product.productSKUCode,
            productName: product.productName,
            cost: product.cost,
            productQuantity: "",
            imageLink: product.imageLink,
          })) ?? [],
      };
      setOrderedProduct(orderedDataMapping);
    }
  }, [orderDataState.data]);

  const storeInformation = (data: TeiHubLinkStoreModel) => {
    const { storeName, storeAddress, storeContactNumber } = data;
    return (
      <div className="flex-1 px-2">
        <span className="font-bold text-lg">Store Information: </span>
        <div className="flex flex-col space-y-1">
          <span className="text-base">{storeName}</span>
          <span className="text-sm">{storeAddress} </span>
          <span className="text-sm">{storeContactNumber}</span>
        </div>
      </div>
    );
  };

  const addWorkingDays = (date: Date, days: number) => {
    let count = 0;
    while (count < days) {
      date.setDate(date.getDate() + 1);
      const dayOfWeek = date.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }
    return date;
  };

  const DeliveryEstimation = () => {
    useEffect(() => {
      if (expectedDeliveryDate === null) {
        const currentDate = new Date();
        const currentHour = currentDate.getHours();
        const leadTimeDays = 4;

        let adjustedLeadTimeDays = leadTimeDays;
        if (currentHour >= 14) {
          adjustedLeadTimeDays += 1;
        }

        let calculatedArrivalDate = new Date(currentDate);
        calculatedArrivalDate = addWorkingDays(
          calculatedArrivalDate,
          adjustedLeadTimeDays
        );

        if (calculatedArrivalDate.getDay() === 6) {
          calculatedArrivalDate = addWorkingDays(calculatedArrivalDate, 2);
        } else if (calculatedArrivalDate.getDay() === 0) {
          calculatedArrivalDate = addWorkingDays(calculatedArrivalDate, 1);
        }

        setCalculatedDeliveryDate(calculatedArrivalDate);
      }
    }, [expectedDeliveryDate]);

    if (calculatedDeliveryDate === null) {
      return <div>Loading...</div>;
    }

    const currentTime = new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    return (
      <>
        {expectedDeliveryDate === null ? (
          <>
            <div className="text-sm space-x-1">
              <span className="font-semibold">Estimated Lead Time:</span>
              <span>4 working days</span>
            </div>
            <div className="text-sm space-x-1">
              <span className="font-semibold">
                Expected Arrival Date and Time:
              </span>
              <span>
                {dateSetup(calculatedDeliveryDate.toLocaleDateString(), false)}
              </span>
              <span>{`at ${currentTime}`}</span>
            </div>
          </>
        ) : (
          <>
            <div className="text-sm space-x-1">
              <span className="font-semibold">Estimated Lead Time:</span>
              <span>4 working days</span>
            </div>
            <div className="text-sm space-x-1">
              <span className="font-semibold">
                Expected Arrival Date and Time:
              </span>
              <span>
                {dateSetup(expectedDeliveryDate.toLocaleDateString(), false)}
              </span>
              <span>{`at ${expectedDeliveryDate.toLocaleTimeString()}`}</span>
            </div>
          </>
        )}

        <div className="text-sm space-x-1 mt-2">
          <span className="font-semibold">Requirements:</span>
          <span>Minimum order total amount is 8000</span>
        </div>
      </>
    );
  };

  const handleExpectedDeliveryDate = (selectedDate: Date | null) => {
    setExpectedDeliveryDate(selectedDate);
  };

  const noQuantityCheck = orderedProduct?.products.some(
    (product) =>
      product.productQuantity === "0" ||
      product.productQuantity === null ||
      product.productQuantity === ""
  );

  const handlePlaceOrder = () => {
    dispatch(
      openMessageModal({
        useHtml: true,
        message: `By clicking the <b>YES button</b>, you are confirming your order. And This action is irreversible.`,
        buttons: [
          {
            color: "#CC5801",
            text: "Yes",
            onClick: () => {
              if (
                orderDataState.data?.storeInformation &&
                orderedProduct &&
                clientType
              ) {
                const fullDatetime = expectedDeliveryDate
                  ? expectedDeliveryDate?.toLocaleDateString() +
                    " " +
                    expectedDeliveryDate?.toLocaleTimeString()
                  : calculatedDeliveryDate?.toLocaleDateString() +
                    " " +
                    new Date().toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    });

                dispatch(
                  teiHubLinkPlaceOrder({
                    storeInformation: orderDataState.data?.storeInformation,
                    productInformation: orderedProduct,
                    clientType: clientType,
                    expectedDeliveryDate: fullDatetime,
                  })
                );
                props.orderPlaced(true);
                dispatch(closeMessageModal());
              }
            },
          },
          {
            color: "#CC5801",
            text: "No",
            onClick: () => {
              dispatch(closeMessageModal());
            },
          },
        ],
      })
    );
  };

  const shouldDisableDate = (date: Date) => {
    const leadTimeDate = new Date();
    addWorkingDays(leadTimeDate, 4);

    return date < leadTimeDate || date.getDay() === 0 || date.getDay() === 6;
  };

  return (
    <div className="space-y-3">
      <div>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 10 }}
          onClick={() => {
            const queryParams = createQueryParams({
              page_no: pageNo,
              per_page: perPage,
              order_by: orderBy,
              order: order,
              search: search,
              store: store,
              dateType: dateType,
              startDate: startDate,
              endDate: endDate,
              phase: "placement",
            });

            navigate({
              pathname: "",
              search: queryParams,
            });
          }}
        >
          <IoMdArrowRoundBack /> Back
        </Button>
      </div>

      <div className="flex flex-col lg:flex-wrap lg:flex-row p-5 gap-2">
        {orderDataState.data?.storeInformation ? (
          storeInformation(orderDataState.data?.storeInformation)
        ) : (
          <div className="flex-1">
            <span className="font-bold text-lg">
              We apologize, but we are currently unable to retrieve store
              information.
            </span>
            <span>
              Please consider canceling your order placement immediately.
            </span>
          </div>
        )}
        {dynamicDivider()}

        <div className="flex-1 space-y-1 px-2">
          <span className="font-bold text-lg">Expected Delivery: </span>
          <span className="text-xs text-gray-400">{"(Optional)"}</span>
          <div className="flex flex-col">
            <div className="flex space-x-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MaterialDateTimeInput
                  fullWidth
                  colorTheme={"black"}
                  size="small"
                  value={expectedDeliveryDate || calculatedDeliveryDate}
                  textFieldValue={
                    expectedDeliveryDate !== null
                      ? expectedDeliveryDate?.toDateString() +
                        " " +
                        expectedDeliveryDate?.toLocaleTimeString()
                      : calculatedDeliveryDate?.toDateString() +
                        " " +
                        calculatedDeliveryDate?.toLocaleTimeString()
                  }
                  readonly={true}
                  openCalendar={openExpectedDeliveryDateCalendar}
                  setOpenCalendar={(val) =>
                    setOpenExpectedDeliveryDateCalendar(val)
                  }
                  shouldDisableDate={shouldDisableDate}
                  onChange={handleExpectedDeliveryDate}
                  minDate={calculatedDeliveryDate}
                />
              </LocalizationProvider>
              <div className="flex items-center">
                <Button
                  onClick={() => {
                    setExpectedDeliveryDate(null);
                    setCalculatedDeliveryDate(null);
                  }}
                >
                  <span className="text-xs capitalize text-center">
                    Reset <br /> date
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <DeliveryEstimation />
          </div>
        </div>
      </div>

      <div>
        <TeiHubLinkOrderTable
          isEdit={isEdit}
          rows={orderedProduct ?? null}
          setRows={setOrderedProduct}
          setTotalAmount={setTotalAmount}
        />
      </div>

      <div className="flex flex-col lg:flex-row px-5 gap-3">
        {isEdit ? (
          <>
            <Button
              variant="contained"
              size="medium"
              fullWidth
              sx={STOCK_ORDERING_BUTTON_STYLE}
              onClick={() => setIsEdit(false)}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              size="medium"
              fullWidth
              sx={STOCK_ORDERING_BUTTON_STYLE}
              onClick={() => setIsEdit(false)}
            >
              Confirm Edit
            </Button>
          </>
        ) : (
          <>
            {/* 
            <Button
              variant="contained"
              size="medium"
              fullWidth
              sx={STOCK_ORDERING_BUTTON_STYLE}
              onClick={() => setIsEdit(true)}
            >
              Edit Information & Products
            </Button> */}

            {totalAmount <= 8000 ? (
              <span className="flex justify-center w-full">
                🚫 Order Minimum Total Amount Not Met: please ensure that your
                order total amount exceeds 8000. Thank you!.
              </span>
            ) : (
              <Button
                variant="contained"
                size="medium"
                fullWidth
                disabled={noQuantityCheck || totalAmount <= 8000}
                sx={STOCK_ORDERING_BUTTON_STYLE}
                onClick={handlePlaceOrder}
              >
                Place Order
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
