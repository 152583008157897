import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { storeCancelOrderParam } from "features/hublink/core/tei-hub-link.param";
import {
  GetTeiHubLinkStoreCancelOrderResponse,
  GetTeiHubLinkStoreCancelOrderRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkStoreCancelOrder {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkStoreCancelOrder;
  message: string;
}

const initialState: InitialState = {
  status: TeiHubLinkStoreCancelOrder.initial,
  message: "",
};

export const teiHubLinkStoreCancelOrder = createAsyncThunk(
  "teiHubLinkStoreCancelOrder",
  async (param: storeCancelOrderParam, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkStoreCancelOrderResponse =
        await GetTeiHubLinkStoreCancelOrderRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkStoreCancelOrderSlice = createSlice({
  name: "teiHubLinkStoreCancelOrder",
  initialState,
  reducers: {
    resetTeiHubLinkStoreCancelOrder: (state) => {
      state.status = TeiHubLinkStoreCancelOrder.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkStoreCancelOrder.pending, (state) => {
        state.status = TeiHubLinkStoreCancelOrder.inProgress;
      })
      .addCase(teiHubLinkStoreCancelOrder.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = TeiHubLinkStoreCancelOrder.success;
          state.message = message;
        }
      })
      .addCase(teiHubLinkStoreCancelOrder.rejected, (state, action) => {
        state.status = TeiHubLinkStoreCancelOrder.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectTeiHubLinkStoreCancelOrder = (state: RootState) =>
  state.teiHubLinkStoreCancelOrder;

export const { resetTeiHubLinkStoreCancelOrder } =
  teiHubLinkStoreCancelOrderSlice.actions;
export default teiHubLinkStoreCancelOrderSlice.reducer;
