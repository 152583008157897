import { FaRegFaceSmileWink } from "react-icons/fa6";

export function SurveySuccess() {
  return (
    <div className="w-full px-5 py-20 lg:px-96">
      <div className="flex justify-center  py-10 space-y-3">
        <div className="flex flex-col">
          <FaRegFaceSmileWink className="flex w-full" size={50} />
          <div className="flex flex-col text-center space-y-3">
            <span className="text-3xl font-semibold">Thank you!</span>
            <span>Congratulations on finishing the survey!</span>
          </div>

          <div className="flex justify-center mt-20 text-xs">
            You can close this window/tab
          </div>
        </div>
      </div>
    </div>
  );
}
