import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { SoaModel } from "features/hublink/core/domain/tei-hub-link-soa.model";
import {
  GetTeiHubLinkSoaResponse,
  GetTeiHubLinkSoaRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkSoa {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkSoa;
  message: string;
  data: SoaModel | undefined;
}

const initialState: InitialState = {
  status: TeiHubLinkSoa.initial,
  message: "",
  data: undefined,
};

export const teiHubLinkSoa = createAsyncThunk(
  "teiHubLinkSoa",
  async (param: string, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkSoaResponse =
        await GetTeiHubLinkSoaRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkSoaSlice = createSlice({
  name: "teiHubLinkSoa",
  initialState,
  reducers: {
    resetTeiHubLinkSoa: (state) => {
      state.status = TeiHubLinkSoa.initial;
      state.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkSoa.pending, (state) => {
        state.status = TeiHubLinkSoa.inProgress;
      })
      .addCase(teiHubLinkSoa.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = TeiHubLinkSoa.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(teiHubLinkSoa.rejected, (state, action) => {
        state.status = TeiHubLinkSoa.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectTeiHubLinkSoa = (state: RootState) => state.teiHubLinkSoa;

export const { resetTeiHubLinkSoa } = teiHubLinkSoaSlice.actions;
export default teiHubLinkSoaSlice.reducer;
