import { useParams } from "react-router-dom";
import { HublinkHead, TeiHubLinkOrderContent } from "../components";

export function TeiHubLinkPage() {
  const { clientType } = useParams();

  return (
    <>
      <HublinkHead
        HublinkBreadCrumbsProps={{
          home: {
            title: "Tei Hub Link",
            url: `/hublink/${clientType}`,
          },
          className: "lg:h-[200px]",
          pageTitles: [],
        }}
      />
      <div className="p-3">
        <TeiHubLinkOrderContent />
      </div>
    </>
  );
}
