import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { TeiHubLinkOrderReturnRefundParam } from "features/hublink/core/tei-hub-link.param";
import {
  GetTeiHubLinkReturnRefundResponse,
  GetTeiHubLinkReturnRefundRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkReturnRefund {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkReturnRefund;
  message: string;
}

const initialState: InitialState = {
  status: TeiHubLinkReturnRefund.initial,
  message: "",
};

export const teiHubLinkReturnRefund = createAsyncThunk(
  "teiHubLinkReturnRefund",
  async (param: TeiHubLinkOrderReturnRefundParam, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkReturnRefundResponse =
        await GetTeiHubLinkReturnRefundRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkReturnRefundSlice = createSlice({
  name: "teiHubLinkReturnRefund",
  initialState,
  reducers: {
    resetTeiHubLinkReturnRefund: (state) => {
      state.status = TeiHubLinkReturnRefund.initial;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkReturnRefund.pending, (state) => {
        state.status = TeiHubLinkReturnRefund.inProgress;
      })
      .addCase(teiHubLinkReturnRefund.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = TeiHubLinkReturnRefund.success;
          state.message = message;
        }
      })
      .addCase(teiHubLinkReturnRefund.rejected, (state, action) => {
        state.status = TeiHubLinkReturnRefund.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectTeiHubLinkReturnRefund = (state: RootState) =>
  state.teiHubLinkReturnRefund;

export const { resetTeiHubLinkReturnRefund } =
  teiHubLinkReturnRefundSlice.actions;
export default teiHubLinkReturnRefundSlice.reducer;
