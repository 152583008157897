import { Navigate, Outlet, useNavigate } from "react-router-dom";
import {
  getAdminSession,
  GetAdminSessionState,
  selectGetAdminSession,
} from "../slices/get-admin-session.slice";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { useEffect } from "react";

export function LandingPageGuardGuard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getAdminSessionState = useAppSelector(selectGetAdminSession);

  useEffect(() => {
    dispatch(getAdminSession());
  }, [dispatch]);

  if (
    getAdminSessionState.status === GetAdminSessionState.fail &&
    getAdminSessionState.data === null
  ) {
    return <Navigate to={"/admin"} />;
  }

  if (
    getAdminSessionState.data?.admin.user_details.groups.length !== 0 ||
    getAdminSessionState.data?.admin.user_details.sos_groups.length !== 0 ||
    getAdminSessionState.data?.admin.user_details.sales_groups.length !== 0
  ) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return null;
}
