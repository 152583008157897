import {
  Typography,
  Box,
  Divider,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { TeiHubLinkStoresModel } from "features/hublink/core/domain/tei-hub-link.model";

export const mobileCustomStatus = (text: string) => {
  return (
    <Typography fontSize={14}>
      <Box sx={{ fontWeight: 600 }} className="border-2 border-none">
        <span className="bg-amber-300 px-2 py-0.5 rounded text-amber-600">
          {text}
        </span>
      </Box>
    </Typography>
  );
};

export const dynamicDivider = () => {
  return (
    <>
      <Divider className="hidden lg:block" orientation="vertical" flexItem />
      <Divider className="block lg:hidden" orientation="horizontal" flexItem />
    </>
  );
};

export const dateSetup = (date: string | Date, withTime: boolean) => {
  if (withTime) {
    return new Date(date).toLocaleDateString("en-PH", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  } else {
    return new Date(date).toLocaleDateString("en-PH", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }
};

export const dateTypeMapper = (dateType: string | null) => {
  switch (dateType) {
    case "created_at":
      return "Placement date";
    case "Expected_delivery":
      return "Expected delivery date";
    case "Actual_delivery":
      return "Actual delivery date";
  }
};

export const StoreIdMapper = (
  id: string | number,
  storeData: TeiHubLinkStoresModel | undefined
) => {
  // const dispatch = useAppDispatch();

  // if (!storeState.data && clientType) {
  //   const storeParam = createQueryParams({
  //     clientType: clientType,
  //   });
  //   dispatch(teiHubLinkStores(storeParam));
  // }

  const storeName = storeData?.stores.find(
    (stores) => stores.storeCode === id
  )?.storeName;

  return storeName;
};

export const calculateSubTotalPerRow = (
  cost: string,
  orderedQuantity: string
) => {
  return Number(cost) * Number(orderedQuantity);
};
