import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { ListQrCodeModel } from "features/shared/core/domain/list-qr-code.model";
import {
  listQrCodeResponse,
  listQrCodeRepository,
} from "features/shared/data/repository/shared.repository";

export enum ListQrCodeState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: ListQrCodeState;
  data: ListQrCodeModel | undefined;
}

const initialState: InitialState = {
  status: ListQrCodeState.initial,
  data: undefined,
};

export const listQrCode = createAsyncThunk(
  "listQrCode",
  async (param: string, { rejectWithValue }) => {
    try {
      const response: listQrCodeResponse = await listQrCodeRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }

        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const listQrCodeSlice = createSlice({
  name: "listQrCode",
  initialState,
  reducers: {
    resetListQrCode: (state) => {
      state.status = ListQrCodeState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listQrCode.pending, (state) => {
        state.status = ListQrCodeState.inProgress;
      })
      .addCase(listQrCode.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = ListQrCodeState.success;
          state.data = action.payload;
        }
      })
      .addCase(listQrCode.rejected, (state, action) => {
        state.status = ListQrCodeState.fail;
        state.data = undefined;
      });
  },
});

export const selectlistQrCode = (state: RootState) => state.listQrCode;
export const { resetListQrCode } = listQrCodeSlice.actions;
export default listQrCodeSlice.reducer;
