import { ButtonGroup, Button, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import React, { useEffect, useState } from "react";
import {
  GetMinimumTotalAmountDataState,
  getMinimumTotalAmountData,
  selectGetMinimumTotalAmountData,
} from "../slices/get-minimum-total-amount.slice";

interface StockOrderHandleQuantityProps {
  rows: any[];
  setRows: (row: any[]) => void;
  setTotalCost?: (value: number) => void;
  rowsIndex: number;
  currentValue: string | null;
  propertyKey: string;
  precedingPropertyKey?: string;
}

export function StockOrderHandleQuantity(props: StockOrderHandleQuantityProps) {
  const dispatch = useAppDispatch();
  const getMinimumTotalAmountState = useAppSelector(
    selectGetMinimumTotalAmountData
  );
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (
      !getMinimumTotalAmountState.data &&
      getMinimumTotalAmountState.status ===
        GetMinimumTotalAmountDataState.success
    ) {
      dispatch(getMinimumTotalAmountData());
    }
  }, [getMinimumTotalAmountState.data]);

  useEffect(() => {
    const totalProductQuantity: number[] = [];
    props.rows.map((orderData) => {
      if (orderData.orderQty) {
        const totalQuantityPerRow =
          Number(orderData.cost) * Number(orderData.orderQty);
        totalProductQuantity.push(Number(totalQuantityPerRow.toFixed(2)));
      }
    });

    setTotalAmount(
      Number(totalProductQuantity.reduce((acc, cur) => acc + cur, 0).toFixed(2))
    );
  }, [props.rows]);

  useEffect(() => {
    props.setTotalCost?.(totalAmount);
  }, [totalAmount]);

  const handleQuantityButtonChange = (event: {
    currentTarget: { id: string };
  }) => {
    const updatedRows = props.rows.map((r: any, index: number) => {
      let value = isNaN(Number(props.currentValue))
        ? 0
        : Number(props.currentValue);

      value = value >= 0 ? value : 0;

      if (props.precedingPropertyKey) {
        value =
          value < r?.[props.precedingPropertyKey ?? ""]
            ? value
            : r?.[props.precedingPropertyKey ?? ""] - 1;
      }

      if (index === props.rowsIndex) {
        if (event.currentTarget.id === "minus") {
          const minusVal = value - 1;
          value = minusVal > 0 ? minusVal : 0;
        } else if (event.currentTarget.id === "plus") {
          const plusVal = value + 1;
          value = plusVal < 9999 ? plusVal : 9999;
        }

        return {
          ...r,
          [props.propertyKey]: value.toString(),
        };
      }
      return r;
    });
    props.setRows(updatedRows);
  };

  const handleQuantityTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value.replace(/\D/g, "");

    const updatedRows = props.rows.map((r: any, index: number) => {
      if (index === props.rowsIndex) {
        if (props.precedingPropertyKey) {
          value =
            value < r?.[props.precedingPropertyKey]
              ? value
              : r?.[props.precedingPropertyKey];
        }

        return {
          ...r,
          [props.propertyKey]: value.toString(),
        };
      }
      return r;
    });
    props.setRows(updatedRows);
  };

  return (
    <ButtonGroup
      disableElevation
      size="small"
      variant="contained"
      aria-label="quantity button group"
      sx={{ display: "flex" }}
    >
      <Button id="minus" onClick={handleQuantityButtonChange}>
        -
      </Button>
      <TextField
        required
        type="text"
        sx={{ width: 65 }}
        value={props.currentValue}
        inputProps={{ maxLength: 4 }}
        onChange={handleQuantityTextChange}
        autoComplete="off"
        size="small"
        variant="outlined"
        placeholder="0"
      />
      <Button id="plus" onClick={handleQuantityButtonChange}>
        +
      </Button>
    </ButtonGroup>
  );
}
