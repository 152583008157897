import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { TeiHubLinkOrdersModel } from "features/hublink/core/domain/tei-hub-link.model";
import {
  GetTeiHubLinkCancelledOrderResponse,
  GetTeiHubLinkCancelledOrderRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkCancelledOrder {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkCancelledOrder;
  message: string;
  data: TeiHubLinkOrdersModel | undefined;
}

const initialState: InitialState = {
  status: TeiHubLinkCancelledOrder.initial,
  message: "",
  data: undefined,
};

export const teiHubLinkCancelledOrder = createAsyncThunk(
  "teiHubLinkCancelledOrder",
  async (param: string, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkCancelledOrderResponse =
        await GetTeiHubLinkCancelledOrderRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkCancelledOrderSlice = createSlice({
  name: "teiHubLinkCancelledOrder",
  initialState,
  reducers: {
    resetTeiHubLinkCancelledOrder: (state) => {
      state.status = TeiHubLinkCancelledOrder.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkCancelledOrder.pending, (state) => {
        state.status = TeiHubLinkCancelledOrder.inProgress;
      })
      .addCase(teiHubLinkCancelledOrder.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = TeiHubLinkCancelledOrder.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(teiHubLinkCancelledOrder.rejected, (state, action) => {
        state.status = TeiHubLinkCancelledOrder.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectTeiHubLinkCancelledOrder = (state: RootState) =>
  state.teiHubLinkCancelledOrder;

export const { resetTeiHubLinkCancelledOrder } =
  teiHubLinkCancelledOrderSlice.actions;
export default teiHubLinkCancelledOrderSlice.reducer;
