import { Button } from "@mui/material";
import { useAppDispatch } from "features/config/hooks";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { teiHubLinkStoreCancelOrder } from "../slices/tei-hub-link-store-cancel-order.slice";
import { dynamicDivider } from "./tei-hub-link-utils";

interface TeiHubLinkStoreCancelRequestProps {
  processId: number | undefined;
  orderId: string | undefined;
}

export function TeiHubLinkStoreCancelRequest(
  props: TeiHubLinkStoreCancelRequestProps
) {
  const { clientType, trackingNum } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [cancelOrderReason, setCancelOrderReason] = useState("");

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    dispatch(
      openMessageModal({
        message: `Are sure you want to cancel this order?`,
        buttons: [
          {
            color: "#CC5801",
            text: "Yes",
            onClick: () => {
              if (trackingNum && clientType) {
                dispatch(
                  teiHubLinkStoreCancelOrder({
                    order_information_id: props.orderId,
                    reason: cancelOrderReason,
                    trackingNumber: trackingNum,
                    clientType: clientType,
                  })
                );
                navigate(`/hublink/${clientType}`);
              }
              dispatch(closeMessageModal());
            },
          },
          {
            color: "#22201A",
            text: "No",
            onClick: () => {
              dispatch(closeMessageModal());
            },
          },
        ],
      })
    );
  };

  if (clientType === "shell" && props.processId === 0) {
    return (
      <>
        {dynamicDivider()}
        <div className="flex-1 space-y-5">
          <form className="flex flex-col space-y-2" onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-1">
              <span>State your reason: </span>
              <textarea
                required
                className="w-full h-full text-base p-1 border border-black rounded-md"
                value={cancelOrderReason}
                onChange={(event) => {
                  setCancelOrderReason(event.target.value);
                }}
              />
            </div>

            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={STOCK_ORDERING_BUTTON_STYLE}
            >
              Cancel Order
            </Button>
          </form>
        </div>
      </>
    );
  } else {
    return null;
  }
}
