import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { ModifyOrderPram } from "features/hublink/core/tei-hub-link.param";
import {
  GetTeiHubLinkModifyOrderResponse,
  GetTeiHubLinkModifyOrderRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkModifyOrder {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkModifyOrder;
  message: string;
}

const initialState: InitialState = {
  status: TeiHubLinkModifyOrder.initial,
  message: "",
};

export const teiHubLinkModifyOrder = createAsyncThunk(
  "teiHubLinkModifyOrder",
  async (param: ModifyOrderPram, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkModifyOrderResponse =
        await GetTeiHubLinkModifyOrderRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkModifyOrderSlice = createSlice({
  name: "teiHubLinkModifyOrder",
  initialState,
  reducers: {
    resetTeiHubLinkModifyOrder: (state) => {
      state.status = TeiHubLinkModifyOrder.initial;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkModifyOrder.pending, (state) => {
        state.status = TeiHubLinkModifyOrder.inProgress;
      })
      .addCase(teiHubLinkModifyOrder.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = TeiHubLinkModifyOrder.success;
          state.message = message;
        }
      })
      .addCase(teiHubLinkModifyOrder.rejected, (state, action) => {
        state.status = TeiHubLinkModifyOrder.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectTeiHubLinkModifyOrder = (state: RootState) =>
  state.teiHubLinkModifyOrder;

export const { resetTeiHubLinkModifyOrder } =
  teiHubLinkModifyOrderSlice.actions;
export default teiHubLinkModifyOrderSlice.reducer;
