import {
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import {
  MaterialInput,
  MaterialInputAutoComplete,
} from "features/shared/presentation/components";
import { FaArrowUp, FaRegClock } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
import { PopupModal } from "./popup.modal";
import {
  useAppDispatch,
  useAppSelector,
  useQuery,
} from "features/config/hooks";
import { selectGetPriceHistory } from "../slices/get-price-history.slice";
import { useEffect, useState } from "react";
import { PriceIncreaseParam } from "features/stock-ordering/core/stock-ordering.params";
import { insertPriceIncrease } from "../slices/insert-price-increase.slice";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { dateSetup } from "../components/stock-ordering-utils";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface PriceHistoryModalProps {
  open: boolean;
  onClose: (data: boolean) => void;
}

const region = [
  { id: "1", regionName: "North Luzon" },
  { id: "2", regionName: "NCR" },
  { id: "3", regionName: "South Luzon" },
  { id: "4", regionName: "Visayas" },
  { id: "5", regionName: "Mindanao" },
];

export function PriceHistoryModal(props: PriceHistoryModalProps) {
  const getPriceHistoryState = useAppSelector(selectGetPriceHistory);
  const dispatch = useAppDispatch();
  const [priceChangeData, setPriceChangeData] = useState<PriceIncreaseParam>({
    regionId: [],
    productId: "",
    price: "",
    effectivityDate: "",
    endDate: "",
  });

  const query = useQuery();
  const productId = query.get("productId");

  useEffect(() => {
    setPriceChangeData({
      regionId: [],
      productId: "",
      price: "",
      effectivityDate: "",
      endDate: "",
    });

    if (productId) {
      setPriceChangeData((prevData) => ({
        ...prevData,
        productId: productId,
      }));
    }
  }, [productId, props.open]);

  const handleChangePrice = () => {
    const changePriceParam: PriceIncreaseParam = priceChangeData;

    if (
      changePriceParam.regionId.length != 0 &&
      changePriceParam.price !== "" &&
      changePriceParam.productId !== "" &&
      changePriceParam.effectivityDate !== null
    ) {
      dispatch(
        openMessageModal({
          message: `This process is ireversible. Are you sure you want to continue?`,
          buttons: [
            {
              color: "#CC5801",
              text: "Yes",
              onClick: () => {
                dispatch(insertPriceIncrease(changePriceParam));
                props.onClose(false);
                dispatch(closeMessageModal());
              },
            },
            {
              color: "#22201A",
              text: "No",
              onClick: () => {
                dispatch(closeMessageModal());
              },
            },
          ],
        })
      );
    }
  };

  const handleDate = (
    date: string | number | Date | dayjs.Dayjs | null | undefined,
    type: string
  ) => {
    if (date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");

      if (dayjs(formattedDate).isValid()) {
        setPriceChangeData({
          ...priceChangeData,
          [type]: formattedDate ?? [],
        });
      }
    }
  };

  const priceIncreasePerPriceIncreaseId = (
    // isCurrent: boolean,
    index: number
  ) => {
    const effectivityDate = dateSetup(
      getPriceHistoryState.data?.history[index].effectivityDate ?? "",
      true
    );
    return (
      <div className="flex-1">
        {/* <span className={`${!isCurrent && "hidden"}`}>{"Current: "}</span> */}
        <div className="flex">
          {getPriceHistoryState.data &&
            getPriceHistoryState.data?.history?.[index].latestPrice.map(
              (priceData) => (
                <ListItemText
                  primary={priceData.region_name}
                  secondary={`₱${priceData.price}`}
                  primaryTypographyProps={{
                    color: "#555555",
                    fontSize: "0.75rem",
                  }}
                  secondaryTypographyProps={{
                    color: "#708090",
                    fontSize: "0.75rem",
                  }}
                />
              )
            )}
        </div>
        <span className="text-xs">{`As of ${effectivityDate}`}</span>
      </div>
    );
  };

  console.log(priceChangeData.effectivityDate);

  return (
    <PopupModal
      noIcon
      open={props.open}
      title={"Price History"}
      message={""}
      customButton
      onClose={() => props.onClose(false)}
      customModalWidth="md:w-[50%] lg:w-[460px]"
    >
      <div className="p-2 border-2 max-h-72 md:max-h-96 lg:max-h-96 overflow-y-auto">
        <div>
          <ListItemText
            primary={`Base Price: ₱${getPriceHistoryState?.data?.baseCost}`}
            primaryTypographyProps={{ color: "#555555" }}
          />
        </div>
        {getPriceHistoryState?.data?.history &&
          getPriceHistoryState?.data?.history.map((row, index) => {
            return (
              <>
                <List dense>
                  <ListItem
                    sx={{
                      gap: 2,
                    }}
                  >
                    {row.isPending === 0 ? (
                      <FaArrowUp color="#32CD32" />
                    ) : (
                      <FaRegClock color="#FFD700" />
                    )}
                    <div className="flex justify-between w-full">
                      {priceIncreasePerPriceIncreaseId(index)}
                    </div>
                  </ListItem>
                </List>
                {getPriceHistoryState.data?.history.length! - 1 !== index && (
                  <Divider />
                )}
              </>
            );
          })}
      </div>
      <div className="flex space-x-2 px-3">
        <div className="flex-1 space-y-2">
          <MaterialInputAutoComplete
            required
            label="Select Region"
            colorTheme="black"
            multiple
            options={region ?? []}
            getOptionLabel={(option) => option.regionName}
            size="small"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              priceChangeData?.regionId ? [...priceChangeData.regionId] : []
            }
            onChange={(e, selectedRegion) => {
              setPriceChangeData({
                ...priceChangeData,
                regionId: selectedRegion ?? [],
              });
            }}
            filterSelectedOptions
          />

          <div className="flex-1 space-y-2">
            <MaterialInput
              required
              label="Price"
              colorTheme={"black"}
              fullWidth
              value={priceChangeData?.price}
              onChange={(event) => {
                if (/^[0-9]*$/.test(event.target.value)) {
                  setPriceChangeData({
                    ...priceChangeData,
                    price: event.target.value,
                  });
                }
              }}
              name={"priceInput"}
              size="small"
            />
            <div className="flex space-x-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Effectivity date"
                  views={["year", "month", "day", "hours", "minutes"]}
                  onChange={(
                    date:
                      | string
                      | number
                      | Date
                      | dayjs.Dayjs
                      | null
                      | undefined
                  ) => handleDate(date, "effectivityDate")}
                  value={
                    priceChangeData?.effectivityDate
                      ? dayjs(priceChangeData?.effectivityDate)
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      id="effectivityDate"
                      required
                      InputProps={{ style: { color: "black" } }}
                      {...params}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="End date"
                  views={["year", "month", "day", "hours", "minutes"]}
                  onChange={(
                    date:
                      | string
                      | number
                      | Date
                      | dayjs.Dayjs
                      | null
                      | undefined
                  ) => handleDate(date, "endDate")}
                  value={
                    priceChangeData?.endDate
                      ? dayjs(priceChangeData?.endDate)
                      : null
                  }
                  renderInput={(params) => (
                    <TextField id="endDate" required {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <IconButton style={{ color: "#CC5801" }} onClick={handleChangePrice}>
          <HiPencilAlt />
        </IconButton>
      </div>
    </PopupModal>
  );
}
