import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { RootState } from "features/config/store";
import { TeiHubLinkSessionModel } from "features/hublink/core/domain/tei-hub-link-session.model";
import {
  teiHubLinkSessionResponse,
  teiHubLinkSessionRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkSessionState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkSessionState;
  message: string;
  data: TeiHubLinkSessionModel | undefined;
}

const initialState: InitialState = {
  status: TeiHubLinkSessionState.initial,
  message: "",
  data: undefined,
};

export const teiHubLinkSession = createAsyncThunk(
  "teiHubLinkSession",
  async (param, { rejectWithValue }) => {
    try {
      const response: teiHubLinkSessionResponse =
        await teiHubLinkSessionRepository();
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkSessionSlice = createSlice({
  name: "teiHubLinkSession",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkSession.pending, (state) => {
        state.status = TeiHubLinkSessionState.inProgress;
      })
      .addCase(teiHubLinkSession.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = TeiHubLinkSessionState.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(teiHubLinkSession.rejected, (state, action) => {
        state.status = TeiHubLinkSessionState.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectTeiHubLinkSession = (state: RootState) =>
  state.teiHubLinkSession;

export default teiHubLinkSessionSlice.reducer;
