import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { updateOrderParam } from "features/hublink/core/tei-hub-link.param";
import {
  GetTeiHubLinkReceiveOrderResponse,
  GetTeiHubLinkReceiveOrderRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkReceiveOrder {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkReceiveOrder;
  message: string;
}

const initialState: InitialState = {
  status: TeiHubLinkReceiveOrder.initial,
  message: "",
};

export const teiHubLinkReceiveOrder = createAsyncThunk(
  "teiHubLinkReceiveOrder",
  async (param: updateOrderParam, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkReceiveOrderResponse =
        await GetTeiHubLinkReceiveOrderRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkReceiveOrderSlice = createSlice({
  name: "teiHubLinkReceiveOrder",
  initialState,
  reducers: {
    resetTeiHubLinkReceiveOrder: (state) => {
      state.status = TeiHubLinkReceiveOrder.initial;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkReceiveOrder.pending, (state) => {
        state.status = TeiHubLinkReceiveOrder.inProgress;
      })
      .addCase(teiHubLinkReceiveOrder.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = TeiHubLinkReceiveOrder.success;
          state.message = message;
        }
      })
      .addCase(teiHubLinkReceiveOrder.rejected, (state, action) => {
        state.status = TeiHubLinkReceiveOrder.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectTeiHubLinkReceiveOrder = (state: RootState) =>
  state.teiHubLinkReceiveOrder;

export const { resetTeiHubLinkReceiveOrder } =
  teiHubLinkReceiveOrderSlice.actions;
export default teiHubLinkReceiveOrderSlice.reducer;
