import { selectGetAdminSession } from "features/admin/presentation/slices/get-admin-session.slice";
import { createQueryParams } from "features/config/helpers";
import {
  useAppDispatch,
  useAppSelector,
  useQuery,
} from "features/config/hooks";
import { pusher } from "features/shared/constants";
import { useEffect } from "react";
import { useParams, Outlet, useLocation } from "react-router-dom";
import { teiHubLinkOrders } from "../slices/tei-hub-link-orders.slice";
import { selectTeiHubLinkSession } from "../slices/tei-hub-link-session.slice";
import { teiHubLinkOrder } from "../slices/tei-hub-link-order.slice";
import { teiHubLinkDispatchedOrders } from "../slices/tei-hub-link-dispatched-orders.slice";

interface TransactionParam {
  store_id: number;
  clientType: string;
  message: string;
}

interface UpdateOrderInformationParam {
  trackingNumber: string;
  clientType: string;
  message: string;
}

export function TeiHubLinkRealTimeWrapper() {
  const { clientType, trackingNum } = useParams();
  const location = useLocation();

  const query = useQuery();
  const pageNo = query.get("page_no");
  const perPage = query.get("per_page");
  const orderBy = query.get("order_by");
  const order = query.get("order");
  const search = query.get("search");
  const store = query.get("store");
  const dateType = query.get("dateType");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");

  const dispatch = useAppDispatch();

  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);

  useEffect(() => {
    pusher.unsubscribe("admin-tei-hub-link");
    const teiHubLinkChannel = pusher.subscribe("admin-tei-hub-link");

    teiHubLinkChannel.bind("tei-hub-link-process", (data: TransactionParam) => {
      if (
        teiHubLinkSessionState.data?.hublink.clientType.length !== 0 &&
        clientType
      ) {
        const queryParams = createQueryParams({
          page_no: pageNo,
          per_page: perPage,
          order_by: orderBy,
          order: order,
          search: search,
          store: store,
          dateType: dateType,
          startDate: startDate,
          endDate: endDate,
          clientType: clientType,
        });

        dispatch(teiHubLinkOrders(queryParams));
      }
    });

    teiHubLinkChannel.bind(
      "tei-hub-link-information",
      (data: UpdateOrderInformationParam) => {
        const orderInformationPageMatch =
          location.pathname === `/hublink/${clientType}/order/${trackingNum}`;

        if (
          teiHubLinkSessionState.data?.hublink.clientType.length !== 0 &&
          clientType &&
          trackingNum &&
          orderInformationPageMatch
        ) {
          const queryParams = createQueryParams({
            order_by: orderBy,
            order: order,
            clientType: clientType,
            trackingNum: trackingNum,
            search: search,
          });

          dispatch(teiHubLinkOrder(queryParams));
        }
      }
    );

    teiHubLinkChannel.bind("tei-hub-link-process", (data: TransactionParam) => {
      if (
        teiHubLinkSessionState.data?.hublink.clientType.length !== 0 &&
        clientType
      ) {
        const queryParams = createQueryParams({
          page_no: pageNo,
          per_page: perPage,
          order_by: orderBy,
          order: order,
          search: search,
          store: store,
          dateType: dateType,
          startDate: startDate,
          endDate: endDate,
          clientType: clientType,
        });

        dispatch(teiHubLinkDispatchedOrders(queryParams));
      }
    });
  }, [
    dispatch,
    teiHubLinkSessionState.data,
    trackingNum,
    clientType,
    pageNo,
    perPage,
    orderBy,
    order,
    search,
    store,
    dateType,
    startDate,
    endDate,
  ]);

  return (
    <>
      <Outlet />
    </>
  );
}
