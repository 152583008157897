import { Helmet } from "react-helmet";
import { FormEvent } from "react";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { useNavigate } from "react-router-dom";
import { logoutHr } from "../slices/logout-hr.slice";
import { selectGetHrSession } from "../slices/get-hr-session.slice";
import { hrImportUsers } from "../slices/hr-import-users.slice";

export function HrEmployeesImport() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getHrSessionState = useAppSelector(selectGetHrSession);

  const handleOnSubmitPayment = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    dispatch(hrImportUsers(formData));
  };

  return (
    <>
      <Helmet>
        <title>Taters | Human Resourcee</title>
      </Helmet>
      <main className="min-h-screen text-[#242424] flex flex-col  border-b-[#F2F2F2]">
        <div className="border-b h-[50px] px-[24px] flex items-center flex justify-between flex-initial">
          <img
            src="https://www.ilovetaters.com/api/assets/images/shared/logo/taters-logo.png"
            alt="Taters Logo"
            className="w-[80px] "
          />
          <div className="flex items-center space-x-8">
            <div className="flex flex-col justify-center items-center">
              <img
                alt=""
                className="rounded-[50%] w-[25px] h-[25px] bg-[#F2F2F2] border border-gray "
                src="https://miro.medium.com/v2/resize:fill:32:32/1*dmbNkD5D-u45r44go_cf0g.png"
                loading="lazy"
                role="presentation"
              />
              <span className="text-[11px] text-[#6B6B6B] font-[400] hover:text-black cursor-pointer ">
                {getHrSessionState.data?.hr.user_personal_details?.first_name}{" "}
                {getHrSessionState.data?.hr.user_personal_details?.last_name}
              </span>
            </div>

            <span
              onClick={() => {
                dispatch(logoutHr());
              }}
              className="text-[11px] font-[400] hover:text-black cursor-pointer bg-red-700 px-4 pt-[1px] pb-[2px] rounded-full text-white"
            >
              Logout
            </span>
          </div>
        </div>

        <section className="flex-auto flex justify-start items-start py-8 px-8 space-x-2">
          <form
            onSubmit={handleOnSubmitPayment}
            className="flex flex-col items-center justify-center space-x-2 space-y-2 lg:justify-start lg:space-y-0 lg:flex-row"
          >
            <strong>Upload Users:</strong>{" "}
            <input type="file" name="users_file" id="users_file" />
            <button
              type="submit"
              className="px-3 py-1 mb-2 text-base text-white bg-green-700 rounded-md shadow-md lg:mb-0"
            >
              Upload
            </button>
            <span>(You can only upload excel or csv files)</span>
          </form>
        </section>
      </main>
    </>
  );
}
