import { HublinkBreadCrumbs } from ".";
import { HublinkBreadCrumbsProps } from "./tei-hub-link-breadcrumbs";

interface HublinkHeadProps {
  HublinkBreadCrumbsProps: HublinkBreadCrumbsProps;
}

export function HublinkHead(props: HublinkHeadProps) {
  return (
    <div className="flex justify-between p-4">
      <HublinkBreadCrumbs {...props.HublinkBreadCrumbsProps} />
    </div>
  );
}
