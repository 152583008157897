import { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { MaterialInput } from "features/shared/presentation/components";
import { useNavigate } from "react-router-dom";
import {
  TeiHubLinkCreateStoreState,
  resetTeiHubLinkCreateStore,
  selectTeiHubLinkCreateStore,
  teiHubLinkCreateStore,
} from "../slices/tei-hub-link-store-enrollment.slice";

interface formStateData {
  name: string;
  storeAddress: string;
  storeContactNumber: string;
}

export function TeiHubLinkCreateStorePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formState, setFormState] = useState<formStateData>({
    name: "",
    storeAddress: "",
    storeContactNumber: "",
  });

  const teiHubLinkCreateStoreState = useAppSelector(
    selectTeiHubLinkCreateStore
  );

  useEffect(() => {
    if (
      teiHubLinkCreateStoreState.status === TeiHubLinkCreateStoreState.success
    ) {
      dispatch(resetTeiHubLinkCreateStore());
      // navigate("/hublink");
    }
  }, [teiHubLinkCreateStoreState, navigate, dispatch]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    dispatch(teiHubLinkCreateStore(formState));
    e.preventDefault();
  };

  const handleInputChange = (evt: any) => {
    const value = evt.target.value;
    setFormState({
      ...formState,
      [evt.target.name]: value,
    });
  };

  return (
    <>
      <div className="px-96 py-10 bg-paper">
        <div className="border border-gray-200 shadow-md bg-white rounded-md p-5">
          <span className="text-secondary text-3xl font-['Bebas_Neue'] flex-1">
            Create Store
          </span>

          <div className="pb-10 space-y-6">
            <span>Please enter the user's information below.</span>

            <form onSubmit={onSubmit} className="flex flex-col space-y-4">
              <MaterialInput
                colorTheme="black"
                required
                label="Store Name"
                name="name"
                value={formState.name}
                onChange={handleInputChange}
              />
              <MaterialInput
                colorTheme="black"
                required
                label="Store Address"
                name="storeAddress"
                value={formState.storeAddress}
                onChange={handleInputChange}
              />
              {/* <MaterialInput
                colorTheme="black"
                required
                label="Store Contact Number"
                name="storeContactNumber"
                value={formState.storeContactNumber}
                onChange={handleInputChange}
              /> */}

              <textarea
                className="border border-black rounded-sm p-3 placeholder-black"
                required
                name="storeContactNumber"
                placeholder="Store Contact Number *"
                value={formState.storeContactNumber}
                onChange={handleInputChange}
              />

              <button
                type="submit"
                className="px-4 py-2 text-white rounded-lg bg-button w-fit"
              >
                Create Store
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
