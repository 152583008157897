import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { GetMinimumTotalAmountDataModel } from "features/stock-ordering/core/domain/get-minimum-total-amount.model";
import {
  GetMinimumTotalAmountHistoryRepository,
  GetMinimumTotalAmountHistoryResponse,
} from "features/stock-ordering/data/stock-ordering.repository";

export enum GetMinimumTotalAmountHistoryState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: GetMinimumTotalAmountHistoryState;
  message: string;
  data: Array<GetMinimumTotalAmountDataModel> | undefined;
}

const initialState: InitialState = {
  status: GetMinimumTotalAmountHistoryState.initial,
  message: "",
  data: undefined,
};

export const getMinimumTotalAmountHistory = createAsyncThunk(
  "getMinimumTotalAmountHistory",
  async (_, { rejectWithValue }) => {
    try {
      const response: GetMinimumTotalAmountHistoryResponse =
        await GetMinimumTotalAmountHistoryRepository();
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const getMinimumTotalAmountHistorySlice = createSlice({
  name: "getMinimumTotalAmountHistory",
  initialState,
  reducers: {
    resetGetMinimumTotalAmountHistory: (state) => {
      state.status = GetMinimumTotalAmountHistoryState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMinimumTotalAmountHistory.pending, (state) => {
        state.status = GetMinimumTotalAmountHistoryState.inProgress;
      })
      .addCase(getMinimumTotalAmountHistory.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = GetMinimumTotalAmountHistoryState.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(getMinimumTotalAmountHistory.rejected, (state, action) => {
        state.status = GetMinimumTotalAmountHistoryState.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectGetMinimumTotalAmountHistory = (state: RootState) =>
  state.getMinimumTotalAmountHistory;

export const { resetGetMinimumTotalAmountHistory } =
  getMinimumTotalAmountHistorySlice.actions;
export default getMinimumTotalAmountHistorySlice.reducer;
