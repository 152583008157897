import { selectGetAdminSession } from "features/admin/presentation/slices/get-admin-session.slice";
import { useAppSelector, useQuery } from "features/config/hooks";
import { Outlet, useNavigate } from "react-router-dom";

export function SalesTaskFormDataComparisonGuard() {
  const navigate = useNavigate();
  const getAdminSessionState = useAppSelector(selectGetAdminSession);

  const query = useQuery();
  const userType = query.get("type");

  const permissionCheck =
    getAdminSessionState.data?.admin.user_details.sales_groups.some(
      (perm) => userType !== perm.group_name
    );

  if (permissionCheck && userType !== "view") {
    navigate("/unauthorize");
  }

  return (
    <>
      <Outlet />
    </>
  );
}
