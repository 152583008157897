import { Navigate, Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { useEffect, useState } from "react";
import {
  TeiHubLinkSessionState,
  selectTeiHubLinkSession,
  teiHubLinkSession,
} from "../slices/tei-hub-link-session.slice";
import { TeiHubLinkLoginModal } from "../modals/tei-hub-link-login.modal";

export function HubLinkGuard() {
  const { clientType } = useParams();

  const dispatch = useAppDispatch();
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);

  const [openLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    dispatch(teiHubLinkSession());
  }, [dispatch]);

  if (
    teiHubLinkSessionState.status === TeiHubLinkSessionState.fail &&
    teiHubLinkSessionState.data === undefined
  ) {
    return (
      <>
        <TeiHubLinkLoginModal
          open={true}
          onClose={() => setOpenLoginModal(false)}
        />
      </>
    );
  }

  if (
    teiHubLinkSessionState.status === TeiHubLinkSessionState.fail &&
    teiHubLinkSessionState.data === null
  ) {
    return <Navigate to={"/hublink"} />;
  }

  if (
    clientType !==
      teiHubLinkSessionState.data?.hublink.clientType[1].name?.toLowerCase() &&
    teiHubLinkSessionState.data &&
    teiHubLinkSessionState.status === TeiHubLinkSessionState.success
  ) {
    return <Navigate to={"/hublink"} />;
  }

  if (
    teiHubLinkSessionState.data &&
    teiHubLinkSessionState.status === TeiHubLinkSessionState.success &&
    clientType ===
      teiHubLinkSessionState.data?.hublink.clientType[1].name?.toLowerCase()
  ) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return null;
}
