import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { useAppSelector } from "features/config/hooks";
import {
  TeiHubLinkOrderedProductsModel,
  TeiHubLinkProductModel,
  TeiHubLinkOrderedProductModel,
} from "features/hublink/core/domain/tei-hub-link.model";
import { MaterialInputAutoComplete } from "features/shared/presentation/components";
import { Column } from "features/shared/presentation/components/data-table";
import { StockOrderHandleQuantity } from "features/stock-ordering/presentation/components";
import { useState, useEffect } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { selectTeiHubLinkProducts } from "../slices/tei-hub-link-products.slice";

interface StockOrderConfirmTableProps {
  isEdit: boolean;
  rows: TeiHubLinkOrderedProductsModel | null;
  setRows: (rowData: TeiHubLinkOrderedProductsModel) => void;
  setTotalAmount: (value: number) => void;
}

const columns: Array<Column> = [
  { id: "number", label: "#" },
  { id: "productImg", label: "Image" },
  { id: "productSKU", label: "SKU Code" },
  { id: "productName", label: "Name" },
  { id: "productQuantity", label: "Quantity" },
  { id: "productCost", label: "Cost" },
  { id: "subTotal", label: "Subtotal" },
  { id: "trash", label: "" },
];

export function TeiHubLinkOrderTable(props: StockOrderConfirmTableProps) {
  const getProductInformation = useAppSelector(selectTeiHubLinkProducts);

  const [addButtonDisabled, setAddButtonDisabled] = useState(false);

  useEffect(() => {
    const lastIndex = props.rows?.products.length ?? 0;
    const lastProduct = props.rows?.products?.[lastIndex - 1];

    if (
      lastProduct?.productName === undefined ||
      lastProduct?.productName === "" ||
      lastIndex === 0
    ) {
      setAddButtonDisabled(true);
    } else {
      setAddButtonDisabled(false);
    }
  }, [props.rows]);

  const handleProductChange = (
    value: TeiHubLinkProductModel,
    rowsIndex: number
  ) => {
    const updatedRows = props.rows?.products.map((r, index) => {
      if (index === rowsIndex) {
        return {
          ...r,
          id: value.id,
          categoryName: value.categoryName,
          subCategoryName: value.subCategoryName,
          shelfLife: value.shelfLife,
          productSKUCode: value.productSKUCode,
          productName: value.productName,
          cost: value.cost,
          productQuantity: "0",
          imageLink: value.imageLink,
        };
      }
      return r;
    });
    props.setRows({ products: updatedRows ?? [] });
  };

  const addRow = () => {
    setAddButtonDisabled(true);

    const defaultRow: TeiHubLinkOrderedProductModel = {
      id: "",
      categoryName: "",
      subCategoryName: "",
      shelfLife: "",
      productSKUCode: "",
      productName: "",
      cost: 0,
      productQuantity: "",
      imageLink: "",
    };

    const updatedRows = [...(props.rows?.products ?? []), defaultRow];
    props.setRows({ products: updatedRows ?? [] });
  };

  const removeRow = (product_Id: string) => {
    const removedProduct = props.rows?.products.find(
      (product) => product.productSKUCode === product_Id
    );
    if (removedProduct) {
      const updatedRows = props.rows?.products.filter(
        (product) => product.productSKUCode !== product_Id
      );
      props.setRows({ products: updatedRows ?? [] });

      if (
        updatedRows &&
        updatedRows.length > 0 &&
        updatedRows[updatedRows.length - 1].productSKUCode !== ""
      ) {
        setAddButtonDisabled(false);
      }
    }
  };

  const subTotal = (cost: number, quantity: number) => {
    return quantity * cost;
  };

  const totalCost = props.rows?.products.reduce(
    (acc, curr) => acc + Number(curr.productQuantity) * curr.cost,
    0
  );

  useEffect(() => {
    props.setTotalAmount(totalCost ?? 0);
  }, [totalCost]);

  return (
    <div>
      <div className="border-2 border-black rounded-lg pb-1 overflow-auto">
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead className="bg-black">
            <TableRow>
              {columns.map((row, index) => (
                <TableCell key={index} align="center">
                  <span className="text-white">{row.label}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows?.products.map((productProperty, rowsIndex) => {
              const {
                id,
                categoryName,
                subCategoryName,
                shelfLife,
                productSKUCode,
                productName,
                cost,
                productQuantity,
                imageLink,
              } = productProperty;

              return (
                <TableRow key={rowsIndex}>
                  <TableCell>{rowsIndex + 1}</TableCell>
                  <TableCell>{imageLink}</TableCell>
                  <TableCell>{productSKUCode}</TableCell>
                  <TableCell>
                    {props.isEdit ? (
                      <MaterialInputAutoComplete
                        colorTheme={"black"}
                        required={true}
                        fullWidth={true}
                        size={"small"}
                        options={getProductInformation.data?.products ?? []}
                        getOptionLabel={(option) => option.productName || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.productName === value.productName
                        }
                        placeholder={productName}
                        onChange={(event, value: TeiHubLinkProductModel) => {
                          if (value) {
                            handleProductChange(value, rowsIndex);
                          }
                        }}
                        filterSelectedOptions
                      />
                    ) : (
                      <div className="flex flex-col space-y-2">
                        <span>{productName}</span>
                        <div className="space-x-1">
                          <span className="lowercase">{shelfLife}</span>
                          <span>Shelf-life</span>
                        </div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <StockOrderHandleQuantity
                      rows={props.rows?.products ?? []}
                      setRows={(rows) => props.setRows({ products: rows })}
                      rowsIndex={rowsIndex}
                      currentValue={productQuantity.toString()}
                      propertyKey={"productQuantity"}
                    />
                  </TableCell>
                  <TableCell align="right">{cost}</TableCell>
                  <TableCell align="right">
                    {subTotal(cost, Number(productQuantity)).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {props.isEdit && (
                      <FaRegTrashAlt
                        className="text-lg text-primary"
                        onClick={() => removeRow(productSKUCode)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <div className="flex justify-between p-5 py-1">
          <div className="flex justify-end items-stretch w-full">
            <span className="text-base capitalize items-center ml-3">
              Total cost: {totalCost?.toFixed(2)}
            </span>
          </div>

          {props.isEdit && (
            <div className="flex space-x-3">
              <Button
                onClick={addRow}
                disabled={addButtonDisabled}
                fullWidth
                size="small"
              >
                Add
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
