import { createQueryParams } from "features/config/helpers";
import { useAppSelector, useAppDispatch } from "features/config/hooks";
import { TeiHubLinkQrDataParam } from "features/hublink/core/tei-hub-link.param";
import {
  selectlistQrCode,
  resetListQrCode,
  ListQrCodeState,
  listQrCode,
} from "features/shared/presentation/slices/list-qr-code.slice";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  selectTeiHubLinkOrder,
  resetTeiHubLinkOrder,
} from "../slices/tei-hub-link-order.slice";
import {
  teiHubLinkSaveQrData,
  resetTeiHubLinkSaveQrData,
} from "../slices/tei-hub-link-save-qr-data.slice";
import { CircularProgress } from "@mui/material";

export function TeiHubLinkQrCodeGeneration() {
  const { clientType, trackingNum } = useParams();
  const orderState = useAppSelector(selectTeiHubLinkOrder);
  const listQrCodeState = useAppSelector(selectlistQrCode);

  const dispatch = useAppDispatch();

  const [notFound, setNotFound] = useState("");

  const [qrData, setQrData] = useState<TeiHubLinkQrDataParam | null>(null);

  useEffect(() => {
    setQrData(null);
    dispatch(resetTeiHubLinkOrder());
    dispatch(resetListQrCode());
  }, [trackingNum]);

  useEffect(() => {
    if (
      orderState.data?.orderInformation.processId === 3 &&
      clientType === "shell" &&
      orderState.data.orderInformation.qrId === ""
    ) {
      if (
        !listQrCodeState.data &&
        listQrCodeState.status !== ListQrCodeState.success &&
        orderState.data.orderInformation.qrId === ""
      ) {
        const listQrParam = createQueryParams({ limit: 100 });
        dispatch(listQrCode(listQrParam));
        dispatch(resetListQrCode());
      }

      if (listQrCodeState.data) {
        let found = false;
        for (
          let index = listQrCodeState.data.currentPageNumber;
          index < listQrCodeState.data?.lastPageNumber + 1;
          index++
        ) {
          listQrCodeState.data.items.map((qrList, i) => {
            if (
              qrList.title.split(":")[1] &&
              qrList.title.split(":")[1] === trackingNum
            ) {
              setQrData({
                id: qrList.id.toString(),
                link: qrList.image,
                trackingNumber: trackingNum,
              });
              found = true;
            }
          });

          if (found) {
            break;
          }
        }

        if (!found) {
          const nextPage = listQrCodeState.data.currentPageNumber + 1;
          if (nextPage <= listQrCodeState.data?.lastPageNumber) {
            const listQrParam = createQueryParams({
              limit: 100,
              page: nextPage,
            });
            dispatch(listQrCode(listQrParam));
            dispatch(resetListQrCode());
          } else {
            setNotFound(
              "Qr Code not found. Please contact Taters Enterprises Inc. MIS Department to verify."
            );
          }
        } else {
          if (qrData) {
            dispatch(teiHubLinkSaveQrData(qrData));
            dispatch(resetTeiHubLinkSaveQrData());
          }
        }
      }
    }

    if (
      orderState.data &&
      trackingNum &&
      orderState.data?.orderInformation.qrId !== "" &&
      orderState.data?.orderInformation.qrLink !== ""
    ) {
      setQrData({
        id: orderState.data?.orderInformation.qrId + ".png",
        link: orderState.data?.orderInformation.qrLink,
        trackingNumber: trackingNum,
      });
    }
  }, [orderState.data?.orderInformation.processId, listQrCodeState.data]);

  return (
    <div className="flex flex-wrap space-x-1 space-y-3">
      <span className="text-normal font-semibold text-center">
        Wait for the logistic to scan this qr code to recieve this order
      </span>

      {notFound ? (
        <div className="flex justify-center w-full text-center text-red-500">
          {notFound}
        </div>
      ) : qrData === null ? (
        <div className="flex justify-center py-10">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <img
            className="flex justify-center w-full"
            src={qrData?.link}
            alt={`${trackingNum} qr link`}
          />

          <span>
            One time passcode:{" "}
            <strong>{orderState.data?.orderInformation.otk}</strong>
          </span>
        </div>
      )}
    </div>
  );
}
