import { useParams } from "react-router-dom";
import {
  HublinkHead,
  TeiHubLinkLogisticReceiveMultipleOrdersContent,
} from "../components";

export function TeiHubLinkLogisticReceiveMultipleOrdersPage() {
  const { clientType } = useParams();

  return (
    <>
      <HublinkHead
        HublinkBreadCrumbsProps={{
          home: {
            title: "Tei Hub Link Multiple Receive Orders",
            url: `/hublink/${clientType}/dispatched`,
          },
          className: "lg:h-[200px]",
          pageTitles: [],
        }}
      />
      <div className="p-3">
        <TeiHubLinkLogisticReceiveMultipleOrdersContent />
      </div>
    </>
  );
}
