import { StockOrderRequestTransfer } from "./stock-order-request-transfer";

export function StockOrderingTransferContent() {
  return (
    <div className="space-y-3">
      <div className="flex flex-col px-4 lg:flex-row lg:items-end">
        <span className="text-secondary text-3xl font-['Bebas_Neue'] flex-1">
          Stock Transfer
        </span>
      </div>

      <StockOrderRequestTransfer />
    </div>
  );
}
