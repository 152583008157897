import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "features/config/store";
import { TeiHubLinkReturnRefundParam } from "features/hublink/core/tei-hub-link.param";

interface InitialState {
  data: TeiHubLinkReturnRefundParam | undefined;
}

const initialState: InitialState = {
  data: undefined,
};

export const teiHubLinkReturnRefundDataSlice = createSlice({
  name: "teiHubLinkReturnRefundData",
  initialState,
  reducers: {
    teiHubLinkReturnRefundData: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

export const selectTeiHubLinkReturnRefundData = (state: RootState) =>
  state.teiHubLinkReturnRefundData;

export const { teiHubLinkReturnRefundData } =
  teiHubLinkReturnRefundDataSlice.actions;

export default teiHubLinkReturnRefundDataSlice.reducer;
