import { useEffect } from "react";
import { teiHubLinkReceiveOrder } from "../slices/tei-hub-link-receive-order.slice";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useQuery,
} from "features/config/hooks";
import { selectTeiHubLinkOrder } from "../slices/tei-hub-link-order.slice";
import {
  closeMessageModal,
  openMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";

export function TeiHubLinkClientReceivedOrder() {
  const { trackingNum, clientType } = useParams();

  const query = useQuery();

  const received = query.get("received");

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const orderState = useAppSelector(selectTeiHubLinkOrder);

  useEffect(() => {
    if (
      location.pathname ===
        `/hublink/logistic/order/${trackingNum}/order-receive` &&
      orderState.data?.orderInformation.processId === 3 &&
      trackingNum
    ) {
      if (Boolean(received) === true) {
        dispatch(
          openMessageModal({
            message: `Are you sure you want to proceed?`,
            buttons: [
              {
                color: "#CC5801",
                text: "Yes",
                onClick: () => {
                  dispatch(
                    teiHubLinkReceiveOrder({ trackingNumber: trackingNum })
                  );
                  navigate(`/hublink/${clientType}`);
                  dispatch(closeMessageModal());
                },
              },
              {
                color: "#22201A",
                text: "No",
                onClick: () => {
                  dispatch(closeMessageModal());
                },
              },
            ],
          })
        );
      }
    }
  }, [location.pathname, orderState.data]);

  return (
    <>
      <Outlet />
    </>
  );
}
