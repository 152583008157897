import { MaterialInput } from "features/shared/presentation/components";
// import {
//   MaterialInput,
//   MaterialInputAutoComplete,
// } from "features/shared/presentation/components";
// import { useEffect, useState } from "react";
import { FormEvent, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectCashDepositInput,
  resetCashDepositInput,
  cashDepositInput,
  cashDepositInputState,
} from "../slices/cash-deposit-input.slice";
// import { cashDepositInputParam } from "features/sales/core/sales.param";

// interface formData {
//   id: string;
//   snackshopCashDeposit: string;
//   cateringCashDeposit: string;
//   resellerCashDeposit: string;
//   bulkWholeSaleInHouseDeposit: string;
//   offsiteSellingDeposit: string;
// }

export function CashDepositInputContents() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // const getLastUrlValue = String(window.location.pathname.split("/").pop());

  const [formState, setFormState] = useState({
    id: String(id),
    snackshopCashDeposit: "",
    cateringCashDeposit: "",
    resellerCashDeposit: "",
    bulkWholeSaleInHouseDeposit: "",
    offsiteSellingDeposit: "",
  });

  const submitCashDepositState = useAppSelector(selectCashDepositInput);

  useEffect(() => {
    if (submitCashDepositState.status === cashDepositInputState.success) {
      dispatch(resetCashDepositInput());
      navigate("/admin/sales/cash-deposit");
    }
  }, [submitCashDepositState, navigate, dispatch]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    dispatch(cashDepositInput(formState));
    e.preventDefault();
  };

  const handleInputChange = (evt: any) => {
    const value = evt.target.value;
    setFormState({
      ...formState,
      [evt.target.name]: value,
    });
  };

  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  // const getLastUrlValue = String(window.location.pathname.split("/").pop());

  // const submitCashDepositState = useAppSelector(selectCashDepositInput);
  // const [formState, setFormState] = useState<formData>({
  //   id: "",
  //   snackshopCashDeposit: "",
  //   cateringCashDeposit: "",
  //   resellerCashDeposit: "",
  //   bulkWholeSaleInHouseDeposit: "",
  //   offsiteSellingDeposit: "",
  // });

  // const handleOnChange = (value: string, property: string) => {
  //   setFormState((prevValue) => ({
  //     ...prevValue,
  //     [property]: value,
  //   }));
  // };

  // const handleOnSubmit = (event: { preventDefault: () => void }) => {
  //   event.preventDefault();
  //   const cashDepositInputParam: cashDepositInputParam = {
  //     id: getLastUrlValue,
  //     snackshopCashDeposit: formState.snackshopCashDeposit,
  //     cateringCashDeposit: formState.cateringCashDeposit,
  //     resellerCashDeposit: formState.resellerCashDeposit,
  //     bulkWholeSaleInHouseDeposit: formState.bulkWholeSaleInHouseDeposit,
  //     offsiteSellingDeposit: formState.offsiteSellingDeposit,
  //   };

  //   // console.log(cashDepositInputParam);

  //   dispatch(cashDepositInput(cashDepositInputParam));

  //   setFormState({
  //     id: "",
  //     snackshopCashDeposit: "",
  //     cateringCashDeposit: "",
  //     resellerCashDeposit: "",
  //     bulkWholeSaleInHouseDeposit: "",
  //     offsiteSellingDeposit: "",
  //   });
  // };

  // useEffect(() => {
  //   if (cashDepositInputState.success === submitCashDepositState.status) {
  //     navigate("/admin/sales/cash-deposit");
  //   }
  // }, [submitCashDepositState.status, cashDepositInputState.success]);

  return (
    <div className="flex flex-col space-y-5">
      <div>
        <span className="text-secondary text-3xl font-['Bebas_Neue'] flex-1">
          Input Cash Deposit
        </span>
      </div>
      <form onSubmit={onSubmit} className="w-full px-10 space-y-5">
        <MaterialInput
          fullWidth
          required
          name={"snackshopCashDeposit"}
          label="Snackshop"
          colorTheme={"black"}
          placeholder="Snackshop"
          value={formState.snackshopCashDeposit}
          onChange={handleInputChange}
        />

        <MaterialInput
          fullWidth
          required
          name={"cateringCashDeposit"}
          label="Catering"
          colorTheme={"black"}
          placeholder="Catering"
          value={formState.cateringCashDeposit}
          onChange={handleInputChange}
        />

        <MaterialInput
          fullWidth
          required
          name={"resellerCashDeposit"}
          label="Reseller"
          colorTheme={"black"}
          placeholder="Reseller"
          value={formState.resellerCashDeposit}
          onChange={handleInputChange}
        />

        <MaterialInput
          fullWidth
          required
          name={"bulkWholeSaleInHouseDeposit"}
          label="Bulk Wholesale Inhouse"
          colorTheme={"black"}
          placeholder="Bulk Wholesale Inhouse"
          value={formState.bulkWholeSaleInHouseDeposit}
          onChange={handleInputChange}
        />

        <MaterialInput
          fullWidth
          required
          name={"offsiteSellingDeposit"}
          label="Offsite Selling"
          colorTheme={"black"}
          placeholder="Offsite Selling"
          value={formState.offsiteSellingDeposit}
          onChange={handleInputChange}
        />

        {/* <MaterialInputAutoComplete
            colorTheme={"black"}
            options={department ?? []}
            label="Department (Optional)"
            getOptionLabel={(option) => option.name ?? ""}
            placeholder="Select Department"
            value={formState.department}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, selectedValue) => {
              if (selectedValue) {
                handleOnChange(selectedValue, "department");
              }
            }}
          /> */}

        {/* 👇 URGENCY AUTOCOMPLETE FIELD 👇 */}
        {/* <MaterialInputAutoComplete
            required
            colorTheme={"black"}
            options={urgency ?? []}
            label="Urgency"
            getOptionLabel={(option) => option.name ?? ""}
            placeholder="Select Urgency"
            value={formState.urgency}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, selectedValue) => {
              if (selectedValue) {
                handleOnChange(selectedValue, "urgency");
              }
            }}
          /> */}

        {/* <MaterialInput
            multiline
            rows={4}
            fullWidth
            required
            name={"ticketDetails"}
            label="Tell us more about your issue"
            colorTheme={"black"}
            placeholder="Tell us more about your issue"
            value={formState.ticketDetails}
            onChange={(event) =>
              handleOnChange(event.target.value, event.target.name)
            }
          /> */}

        <Button type="submit" sx={STOCK_ORDERING_BUTTON_STYLE} fullWidth>
          Submit
        </Button>
      </form>
    </div>
  );
}
