import React from "react";
import { CashDepositInputContents } from "../components/cash-deposit-input-content";
import { SalesHead } from "../components/sales-head";
import { useParams } from "react-router-dom";

export function CashDepositInputPage() {
  const { id } = useParams();

  return (
    <>
      <SalesHead
        SalesBreadCrumbsProps={{
          home: {
            title: "Home",
            url: "",
          },
          className: "lg:h-[200px]",
          pageTitles: [
            {
              name: "Cash Deposit",
              url: "/admin/sales/cash-deposit",
            },
            {
              name: "Input Cash Deposit",
              url: "/admin/sales/cash-deposit/input/" + id,
            },
          ],
        }}
      />

      <div className="p-4">
        <CashDepositInputContents />
      </div>
    </>
  );
}
