import { Navigate, Outlet } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { useEffect } from "react";
import {
  getAdminSession,
  selectGetAdminSession,
  GetAdminSessionState,
} from "features/admin/presentation/slices/get-admin-session.slice";

export function SalesPermissionGuard() {
  const dispatch = useAppDispatch();
  const getAdminSessionState = useAppSelector(selectGetAdminSession);

  useEffect(() => {
    dispatch(getAdminSession());
  }, [dispatch]);

  if (
    getAdminSessionState.status === GetAdminSessionState.fail &&
    getAdminSessionState.data === null
  ) {
    return <Navigate to={"/admin"} />;
  }

  if (getAdminSessionState.data?.admin.user_details.sales_groups.length === 0) {
    return <Navigate to={"/unauthorize"} />;
  }

  if (
    getAdminSessionState.data &&
    getAdminSessionState.status === GetAdminSessionState.success
  ) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return null;
}
