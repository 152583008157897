import { Button, Divider } from "@mui/material";
import { createQueryParams } from "features/config/helpers";
import {
  useQuery,
  useAppDispatch,
  useAppSelector,
} from "features/config/hooks";
import {
  defaultQrOptions,
  STOCK_ORDERING_BUTTON_STYLE,
} from "features/shared/constants";
import {
  DataTable,
  MaterialInput,
} from "features/shared/presentation/components";
import {
  Column,
  DataTableRow,
  DataTableCell,
} from "features/shared/presentation/components/data-table";
import { createQrCode } from "features/shared/presentation/slices/create-qr-code.slice";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { useState, useEffect, ChangeEvent } from "react";
import { BsQrCodeScan } from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { teiHubLinkAcceptOrder } from "../slices/tei-hub-link-accept-order.slice";
import { teiHubLinkDispatchOrder } from "../slices/tei-hub-link-dispatch-order.slice";
import { teiHubLinkLogisticReceiveOrder } from "../slices/tei-hub-link-logistic-receive.slice";
import {
  selectTeiHubLinkOrder,
  teiHubLinkOrder,
  resetTeiHubLinkOrder,
  TeiHubLinkOrder,
} from "../slices/tei-hub-link-order.slice";
import {
  teiHubLinkProducts,
  resetTeiHubLinkProducts,
} from "../slices/tei-hub-link-products.slice";
import { TeiHubLinkCompleteOrder } from "./tei-hub-link-complete-order";
import { TeiHubLinkModifyOrders } from "./tei-hub-link-modify-order-table";
import { TeiHubOrderStatusStepper } from "./tei-hub-link-order-status-stepper";
import { TeiHubLinkQrCodeGeneration } from "./tei-hub-link-qr-code-generation";
import { TeiHubLinkStoreCancelRequest } from "./tei-hub-link-store-cancel-request";
import { TeiHubLinkStoreInformation } from "./tei-hub-link-store-information";
import { TeiHubLinkSupplierInformation } from "./tei-hub-link-supplier-information";
import { dynamicDivider } from "./tei-hub-link-utils";
import {
  TeiHubLinkLogisticReceivedAction,
  TeiHubLinkOrderInformationProductContent,
} from ".";
import { selectTeiHubLinkSession } from "../slices/tei-hub-link-session.slice";
import { IoIosKeypad } from "react-icons/io";

export function TeiHubLinkOrderInformationContent() {
  const { trackingNum, clientType, edit } = useParams();

  const query = useQuery();
  const pageNo = query.get("page_no");
  const perPage = query.get("per_page");
  const orderBy = query.get("order_by");
  const order = query.get("order");
  const search = query.get("search");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const orderState = useAppSelector(selectTeiHubLinkOrder);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);

  const [commitModifiedOrders, setCommitModifiedOrders] = useState(false);

  let columns: Array<Column> = [
    { id: "num", label: "#" },
    { id: "image_link", label: "Image" },
    { id: "sku", label: "Product Name" },
    { id: "sku_code", label: "Product SKU Code" },
    { id: "cost", label: "Cost" },

    ...(orderState.data?.orderInformation.processId === 4 &&
    teiHubLinkSessionState.data?.hublink.is_Shell
      ? [
          { id: "sub_total", label: "Subtotal" },
          { id: "product_quantity", label: "Ordered Quantity" },
          { id: "delivered_quantity", label: "Delivered Quantity" },
        ]
      : [
          { id: "product_quantity", label: "orderedQuantity" },
          { id: "sub_total", label: "Subtotal" },
        ]),
    { id: "collapsibleCol", label: "" },
  ];

  useEffect(() => {
    if (trackingNum) {
      const queryParams = createQueryParams({
        page_no: pageNo,
        per_page: perPage,
        order_by: orderBy,
        order: order,
        clientType: clientType,
        trackingNum: trackingNum,
        search: search,
      });

      dispatch(teiHubLinkOrder(queryParams));
    }
  }, [trackingNum, search, order, orderBy, perPage, pageNo]);

  useEffect(() => {
    if (orderState.status === TeiHubLinkOrder.success) {
      dispatch(resetTeiHubLinkOrder());
    }
  }, [orderState.status]);

  const { processId, id } = orderState.data?.orderInformation ?? {};

  const totalAmount = orderState.data?.productInformation.reduce(
    (acc, curr) => acc + Number(curr.product_quantity) * Number(curr.cost),
    0
  );

  const handleUpdateOrderButton = (type: string) => () => {
    dispatch(
      openMessageModal({
        message: `Are you sure you want to commit and ${
          type === "logisticReceive" ? "recieve" : type
        } this order?`,
        buttons: [
          {
            color: "#CC5801",
            text: "Yes",
            onClick: () => {
              if (trackingNum) {
                switch (type) {
                  case "accept":
                    dispatch(
                      teiHubLinkAcceptOrder({ trackingNumber: trackingNum })
                    );
                    break;
                  case "dispatch":
                    dispatch(
                      teiHubLinkDispatchOrder({
                        trackingNumber: trackingNum,
                      })
                    );

                    break;
                  case "logisticReceive":
                    dispatch(
                      teiHubLinkLogisticReceiveOrder({
                        trackingNumber: trackingNum,
                      })
                    );

                    dispatch(
                      createQrCode({
                        qrFieldsData: {
                          link: `${
                            window.location.href
                          }/order-receive?received=${true}`,
                          forceCreate: false,
                        },
                        title: `HubLink-trackingNo:${trackingNum}`,
                        format: "png",
                        qrOptions: defaultQrOptions,
                      })
                    );
                    break;
                }
              }
              navigate(`/hublink/${clientType}`);
              dispatch(closeMessageModal());
            },
          },
          {
            color: "#22201A",
            text: "No",
            onClick: () => {
              dispatch(closeMessageModal());
            },
          },
        ],
      })
    );
  };

  const dataFilter = (dataFilter: {
    type: string;
    value?: string | number | boolean;
  }) => {
    const params = {
      order_by:
        dataFilter.type === "onRequestSort" ? dataFilter.value : orderBy,
      order:
        dataFilter.type === "onRequestSort"
          ? orderBy === dataFilter.value && order === "asc"
            ? "desc"
            : "asc"
          : order,
      search:
        dataFilter.type === "onSearch"
          ? dataFilter.value === ""
            ? null
            : dataFilter.value
          : search,
    };
    const queryParams = createQueryParams(params);
    if (
      dataFilter.type === "onRequestSort" ||
      dataFilter.type === "onRowsPerPageChange" ||
      dataFilter.type === "onPageChange"
    ) {
      dispatch(resetTeiHubLinkOrder());
    }
    navigate({
      pathname: "",
      search: queryParams,
    });
  };

  return (
    <>
      <div className="flex-1 space-y-5">
        <div className="flex flex-col lg:flex-row gap-5">
          <TeiHubLinkLogisticReceivedAction />
          <TeiHubLinkStoreInformation />

          <TeiHubLinkSupplierInformation />

          {clientType !== "logistic" && dynamicDivider()}

          {clientType === "supplier" ? (
            <div className="flex-1">
              {processId === 0 || processId === 1 ? (
                <div className="flex flex-col space-y-2">
                  <span className="text-normal">Note: </span>
                  <span className="text-normal">
                    {`By clicking the accept button, you confirm that you are
                ${
                  processId === 0 ? "accepting" : "dispatching"
                } this order. This process is irreversible.`}
                  </span>

                  {processId === 0 && (
                    <Button
                      onClick={handleUpdateOrderButton("accept")}
                      variant="contained"
                      sx={STOCK_ORDERING_BUTTON_STYLE}
                      disabled={Boolean(edit)}
                    >
                      <span className="text-left">Accept Order</span>
                    </Button>
                  )}

                  {processId === 1 && (
                    <>
                      <Button
                        onClick={handleUpdateOrderButton("dispatch")}
                        variant="contained"
                        sx={STOCK_ORDERING_BUTTON_STYLE}
                        disabled={Boolean(edit)}
                      >
                        <span className="text-left">Dispatch Order</span>
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <TeiHubOrderStatusStepper
                  processId={processId}
                  content={orderState.data?.orderStatusInformation ?? []}
                />
              )}
            </div>
          ) : clientType !== "logistic" ? (
            <>
              <TeiHubOrderStatusStepper
                processId={processId}
                content={orderState.data?.orderStatusInformation ?? []}
              />
              {processId !== undefined && processId === 3 && (
                <>
                  {dynamicDivider()}
                  <div className="flex-1 flex-col space-y-2">
                    <TeiHubLinkQrCodeGeneration />
                  </div>
                </>
              )}

              {processId !== undefined && processId === 4 && (
                <>
                  {dynamicDivider()}
                  <div className="flex-1 flex-col space-y-2">
                    <TeiHubLinkCompleteOrder />
                  </div>
                </>
              )}
            </>
          ) : (
            processId !== undefined &&
            processId === 2 && (
              <>
                {dynamicDivider()}
                <div className="flex-1 flex-col space-y-2">
                  <div className="flex flex-wrap space-x-1">
                    <span className="text-normal font-semibold">
                      Important:
                    </span>
                    <span className="text-normal">
                      By clicking the accept button, you acknowledge that
                      receiving this order is irreversible. Once confirmed, you
                      are committed to delivering the package promptly.
                    </span>
                  </div>
                  <Button
                    onClick={handleUpdateOrderButton("logisticReceive")}
                    variant="contained"
                    fullWidth
                    sx={STOCK_ORDERING_BUTTON_STYLE}
                    disabled={Boolean(edit)}
                  >
                    <span className="text-left">Receive Order</span>
                  </Button>
                </div>
              </>
            )
          )}

          <TeiHubLinkStoreCancelRequest processId={processId} orderId={id} />
        </div>

        {dynamicDivider()}

        <div className="">
          {orderState.data && (
            <DataTable
              order={order === "asc" ? "asc" : "desc"}
              orderBy={orderBy ?? "last_updated"}
              search={search ?? ""}
              emptyMessage={`No orders yet. 😔`}
              onSearch={(val) => dataFilter({ type: "onSearch", value: val })}
              onRequestSort={(column_selected) => {
                column_selected !== "num" &&
                  column_selected !== "image_link" &&
                  column_selected !== "collapsibleCol" &&
                  dataFilter({ type: "onRequestSort", value: column_selected });
              }}
              columns={columns}
              onRowsPerPageChange={() => {}}
              onPageChange={() => {}}
              totalRows={orderState.data?.pagination.totalRows}
              perPage={orderState.data?.pagination.perPage}
              page={1}
              noPagination={true}
              customButton={
                <>
                  {Boolean(edit) === false ? (
                    <Button
                      variant="contained"
                      size="medium"
                      sx={STOCK_ORDERING_BUTTON_STYLE}
                      disabled={
                        processId !== undefined &&
                        ((processId >= 2 && clientType === "supplier") ||
                          (processId >= 1 && clientType === "shell") ||
                          clientType === "logistic")
                      }
                      onClick={() => {
                        dispatch(teiHubLinkProducts());

                        navigate(
                          `/hublink/${clientType}/order/${trackingNum}/modify/${true}`
                        );
                      }}
                    >
                      <span className="capitalize">modify order</span>
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        size="medium"
                        sx={STOCK_ORDERING_BUTTON_STYLE}
                        onClick={() => {
                          dispatch(resetTeiHubLinkProducts());
                          setCommitModifiedOrders(true);
                        }}
                      >
                        <span className="capitalize">Commit Changes</span>
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        sx={STOCK_ORDERING_BUTTON_STYLE}
                        onClick={() => {
                          dispatch(resetTeiHubLinkProducts());

                          navigate(
                            `/hublink/${clientType}/order/${trackingNum}`
                          );
                        }}
                      >
                        <span className="capitalize">Discard Changes</span>
                      </Button>
                    </>
                  )}
                </>
              }
            >
              {Boolean(edit) ? (
                <TeiHubLinkModifyOrders
                  commitChanges={commitModifiedOrders}
                  setCommitModifiedOrders={(isChanged: boolean) =>
                    setCommitModifiedOrders(isChanged)
                  }
                />
              ) : (
                <>
                  <TeiHubLinkOrderInformationProductContent />

                  <DataTableRow>
                    <DataTableCell colSpan={columns.length - 2} />
                    <DataTableCell colSpan={2} align="right">
                      <div className="flex space-x-4">
                        <span className="font-bold text-base text-nowrap">
                          Total Cost:
                        </span>
                        <span className="text-base">{totalAmount}</span>
                      </div>
                    </DataTableCell>
                  </DataTableRow>
                </>
              )}
            </DataTable>
          )}
        </div>
      </div>
    </>
  );
}
