import { Button, CircularProgress, Divider } from "@mui/material";
import { MaterialInput } from "features/shared/presentation/components";
import { BsQrCodeScan } from "react-icons/bs";
import { dynamicDivider } from "./tei-hub-link-utils";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useAppSelector } from "features/config/hooks";
import { useEffect, useState } from "react";
import { selectTeiHubLinkOrder } from "../slices/tei-hub-link-order.slice";
import { TeiHubLinkQrCodeReader } from "./tei-hub-link-qr-code-reader";
import { FaCheck } from "react-icons/fa";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import { selectTeiHubLinkSession } from "../slices/tei-hub-link-session.slice";
import { createQueryParams } from "features/config/helpers";

export function TeiHubLinkLogisticReceivedAction() {
  const { clientType, trackingNum } = useParams();
  const query = useQuery();
  const received = query.get("received");

  const [scanQr, setScanQr] = useState(false);
  const [key, setKey] = useState("");
  const [correct, setCorrect] = useState(false);

  const navigate = useNavigate();

  const orderState = useAppSelector(selectTeiHubLinkOrder);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    setKey(value);
  };

  useEffect(() => {
    if (key.length === 6) {
      setTimeout(() => {
        if (orderState.data?.orderInformation.otk.toString() === key) {
          setCorrect(true);
        } else {
          setKey("");
        }
      }, 1000);
    }
  }, [key]);

  const handleKeyOrderReceived = () => {
    if (
      teiHubLinkSessionState.data?.hublink.is_Logistic &&
      trackingNum === orderState.data?.orderInformation.trackingNumber
    ) {
      const queryParams = createQueryParams({ received: true });
      navigate({
        pathname: "order-receive",
        search: queryParams,
      });
    }
  };

  return (
    <>
      {clientType === "logistic" &&
        Boolean(received) === false &&
        orderState.data?.orderInformation.processId === 3 && (
          <>
            <div className="flex flex-col justify-center space-y-3">
              <Button fullWidth onClick={() => setScanQr(true)}>
                <div className="border border-gray-300 rounded-lg shadow-md px-10 py-5 space-y-2">
                  <BsQrCodeScan
                    className="flex justify-center w-full text-button"
                    size={50}
                  />
                  <span className="flex justify-center w-full text-button">
                    Tap to Scan QR Code
                  </span>
                </div>
              </Button>

              <Divider>Or</Divider>
              {correct === false ? (
                <div className="flex flex-col w-full space-y-2">
                  <span>Enter one time key:</span>
                  <div className="flex space-x-2">
                    <MaterialInput
                      size="small"
                      colorTheme={"black"}
                      value={key}
                      fullWidth
                      inputProps={{ maxLength: 6 }}
                      onChange={handleInputChange}
                      name={"otk"}
                    />
                    {key !== "" && correct === false && (
                      <CircularProgress size={35} sx={{ color: "#CC5801" }} />
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex justify-center">
                  <Button
                    variant="contained"
                    sx={STOCK_ORDERING_BUTTON_STYLE}
                    startIcon={<FaCheck />}
                    onClick={handleKeyOrderReceived}
                  >
                    <span className="flex space-x-2">Confirm Received</span>
                  </Button>
                </div>
              )}
            </div>
            {dynamicDivider()}
          </>
        )}

      <TeiHubLinkQrCodeReader open={scanQr} onClose={() => setScanQr(false)} />
    </>
  );
}
