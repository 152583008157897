import { Button } from "@mui/material";
import { createQueryParams } from "features/config/helpers";
import {
  useAppDispatch,
  useQuery,
  useAppSelector,
} from "features/config/hooks";
import {
  TeiHubLinkStoreModel,
  TeiHubLinkProductsModel,
} from "features/hublink/core/domain/tei-hub-link.model";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import { MaterialInputAutoComplete } from "features/shared/presentation/components";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { teiHubLinkOrderData } from "../slices/tei-hub-link-order-data.slice";
import {
  selectTeiHubLinkStores,
  teiHubLinkStores,
} from "../slices/tei-hub-link-stores.slice";
import { TeiHubLinkProductSelection } from "./tei-hub-link-product-selection";

export function TeiHubLinkPlaceOrderContent() {
  const query = useQuery();
  const pageNo = query.get("page_no");
  const perPage = query.get("per_page");
  const orderBy = query.get("order_by");
  const order = query.get("order");
  const search = query.get("search");
  const store = query.get("store");
  const dateType = query.get("dateType");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");

  const { clientType } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getStoreSelectionData = useAppSelector(selectTeiHubLinkStores);

  const [storeInformation, setstoreInformation] =
    useState<TeiHubLinkStoreModel | null>(null);

  const [orderedProducts, setOrderedProducts] =
    useState<TeiHubLinkProductsModel["products"]>();

  useEffect(() => {
    if (!getStoreSelectionData.data) {
      const queryStoreParams = createQueryParams({
        clientType: clientType,
      });

      dispatch(teiHubLinkStores(queryStoreParams));
    }
  }, [getStoreSelectionData.data]);

  const handleStoreChange = (event: any, value: TeiHubLinkStoreModel) => {
    setstoreInformation(value ?? null);
  };

  const handleOrderConfirmation = () => {
    if (orderedProducts?.length === 0) {
      dispatch(
        openMessageModal({
          useHtml: true,
          message: `<b>Oops! It looks like you forgot to select any products</b> <br />
          Please choose at least one item before proceeding.`,
          buttons: [
            {
              color: "#CC5801",
              text: "OK",
              onClick: () => {
                dispatch(closeMessageModal());
              },
            },
          ],
        })
      );
    } else if (storeInformation === null) {
      dispatch(
        openMessageModal({
          useHtml: true,
          message: `<b>Whoops! It seems you forgot to choose a store.</b><br /> Please select a store before proceeding.`,
          buttons: [
            {
              color: "#CC5801",
              text: "OK",
              onClick: () => {
                dispatch(closeMessageModal());
              },
            },
          ],
        })
      );
    } else {
      dispatch(
        teiHubLinkOrderData({
          data: {
            storeInformation: storeInformation,
            productInformation: { products: orderedProducts },
          },
        })
      );

      const queryParams = createQueryParams({
        page_no: pageNo,
        per_page: perPage,
        order_by: orderBy,
        order: order,
        search: search,
        store: store,
        dateType: dateType,
        startDate: startDate,
        endDate: endDate,
        phase: "confirmation",
      });

      navigate({
        pathname: "",
        search: queryParams,
      });
    }
  };

  return (
    <>
      <div className="flex flex-wrap gap-2">
        <span>Select Store: </span>
        <MaterialInputAutoComplete
          colorTheme={"black"}
          filterSelectedOptions
          required={true}
          fullWidth={true}
          size={"small"}
          options={getStoreSelectionData.data?.stores ?? []}
          getOptionLabel={(option) => option.storeName || ""}
          isOptionEqualToValue={(option, value) =>
            option.storeName === value?.storeName
          }
          inputValue={storeInformation?.storeName}
          onChange={handleStoreChange}
          placeholder="Select store"
        />
      </div>
      <div>
        <TeiHubLinkProductSelection setRows={setOrderedProducts} />
      </div>

      <Button
        variant="contained"
        size="medium"
        sx={STOCK_ORDERING_BUTTON_STYLE}
        fullWidth
        onClick={handleOrderConfirmation}
      >
        Proceed to confirmation
      </Button>
    </>
  );
}
