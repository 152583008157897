import { Button, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import {
  TeiHubLinkOrderModel,
  reasonModel,
  ProductDataModel,
  TeiHubLinkProductModel,
} from "features/hublink/core/domain/tei-hub-link.model";
import { MaterialInputAutoComplete } from "features/shared/presentation/components";
import {
  DataTableRow,
  DataTableCell,
} from "features/shared/presentation/components/data-table";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { StockOrderHandleQuantity } from "features/stock-ordering/presentation/components";
import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  selectTeiHubLinkModifyOrder,
  TeiHubLinkModifyOrder,
  resetTeiHubLinkModifyOrder,
  teiHubLinkModifyOrder,
} from "../slices/tei-hub-link-modify-order.slice";
import { selectTeiHubLinkOrder } from "../slices/tei-hub-link-order.slice";
import { selectTeiHubLinkProducts } from "../slices/tei-hub-link-products.slice";
import { selectTeiHubLinkSession } from "../slices/tei-hub-link-session.slice";
import { GoRows } from "react-icons/go";
import { fill } from "lodash";
import { calculateSubTotalPerRow } from "./tei-hub-link-utils";

interface disableButton {
  addButton: boolean;
  removeButton: boolean;
}

interface TeiHubLinkModifyOrdersProps {
  commitChanges: boolean;
  setCommitModifiedOrders: (isChanged: boolean) => void;
}

export function TeiHubLinkModifyOrders(props: TeiHubLinkModifyOrdersProps) {
  const { trackingNum, clientType, edit } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const orderState = useAppSelector(selectTeiHubLinkOrder);
  const productState = useAppSelector(selectTeiHubLinkProducts);
  const modifiedChangesState = useAppSelector(selectTeiHubLinkModifyOrder);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);

  const [row, setRows] = useState<TeiHubLinkOrderModel["productInformation"]>(
    orderState.data?.productInformation ?? []
  );
  const [reason, setReason] = useState<reasonModel[]>([]);

  const [disableButton, setDisableButton] = useState<disableButton>({
    addButton: false,
    removeButton: false,
  });
  const [changed, setChanged] = useState<boolean[]>(
    new Array(row.length).fill(false)
  );

  useEffect(() => {
    if (Boolean(edit) === false) {
      setRows([]);
      setReason([]);
    }
  }, [edit]);

  useEffect(() => {
    if (modifiedChangesState.status === TeiHubLinkModifyOrder.success) {
      navigate(`/hublink/${clientType}`);
      dispatch(resetTeiHubLinkModifyOrder());
    } else {
      props.setCommitModifiedOrders(false);
    }
  }, [modifiedChangesState.status]);

  useEffect(() => {
    setRows(
      row.map((productData) => ({
        ...productData,
        sub_total: calculateSubTotalPerRow(
          productData.cost,
          productData.product_quantity
        ).toString(),
      }))
    );
  }, [row]);

  useEffect(() => {
    if (props.commitChanges) {
      if (totalCost <= 8000) {
        dispatch(
          openMessageModal({
            message: `Order Total Must Exceed ₱8000 To Change This Order?`,
            buttons: [],
          })
        );
        props.setCommitModifiedOrders(false);
      } else if (
        reason.some((reason) => reason.reason !== "") &&
        row.every((product) => product.skuCode !== "")
      ) {
        dispatch(
          openMessageModal({
            message: `Are you sure you want to commit changes to this order?`,
            buttons: [
              {
                color: "#CC5801",
                text: "Yes",
                onClick: () => {
                  dispatch(
                    teiHubLinkModifyOrder({
                      products: row,
                      reason: reason,
                      trackingNumber: trackingNum,
                      clientType: clientType,
                      processId: orderState.data?.orderInformation.processId,
                    })
                  );

                  dispatch(closeMessageModal());
                },
              },
              {
                color: "#22201A",
                text: "No",
                onClick: () => {
                  props.setCommitModifiedOrders(false);
                  dispatch(closeMessageModal());
                },
              },
            ],
          })
        );
      } else {
        dispatch(
          openMessageModal({
            message: `Please fill each reason below products and please ensure that all added products have value to commit this order?`,
            buttons: [],
          })
        );
        props.setCommitModifiedOrders(false);
      }
    }
  }, [props.commitChanges]);

  useEffect(() => {
    if (row.length !== 0) {
      if (row[row.length - 1].skuCode === "") {
        setDisableButton({
          ...disableButton,
          addButton: true,
        });
      } else {
        setDisableButton({
          ...disableButton,
          addButton: false,
        });
      }
    } else {
      handleAddButton();
    }
  }, [disableButton, row]);

  const handleAddButton = () => {
    const defaultRow: ProductDataModel = {
      categoryName: "",
      categoryDescription: "",
      subCategoryName: "",
      subCategoryDescription: "",
      skuCode: "",
      productName: "",
      shelfLife: "",
      cost: "0",
      imgLink: null,
      product_quantity: "0",
      sub_total: "0",
      current: true,
    };

    const updatedRows = [...(row ?? []), defaultRow];
    setRows(updatedRows);
    addNewElement();
  };

  const addNewElement = () => {
    setChanged([...changed, false]);
  };

  const handleRemoveButton = (product_Id: string) => () => {
    const removedProduct = row.find(
      (product) => product.skuCode === product_Id
    );

    if (removedProduct) {
      const updatedRows = row.filter(
        (product) => product.skuCode !== product_Id
      );
      setRows(updatedRows);
      changed.pop();
    }
  };

  const handleProductChange = (
    selectedOption: TeiHubLinkProductModel,
    index: number
  ) => {
    const updatedRows = row.map((productData, productIndex) => {
      if (index === productIndex) {
        return {
          ...productData,
          categoryName: selectedOption.categoryName,
          categoryDescription: "",
          subCategoryName: selectedOption.subCategoryName,
          subCategoryDescription: "",
          skuCode: selectedOption.productSKUCode,
          productName: selectedOption.productName,
          shelfLife: selectedOption.shelfLife,
          cost: selectedOption.cost.toString(),
          imgLink: selectedOption.imageLink,
          product_quantity: "0",
          sub_total: "0",
        };
      }
      return productData;
    });

    setRows(updatedRows);
  };

  const handleReasonChange = (
    text: string,
    productId: string,
    index: number
  ) => {
    const indexToUpdate = reason.findIndex(
      (reason) => reason.productSku === productId
    );

    const update = {
      trackingNumber: trackingNum,
      productSku: productId,
      reason: text,
    };

    if (indexToUpdate !== -1) {
      const updatedReasons = [...reason];
      updatedReasons[indexToUpdate] = update;
      setReason(updatedReasons);
    } else {
      setReason([...reason, update]);
    }
  };

  const totalCost = row.reduce(
    (acc, curr) => acc + Number(curr.product_quantity) * Number(curr.cost),
    0
  );

  const filteredProducts =
    productState.data?.products.filter(
      (product) =>
        row.findIndex(
          (rowProduct) => rowProduct.skuCode === product.productSKUCode
        ) === -1
    ) ?? [];

  const handleProductQuantityChange = useCallback(
    (index: number, quantity: number) => {
      if (
        orderState.data &&
        orderState.data.productInformation &&
        orderState.data.productInformation[index]
      ) {
        const productQuantity =
          orderState.data?.productInformation[index].product_quantity ?? "";
        const hasChanged = Number(quantity) !== Number(productQuantity);
        setChanged((prev) => {
          const updatedPreviousQuantities = [...prev];
          updatedPreviousQuantities[index] = hasChanged;
          return updatedPreviousQuantities;
        });
      }
    },
    [orderState]
  );

  return (
    <>
      {row.map((productData, index) => (
        <>
          {orderState.data && (
            <>
              <TableRow
                sx={{
                  "& > *": {
                    borderBottom:
                      changed[index] || productData.product_quantity === "0"
                        ? "none !important"
                        : "",
                  },
                }}
              >
                <DataTableCell align="left">{index + 1}</DataTableCell>
                <DataTableCell align="center">
                  {productData.imgLink}
                </DataTableCell>
                <DataTableCell align="left">
                  {productData.skuCode !== "" ? (
                    productData.productName
                  ) : (
                    <MaterialInputAutoComplete
                      colorTheme={"black"}
                      size="small"
                      options={filteredProducts ?? []}
                      getOptionLabel={(option) => option.productName}
                      isOptionEqualToValue={(option, value) =>
                        option.productName === value.productName
                      }
                      onChange={(e, selectedOption) =>
                        handleProductChange(selectedOption, index)
                      }
                    />
                  )}
                </DataTableCell>
                <DataTableCell align="center">
                  {productData.skuCode}
                </DataTableCell>
                <DataTableCell align="center">{productData.cost}</DataTableCell>
                <DataTableCell align="center">
                  <StockOrderHandleQuantity
                    rows={row ?? []}
                    setRows={(rowData) => {
                      setRows(rowData);

                      handleProductQuantityChange(
                        index,
                        rowData[index]?.product_quantity
                      );
                    }}
                    rowsIndex={index}
                    currentValue={productData.product_quantity}
                    propertyKey={"product_quantity"}
                  />
                </DataTableCell>

                <DataTableCell
                  colSpan={
                    index > orderState.data.productInformation.length - 1
                      ? 0
                      : 2
                  }
                  align="left"
                >
                  {calculateSubTotalPerRow(
                    productData.cost,
                    productData.product_quantity
                  )}
                </DataTableCell>
                {index > orderState.data.productInformation.length - 1 && (
                  <DataTableCell>
                    <Button
                      fullWidth
                      disabled={
                        disableButton.removeButton ||
                        teiHubLinkSessionState.data?.hublink.is_Supplier
                      }
                      onClick={handleRemoveButton(productData.skuCode)}
                    >
                      <span className="capitalize">remove</span>
                    </Button>
                  </DataTableCell>
                )}
              </TableRow>

              {index > orderState.data.productInformation.length - 1 ||
              changed[index] ||
              productData.product_quantity === "0" ? (
                <DataTableRow>
                  <DataTableCell colSpan={4} align="right" />
                  <DataTableCell colSpan={4} align="right">
                    <div className="flex flex-col space-y-1">
                      <span>State your reason: </span>
                      <textarea
                        required
                        className="w-full h-full text-base p-1 border border-black rounded-md"
                        maxLength={500}
                        onChange={(event) =>
                          handleReasonChange(
                            event.target.value,
                            productData.skuCode,
                            index
                          )
                        }
                      />
                    </div>
                  </DataTableCell>
                </DataTableRow>
              ) : null}
            </>
          )}
        </>
      ))}

      <DataTableRow>
        <DataTableCell colSpan={8}>
          <div className="flex flex-row justify-between space-x-3">
            <Button
              fullWidth
              size="small"
              disabled={
                disableButton.addButton ||
                teiHubLinkSessionState.data?.hublink.is_Supplier
              }
              onClick={handleAddButton}
            >
              <span className="capitalize">add</span>
            </Button>
            <div className="flex flex-row space-x-1 items-center">
              <span className="font-bold text-base text-nowrap">
                Total Cost:
              </span>
              <span className="text-base">{totalCost.toFixed(2)}</span>
            </div>
          </div>
        </DataTableCell>
      </DataTableRow>
    </>
  );
}
