import { useAppSelector, useQuery } from "features/config/hooks";
import { IoMdClose } from "react-icons/io";
import { TeiHubLinkPlaceOrderContent } from "../components";
import { TeiHubLinkOrderConfirmation } from "../components/tei-hub-link-order-confirmation-content";
import { selectpopupScroll } from "features/stock-ordering/presentation/slices/popup-scroll.slice";

interface TeiHubLinkPlaceOrderModalProps {
  open: boolean;
  onClose: () => void;
}

export function TeiHubLinkPlaceOrderModal(
  props: TeiHubLinkPlaceOrderModalProps
) {
  const query = useQuery();
  const phase = query.get("phase");

  const popupModalState = useAppSelector(selectpopupScroll);

  const handleCloseModal = () => {
    document.body.classList.remove("overflow-hidden");
    props.onClose();
  };

  if (props.open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
    return null;
  }

  return (
    <div
      className={`fixed inset-0 z-30 flex items-start justify-center ${
        !popupModalState.status &&
        "overflow-auto bg-black bg-opacity-30 backdrop-blur-sm"
      } `}
    >
      <div className="w-[97%] lg:w-[900px] my-5 rounded-[10px]">
        <div className="bg-secondary rounded-t-[10px] flex items-center justify-between p-4">
          <span className="text-2xl text-white">
            {phase === "placement"
              ? "HubLink Order Placement"
              : phase === "confirmation"
              ? "HubLink Order Confirmation"
              : null}
          </span>

          <div className="space-x-3">
            <button className="text-2xl text-white" onClick={handleCloseModal}>
              <IoMdClose />
            </button>
          </div>
        </div>

        <div className="p-4 bg-white border-b-2 border-l-2 rounded-b-md border-r-2 border-secondary space-y-5">
          {phase === "placement" && <TeiHubLinkPlaceOrderContent />}
          {phase === "confirmation" && (
            <TeiHubLinkOrderConfirmation
              orderPlaced={(orderPlaced: boolean) => {
                if (orderPlaced) {
                  handleCloseModal();
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
