import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { TeiHubLinkOrdersModel } from "features/hublink/core/domain/tei-hub-link.model";
import {
  GetTeiHubLinkDispatchedOrdersResponse,
  GetTeiHubLinkDispatchedOrdersRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkDispatchedOrders {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkDispatchedOrders;
  message: string;
  data: TeiHubLinkOrdersModel | undefined;
}

const initialState: InitialState = {
  status: TeiHubLinkDispatchedOrders.initial,
  message: "",
  data: undefined,
};

export const teiHubLinkDispatchedOrders = createAsyncThunk(
  "teiHubLinkDispatchedOrders",
  async (param: string, { rejectWithValue }) => {
    try {
      const response: GetTeiHubLinkDispatchedOrdersResponse =
        await GetTeiHubLinkDispatchedOrdersRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkDispatchedOrdersSlice = createSlice({
  name: "teiHubLinkDispatchedOrders",
  initialState,
  reducers: {
    resetTeiHubLinkDispatchedOrders: (state) => {
      state.status = TeiHubLinkDispatchedOrders.initial;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkDispatchedOrders.pending, (state) => {
        state.status = TeiHubLinkDispatchedOrders.inProgress;
      })
      .addCase(teiHubLinkDispatchedOrders.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = TeiHubLinkDispatchedOrders.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(teiHubLinkDispatchedOrders.rejected, (state, action) => {
        state.status = TeiHubLinkDispatchedOrders.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectTeiHubLinkDispatchedOrders = (state: RootState) =>
  state.teiHubLinkDispatchedOrders;

export const { resetTeiHubLinkDispatchedOrders } =
  teiHubLinkDispatchedOrdersSlice.actions;
export default teiHubLinkDispatchedOrdersSlice.reducer;
