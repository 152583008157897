import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import { useNavigate, useParams } from "react-router-dom";
import { teiHubLinkCompleteOrder } from "../slices/tei-hub-link-complete-order.slice";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { FaExclamationTriangle } from "react-icons/fa";
import { selectTeiHubLinkReturnRefundData } from "../slices/tei-hub-link-return-refund-data.slice";
import { teiHubLinkReturnRefund } from "../slices/tei-hub-link-return-refund.slice";

export function TeiHubLinkCompleteOrder() {
  const { trackingNum, clientType } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const completeOrderState = useAppSelector(selectTeiHubLinkReturnRefundData);

  const handleCompleteOrders = () => {
    if (
      trackingNum &&
      completeOrderState.data?.productData &&
      completeOrderState.data.triggerAction === false
    ) {
      dispatch(
        openMessageModal({
          message: `Congratulations on completing your order. Kindly inspect received items for condition and quantity accuracy. Please be aware that finalizing the order is permanent. Would you like to proceed?`,
          buttons: [
            {
              color: "#CC5801",
              text: "Yes",
              onClick: () => {
                dispatch(
                  teiHubLinkCompleteOrder({
                    trackingNumber: trackingNum,
                  })
                );
                navigate(`/hublink/${clientType}`);
                dispatch(closeMessageModal());
              },
            },
            {
              color: "#22201A",
              text: "No",
              onClick: () => {
                dispatch(closeMessageModal());
              },
            },
          ],
        })
      );
    }
  };

  const handleReturnRefund = () => {
    if (
      trackingNum &&
      completeOrderState.data?.productData &&
      completeOrderState.data?.productReturnRefund &&
      completeOrderState.data.triggerAction === true
    ) {
      if (
        completeOrderState.data?.productReturnRefund?.every(
          (product) =>
            (product.reason !== "" &&
              (product.damaged === true || product.missing == true)) ||
            (product.reason === "" &&
              product.damaged === false &&
              product.missing == false)
        )
      ) {
        dispatch(
          openMessageModal({
            message: `This process is irreversible. Would you like to proceed?`,
            buttons: [
              {
                color: "#CC5801",
                text: "Yes",
                onClick: () => {
                  dispatch(
                    teiHubLinkReturnRefund({
                      productData: completeOrderState.data?.productData,
                      productReturnRefund:
                        completeOrderState.data?.productReturnRefund,
                      trackingNumber: trackingNum,
                    })
                  );
                  navigate(`/hublink/${clientType}`);
                  dispatch(closeMessageModal());
                },
              },
              {
                color: "#22201A",
                text: "No",
                onClick: () => {
                  dispatch(closeMessageModal());
                },
              },
            ],
          })
        );
      } else {
        dispatch(
          openMessageModal({
            message: `Please fill each reason below products.`,
            buttons: [
              {
                color: "#22201A",
                text: "Ok",
                onClick: () => {
                  dispatch(closeMessageModal());
                },
              },
            ],
          })
        );
      }
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-wrap space-x-1">
        <span className="text-normal font-semibold">
          Important Instructions:
        </span>
        <span className="text-normal">
          - <strong>Missing Products:</strong> If there are any missing
          products, please adjust the delivered quantity and provide remarks
          explaining the discrepancy.
        </span>
        <span className="text-normal">
          - <strong>Damaged Products:</strong> For any damaged products, click
          the{"  "}
          <span className="inline-block">
            <FaExclamationTriangle className="text-yellow-500" size={20} />
          </span>
          {"  "}
          icon and provide remarks detailing the damage.
        </span>
        <span className="text-normal">
          - <strong>Complete Products:</strong> If there are no missing or
          damaged products, please click the complete order
        </span>
        <span className="text-normal mt-3">
          Thank you for your attention to these details.
        </span>
      </div>

      <div className="space-y-3">
        {completeOrderState.data?.triggerAction ? (
          <Button
            onClick={handleReturnRefund}
            variant="contained"
            fullWidth
            sx={STOCK_ORDERING_BUTTON_STYLE}
          >
            <span className="text-left">Return/Refund</span>
          </Button>
        ) : (
          <Button
            onClick={handleCompleteOrders}
            variant="contained"
            fullWidth
            sx={STOCK_ORDERING_BUTTON_STYLE}
          >
            <span className="text-left">Complete Order</span>
          </Button>
        )}
      </div>
    </div>
  );
}
