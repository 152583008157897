import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Skeleton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Grid,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { TeiHubLinkProductsModel } from "features/hublink/core/domain/tei-hub-link.model";
import { useState, useEffect } from "react";
import {
  selectTeiHubLinkProducts,
  teiHubLinkProducts,
} from "../slices/tei-hub-link-products.slice";

function not(a: readonly ProductArray[], b: readonly ProductArray[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly ProductArray[], b: readonly ProductArray[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly ProductArray[], b: readonly ProductArray[]) {
  return [...a, ...not(b, a)];
}

interface TeiHubLinkProductSelectionProps {
  setRows: ((rows: TeiHubLinkProductsModel["products"]) => void) | undefined;
}

interface ProductById {
  id: string;
  categoryName: string;
  subCategoryName: string;
  shelfLife: string;
  productSKUCode: string;
  productName: string;
  cost: number;
  imageLink: string | null;
}

interface ProductArray extends Array<ProductById> {}

export function TeiHubLinkProductSelection(
  props: TeiHubLinkProductSelectionProps
) {
  const dispatch = useAppDispatch();
  const getProductInformation = useAppSelector(selectTeiHubLinkProducts);

  const [waitingForProducts, setWaitingForProducts] = useState(true);

  const [checked, setChecked] = useState<readonly ProductArray[]>([]);
  const [left, setLeft] = useState<readonly ProductArray[]>([]);
  const [right, setRight] = useState<readonly ProductArray[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    dispatch(teiHubLinkProducts());

    setLeft([]);
    setRight([]);
  }, [dispatch]);

  useEffect(() => {
    if (getProductInformation.data?.products) {
      const leftData: ProductArray[] = getProductInformation.data.products.map(
        (row) => {
          const {
            id,
            categoryName,
            subCategoryName,
            productName,
            productSKUCode,
            shelfLife,
            cost,
            imageLink,
          } = row;
          return [
            {
              id: id,
              categoryName: categoryName,
              subCategoryName: subCategoryName,
              shelfLife: shelfLife,
              productSKUCode: productSKUCode,
              productName: productName,
              cost: cost,
              imageLink: imageLink,
            },
          ];
        }
      );
      setLeft(leftData);
      setWaitingForProducts(false);
    } else {
      setWaitingForProducts(true);
    }
  }, [getProductInformation.data?.products]);

  useEffect(() => {
    const rightProductData: TeiHubLinkProductsModel["products"] = right.flatMap(
      (productArray: ProductArray) =>
        productArray.map((product: ProductById) => {
          const {
            id,
            categoryName,
            subCategoryName,
            productName,
            productSKUCode,
            shelfLife,
            cost,
            imageLink,
          } = product;
          return {
            id: id,
            categoryName: categoryName,
            subCategoryName: subCategoryName,
            shelfLife: shelfLife,
            productSKUCode: productSKUCode,
            productName: productName,
            cost: cost,
            imageLink: imageLink,
          };
        })
    );

    props.setRows?.(rightProductData);
  }, [right]);

  const handleToggle = (value: ProductArray) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly ProductArray[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly ProductArray[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (
    title: React.ReactNode,
    items: readonly ProductArray[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />

      {waitingForProducts ? (
        <div className="flex flex-col space-y-1 px-1">
          <Skeleton width={350} height={30} animation="wave" />
          <Skeleton width={350} height={30} animation="wave" />
          <Skeleton width={350} height={30} animation="wave" />
          <Skeleton width={350} height={30} animation="wave" />
        </div>
      ) : (
        <List
          sx={{
            width: 350,
            height: 230,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value: ProductArray, index: number) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <>
                <ListItemButton
                  key={index}
                  role="listitem"
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={value.map((row) => row.productName)}
                  />
                </ListItemButton>
                <Divider variant="middle" />
              </>
            );
          })}
        </List>
      )}
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList("Products", left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Added Products", right)}</Grid>
    </Grid>
  );
}
