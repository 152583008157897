import { useAppSelector } from "features/config/hooks";
import { useParams } from "react-router-dom";
import { selectTeiHubLinkOrder } from "../slices/tei-hub-link-order.slice";
import { dynamicDivider, dateSetup } from "./tei-hub-link-utils";

export function TeiHubLinkSupplierInformation() {
  const { clientType } = useParams();
  const orderState = useAppSelector(selectTeiHubLinkOrder);

  const { warehouseName, expectedDeliveryDate, statusName } =
    orderState.data?.orderInformation || {};

  return (
    <>
      {dynamicDivider()}
      <div className="flex-1">
        <div className="flex flex-col">
          <span className="text-gray-400">Supplier Information</span>
          <span className="text-normal">{warehouseName}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-400">Werehouse</span>
          <span className="text-normal">{warehouseName}</span>
        </div>

        <div className="flex flex-col">
          <span className="text-gray-400">Expected Delivery Date</span>
          <span className="text-normal">
            {dateSetup(expectedDeliveryDate ?? "", true)}
          </span>
        </div>
        {clientType === "supplier" && (
          <div className="flex flex-col">
            <span className="text-gray-400">Order Status</span>
            <span className="text-normal">{statusName}</span>
          </div>
        )}
      </div>
    </>
  );
}
