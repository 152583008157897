import { useParams } from "react-router-dom";
import {
  HublinkHead,
  TeiHubLinkCompletedOrders,
  TeiHubLinkSoaContent,
} from "../components";

export function TeiHubLinkSoaPage() {
  const { clientType } = useParams();

  return (
    <>
      <HublinkHead
        HublinkBreadCrumbsProps={{
          home: {
            title: "SOA",
            url: `/hublink/soa/${clientType}`,
          },
          className: "lg:h-[200px]",
          pageTitles: [],
        }}
      />
      <div className="p-3">
        <TeiHubLinkSoaContent />
      </div>
    </>
  );
}
