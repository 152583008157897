import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { GetProductLatestPriceModel } from "features/stock-ordering/core/domain/getProductLatestPrice.model";
import {
  GetProductLatestPriceRepository,
  GetProductLatestPriceResponse,
} from "features/stock-ordering/data/stock-ordering.repository";

export enum GetProductLatestPriceState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: GetProductLatestPriceState;
  message: string;
  data: GetProductLatestPriceModel | undefined;
}

const initialState: InitialState = {
  status: GetProductLatestPriceState.initial,
  message: "",
  data: undefined,
};

export const getProductLatestPrice = createAsyncThunk(
  "getProductLatestPrice",
  async (_, { rejectWithValue }) => {
    try {
      const response: GetProductLatestPriceResponse =
        await GetProductLatestPriceRepository();
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const getProductLatestPriceSlice = createSlice({
  name: "getProductLatestPrice",
  initialState,
  reducers: {
    resetGetProductLatestPrice: (state) => {
      state.status = GetProductLatestPriceState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductLatestPrice.pending, (state) => {
        state.status = GetProductLatestPriceState.inProgress;
      })
      .addCase(getProductLatestPrice.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = GetProductLatestPriceState.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(getProductLatestPrice.rejected, (state, action) => {
        state.status = GetProductLatestPriceState.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectGetProductLatestPrice = (state: RootState) =>
  state.getProductLatestPrice;

export const { resetGetProductLatestPrice } =
  getProductLatestPriceSlice.actions;
export default getProductLatestPriceSlice.reducer;
