import axios from "axios";
import { REACT_APP_DOMAIN_URL } from "features/shared/constants";
import {
  LoginHubLinkParam,
  ModifyOrderPram,
  OrderDataParam,
  TeiHubLinkCreateStoreParam,
  TeiHubLinkCreateUserParam,
  TeiHubLinkOrderReturnRefundParam,
  TeiHubLinkQrDataParam,
  storeCancelOrderParam,
  updateOrderParam,
} from "../core/tei-hub-link.param";
import { TeiHubLinkSessionModel } from "../core/domain/tei-hub-link-session.model";
import {
  TeiHubLinkProductsModel,
  TeiHubLinkStoresModel,
  TeiHubLinkOrdersModel,
  TeiHubLinkOrderModel,
} from "../core/domain/tei-hub-link.model";
import {
  TeiHubLinkAllGroupsModel,
  TeiHubLinkAllStoresModel,
} from "../core/domain/tei-hub-link-group.model";
import { SoaModel } from "../core/domain/tei-hub-link-soa.model";

// Tei HubLink Login
export interface LoginHubLinkResponse {
  data: {
    message: string;
  };
}

export function LoginHubLinkRepository(
  param: LoginHubLinkParam
): Promise<LoginHubLinkResponse> {
  return axios.post(`${REACT_APP_DOMAIN_URL}api/auth-hub-link/login`, param, {
    withCredentials: true,
  });
}

// Session
export interface teiHubLinkSessionResponse {
  data: {
    message: string;
    data: TeiHubLinkSessionModel;
  };
}

export function teiHubLinkSessionRepository(): Promise<teiHubLinkSessionResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hub-link/session`, {
    withCredentials: true,
  });
}

//create user

export interface teiHubLinkCreateUserResponse {
  data: {
    message: string;
  };
}

export function teiHubLinkCreateUserRepository(
  param: TeiHubLinkCreateUserParam
): Promise<teiHubLinkCreateUserResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/auth-hub-link/create-user`,
    param,
    {
      withCredentials: true,
    }
  );
}

export interface GetTeiHubLinkProductsResponse {
  data: {
    message: string;
    data: TeiHubLinkProductsModel;
  };
}

export function GetTeiHubLinkProductsRepository(): Promise<GetTeiHubLinkProductsResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hub-link/products`, {
    withCredentials: true,
  });
}

//Store selection on order placement
export interface GetTeiHubLinkStoresResponse {
  data: {
    message: string;
    data: TeiHubLinkStoresModel;
  };
}

export function GetTeiHubLinkStoresRepository(
  param: string
): Promise<GetTeiHubLinkStoresResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hub-link/stores${param}`, {
    withCredentials: true,
  });
}

//Order Placement
export interface GetTeiHubLinkPlaceOrderResponse {
  data: {
    message: string;
  };
}

export function GetTeiHubLinkPlaceOrderRepository(
  param: OrderDataParam
): Promise<GetTeiHubLinkPlaceOrderResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/place-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

//Orders
export interface GetTeiHubLinkOrdersResponse {
  data: {
    message: string;
    data: TeiHubLinkOrdersModel;
  };
}

export function GetTeiHubLinkOrdersRepository(
  param: string
): Promise<GetTeiHubLinkOrdersResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hub-link/orders${param}`, {
    withCredentials: true,
  });
}

//Dispached Orders Multiple
export interface GetTeiHubLinkDispatchedOrdersResponse {
  data: {
    message: string;
    data: TeiHubLinkOrdersModel;
  };
}

export function GetTeiHubLinkDispatchedOrdersRepository(
  param: string
): Promise<GetTeiHubLinkDispatchedOrdersResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hub-link/orders/dispatch-multiple${param}`,
    {
      withCredentials: true,
    }
  );
}

//Logistic Recieve Orders Multiple
export interface GetTeiHubLinkLogisticReceiveMultipleOrdersResponse {
  data: {
    message: string;
    data: TeiHubLinkOrdersModel;
  };
}

export function GetTeiHubLinkLogisticReceiveMultipleOrdersRepository(
  param: string
): Promise<GetTeiHubLinkLogisticReceiveMultipleOrdersResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/logistic-receive-multiple${param}`,
    {
      withCredentials: true,
    }
  );
}

//Order
export interface GetTeiHubLinkOrderResponse {
  data: {
    message: string;
    data: TeiHubLinkOrderModel;
  };
}

export function GetTeiHubLinkOrderRepository(
  param: string
): Promise<GetTeiHubLinkOrderResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hub-link/order${param}`, {
    withCredentials: true,
  });
}

//Accept Order
export interface GetTeiHubLinkAcceptOrderResponse {
  data: {
    message: string;
    data: TeiHubLinkOrderModel;
  };
}

export function GetTeiHubLinkAcceptOrderRepository(
  param: updateOrderParam
): Promise<GetTeiHubLinkAcceptOrderResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/accept-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

//Dispatch Order
export interface GetTeiHubLinkDispatchOrderResponse {
  data: {
    message: string;
    data: TeiHubLinkOrderModel;
  };
}

export function GetTeiHubLinkDispatchOrderRepository(
  param: updateOrderParam
): Promise<GetTeiHubLinkDispatchOrderResponse> {
  return axios.post(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/dispatch-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

//Store Cancel Order
export interface GetTeiHubLinkStoreCancelOrderResponse {
  data: {
    message: string;
  };
}

export function GetTeiHubLinkStoreCancelOrderRepository(
  param: storeCancelOrderParam
): Promise<GetTeiHubLinkStoreCancelOrderResponse> {
  return axios.put(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/store-cancel-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

//Store Modify Order
export interface GetTeiHubLinkModifyOrderResponse {
  data: {
    message: string;
  };
}

export function GetTeiHubLinkModifyOrderRepository(
  param: ModifyOrderPram
): Promise<GetTeiHubLinkModifyOrderResponse> {
  return axios.put(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/modify-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

//Complted Order
export interface GetTeiHubLinkCompletedOrderResponse {
  data: {
    message: string;
    data: TeiHubLinkOrdersModel;
  };
}

export function GetTeiHubLinkCompletedOrderRepository(
  param: string
): Promise<GetTeiHubLinkCompletedOrderResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/completed-order${param}`,
    {
      withCredentials: true,
    }
  );
}

//Cancelled Order
export interface GetTeiHubLinkCancelledOrderResponse {
  data: {
    message: string;
    data: TeiHubLinkOrdersModel;
  };
}

export function GetTeiHubLinkCancelledOrderRepository(
  param: string
): Promise<GetTeiHubLinkCancelledOrderResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/cancelled-order${param}`,
    {
      withCredentials: true,
    }
  );
}

//Logistic Receive Order
export interface GetTeiHubLinkLogisticReceiveOrderResponse {
  data: {
    message: string;
  };
}

export function GetTeiHubLinkLogisticReceiveOrderRepository(
  param: updateOrderParam
): Promise<GetTeiHubLinkLogisticReceiveOrderResponse> {
  return axios.put(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/logistic-receive-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

//Save Qr Data
export interface GetTeiHubLinkSaveQrDataResponse {
  data: {
    message: string;
  };
}

export function GetTeiHubLinkSaveQrDataRepository(
  param: TeiHubLinkQrDataParam
): Promise<GetTeiHubLinkSaveQrDataResponse> {
  return axios.put(`${REACT_APP_DOMAIN_URL}api/hub-link/qr/save-qr`, param, {
    withCredentials: true,
  });
}

//Receive Order
export interface GetTeiHubLinkReceiveOrderResponse {
  data: {
    message: string;
  };
}

export function GetTeiHubLinkReceiveOrderRepository(
  param: updateOrderParam
): Promise<GetTeiHubLinkReceiveOrderResponse> {
  return axios.put(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/receive-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

//Complete Order
export interface GetTeiHubLinkCompleteOrderResponse {
  data: {
    message: string;
  };
}

export function GetTeiHubLinkCompleteOrderRepository(
  param: updateOrderParam
): Promise<GetTeiHubLinkCompleteOrderResponse> {
  return axios.put(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/complete-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

// All groups
export interface GetTeiHubLinkAllGroupsResponse {
  data: {
    message: string;
    data: Array<TeiHubLinkAllGroupsModel>;
  };
}

export function GetTeiHubLinkAllGroupsRepository(): Promise<GetTeiHubLinkAllGroupsResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hub-link/user/all-groups`,

    {
      withCredentials: true,
    }
  );
}

// All stores
export interface GetTeiHubLinkAllStoresResponse {
  data: {
    message: string;
    data: Array<TeiHubLinkAllStoresModel>;
  };
}

export function GetTeiHubLinkAllStoresRepository(
  param: string
): Promise<GetTeiHubLinkAllStoresResponse> {
  return axios.get(
    `${REACT_APP_DOMAIN_URL}api/hub-link/user/all-stores${param}`,

    {
      withCredentials: true,
    }
  );
}

// logout
export interface LogoutHubLinkResponse {
  data: {
    message: string;
  };
}

export function LogoutHubLinkRepository(): Promise<LogoutHubLinkResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/auth-hub-link/logout`, {
    withCredentials: true,
  });
}

// Return Refund
export interface GetTeiHubLinkReturnRefundResponse {
  data: {
    message: string;
  };
}

export function GetTeiHubLinkReturnRefundRepository(
  param: TeiHubLinkOrderReturnRefundParam
): Promise<GetTeiHubLinkReturnRefundResponse> {
  return axios.put(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/return-refund-order`,
    param,
    {
      withCredentials: true,
    }
  );
}

// Soa

export interface GetTeiHubLinkSoaResponse {
  data: {
    message: string;
    data: SoaModel;
  };
}

export function GetTeiHubLinkSoaRepository(
  param: string
): Promise<GetTeiHubLinkSoaResponse> {
  return axios.get(`${REACT_APP_DOMAIN_URL}api/hub-link/soa/soa_data${param}`, {
    withCredentials: true,
  });
}

// Dispatch Update Multiple
export interface TeiHubLinkDispatchMultipleResponse {
  data: {
    message: string;
  };
}

export function TeiHubLinkDispatchMultipleRepository(param: {
  id: string[];
}): Promise<TeiHubLinkDispatchMultipleResponse> {
  return axios.put(
    `${REACT_APP_DOMAIN_URL}api/hub-link/orders/dispatch-multiple`,
    param,
    {
      withCredentials: true,
    }
  );
}

//Logistic Recieve Orders Multiple update
export interface TeiHubLinkLogisticReceiveMultipleOrderResponse {
  data: {
    message: string;
  };
}

export function TeiHubLinkLogisticReceiveMultipleOrderRepository(param: {
  id: string[];
}): Promise<TeiHubLinkLogisticReceiveMultipleOrderResponse> {
  return axios.put(
    `${REACT_APP_DOMAIN_URL}api/hub-link/order/logistic-receive-multiple`,
    param,
    {
      withCredentials: true,
    }
  );
}

// Create store
export interface teiHubLinkCreateStoreResponse {
  data: {
    message: string;
  };
}

export function teiHubLinkCreateStoreRepository(
  param: TeiHubLinkCreateStoreParam
): Promise<teiHubLinkCreateStoreResponse> {
  return axios.post(`${REACT_APP_DOMAIN_URL}api/hub-link/create/store`, param, {
    withCredentials: true,
  });
}
