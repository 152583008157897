import { Button, colors } from "@mui/material";

export function StockOrderRequestTransfer() {
  return (
    <div className="block rounded-lg bg-white p-6 text-surface shadow-secondary-1 space-y-3">
      <div className="flex justify-between">
        <span className="text-secondary text-2xl font-['Bebas_Neue'] flex-1">
          Requests
        </span>

        <Button sx={{ color: "#CC5801" }}>Request a stock</Button>
      </div>
    </div>
  );
}
