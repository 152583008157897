import { ListItemText, List, ListItem, Divider } from "@mui/material";
import { PopupModal } from "./popup.modal";
import { useAppSelector } from "features/config/hooks";
import { selectGetMinimumTotalAmountHistory } from "../slices/get-minimum-total-amount-history.slice";
import { dateSetup } from "../components/stock-ordering-utils";
import { selectGetMinimumTotalAmountData } from "../slices/get-minimum-total-amount.slice";

interface MinimumTotalAmountHistoryProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

export function MinimumTotalAmountHistory(
  props: MinimumTotalAmountHistoryProps
) {
  const getMinimumTotalAmountHistoryState = useAppSelector(
    selectGetMinimumTotalAmountHistory
  );

  const getMinimumTotalAmountState = useAppSelector(
    selectGetMinimumTotalAmountData
  );

  return (
    <PopupModal
      noIcon
      open={props.open}
      title={"Minimum Total Amount Change History"}
      message={""}
      customButton
      onClose={() => props.onClose(false)}
      customModalWidth="md:w-[50%] lg:w-[460px]"
    >
      <div className="p-2 border-2 max-h-72 md:max-h-96 lg:max-h-96 overflow-y-auto">
        <div>
          <ListItemText
            primary={`Current minimum total amount: ₱${
              getMinimumTotalAmountState.data?.totalPrice ?? "10000"
            }`}
            primaryTypographyProps={{ color: "#555555" }}
          />
        </div>

        {getMinimumTotalAmountHistoryState?.data?.map((row, index) => {
          return (
            <>
              <List dense>
                <ListItem
                  sx={{
                    gap: 2,
                  }}
                >
                  <ListItemText
                    primary={`Amount: ₱${row.totalPrice}`}
                    secondary={`as of ${dateSetup(row.effectivityDate, true)}`}
                  />
                </ListItem>
              </List>
              {getMinimumTotalAmountHistoryState.data?.length! - 1 !==
                index && <Divider />}
            </>
          );
        })}
      </div>
    </PopupModal>
  );
}
