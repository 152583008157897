import { Button, Tab, Tabs, MobileStepper, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  useQuery,
} from "features/config/hooks";
import {
  GetSalesActiveFieldsState,
  getSalesActiveFields,
  selectGetSalesActiveFields,
} from "../slices/get-active-fields.slice";
import { CheckParam, SubmitFormParam } from "features/sales/core/sales.param";
import {
  TabPanel,
  fieldToHide,
  formatDate,
  getFormState,
  hidePanel,
  initialFormState,
} from "./sales-utils";
import { useNavigate } from "react-router-dom";
import {
  GetSalesFormDataState,
  getSalesFormData,
  resetGetSalesFormData,
  selectGetSalesFormData,
} from "../slices/get-sales-form-content.slice";
import { createQueryParams } from "features/config/helpers";
import { FormFieldData, SalesTaskFormDataComparison } from ".";
import { salesSubmitVerdict } from "../slices/sales-submit-verdict.slice";
import { STOCK_ORDERING_BUTTON_STYLE, theme } from "features/shared/constants";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

export default function SalesTaskContent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getSalesActiveFieldsState = useAppSelector(selectGetSalesActiveFields);
  const getSalesFormDataState = useAppSelector(selectGetSalesFormData);

  const [cashierFormState, setCashierFormState] =
    useState<SubmitFormParam["formState"]>();
  const [tcFormState, setTcFormState] =
    useState<SubmitFormParam["formState"]>();
  const [managerFormState, setManagerFormState] =
    useState<SubmitFormParam["formState"]>();

  const [tabValue, setTabValue] = useState(0);

  const grade = useRef("0");
  const query = useQuery();

  const formId = query.get("id");
  const userType = query.get("type");

  useEffect(() => {
    const queryParams = createQueryParams({ id: formId, type: userType });
    dispatch(resetGetSalesFormData());
    dispatch(getSalesFormData(queryParams));
  }, [dispatch, formId, userType]);

  useEffect(() => {
    const queryParams = createQueryParams({ id: formId, type: userType });

    if (
      (getSalesActiveFieldsState.status !== GetSalesActiveFieldsState.success &&
        !getSalesActiveFieldsState.data) ||
      (getSalesFormDataState.status !== GetSalesFormDataState.success &&
        !getSalesFormDataState.data)
    ) {
      dispatch(getSalesActiveFields());
      dispatch(getSalesFormData(queryParams));
    } else if (getSalesActiveFieldsState.data) {
      setCashierFormState(
        initialFormState(
          getSalesActiveFieldsState.data,
          getSalesFormDataState.data?.cashier_data
        )
      );
      setTcFormState(
        initialFormState(
          getSalesActiveFieldsState.data,
          getSalesFormDataState.data?.cashier_data
        )
      );
      setManagerFormState(
        initialFormState(
          getSalesActiveFieldsState.data,
          getSalesFormDataState.data?.tc_data
        )
      );
    }
  }, [getSalesActiveFieldsState.data, getSalesFormDataState.data]);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const checkParam: CheckParam = {
      formState: getFormState(
        userType,
        cashierFormState,
        tcFormState,
        managerFormState
      ),
      grade: grade.current,
      id: formId,
      type: userType,
    };

    if (
      cashierFormState !== undefined ||
      tcFormState !== undefined ||
      managerFormState !== undefined
    ) {
      dispatch(salesSubmitVerdict(checkParam));
      navigate(
        userType === "cashier" ? "/admin/sales/form-list" : "/admin/sales/task"
      );
    }
  };

  const staticData = (
    title: string,
    value: string | Date | null | undefined
  ) => {
    return (
      <div className="flex space-x-2">
        <div className="text-base font-bold">{title}</div>
        <div className="text-base text-gray-600">{value as string}</div>
      </div>
    );
  };

  const dataSaveAndValidation = () => {
    switch (userType) {
      case "cashier":
        return (
          <TabPanel index={tabValue} value={tabValue}>
            <FormFieldData
              formLabel="cashier"
              disabled={userType !== "cashier"}
              disableFeedback
              salesActiveFieldState={getSalesActiveFieldsState.data}
              activeStep={tabValue}
              formState={cashierFormState || {}}
              setFormState={(data: SubmitFormParam["formState"] | undefined) =>
                setCashierFormState(data || {})
              }
            />
          </TabPanel>
        );
      case "tc":
        return (
          <SalesTaskFormDataComparison
            type={"tc"}
            formLabel="tc"
            activeStep={tabValue}
            formState={tcFormState || {}}
            salesActiveFieldState={getSalesActiveFieldsState.data}
            salesFormStateData={getSalesFormDataState.data}
            setFormState={(data: SubmitFormParam["formState"] | undefined) =>
              setTcFormState(data || {})
            }
          />
        );
      case "manager":
        return (
          <SalesTaskFormDataComparison
            type={"manager"}
            formLabel="manager"
            activeStep={tabValue}
            salesActiveFieldState={getSalesActiveFieldsState.data}
            salesFormStateData={getSalesFormDataState.data}
            formState={managerFormState || {}}
            setFormState={(data: SubmitFormParam["formState"] | undefined) =>
              setManagerFormState(data || {})
            }
          />
        );

      case "view":
        return (
          <SalesTaskFormDataComparison
            type={"view"}
            formLabel="view"
            activeStep={tabValue}
            salesActiveFieldState={getSalesActiveFieldsState.data}
            salesFormStateData={getSalesFormDataState.data}
            formState={managerFormState || {}}
            setFormState={(data: SubmitFormParam["formState"] | undefined) =>
              setManagerFormState(data || {})
            }
          />
        );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={`${
          userType === "cashier" && "px-20"
        } "w-full hidden lg:flex lg:justify-center space-x-3 mt-5"`}
      >
        <div className="sticky left-0 top-8 border border-gray-200 bg-white shadow-sm rounded-md p-1 h-fit py-2">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={(event, value) => setTabValue(value)}
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {getSalesActiveFieldsState.data?.field_data.map((field_data) => (
              <Tab label={<span>{field_data.section}</span>} />
            ))}
          </Tabs>
        </div>
        <div
          className={`${
            userType === "cashier" && "flex-1"
          } "border border-gray-200 bg-white shadow-sm rounded-md w-full p-5 overflow-x-auto"`}
        >
          <span className="text-3xl font-['Bebas_Neue']">
            {getSalesActiveFieldsState.data?.field_data[tabValue].section}
          </span>
          {getSalesActiveFieldsState.data?.field_data[tabValue].section ===
            "General Information" &&
            userType !== "cashier" && (
              <>
                <div className="flex justify-center p-5">
                  <div className="flex-1">
                    {staticData(
                      "Shift: ",
                      getSalesFormDataState.data?.cashier_data[0].fieldData[
                        "shift"
                      ]
                    )}
                    {staticData(
                      "Cashier Name: ",
                      getSalesFormDataState.data?.cashier_data[0].fieldData[
                        "cashier_name"
                      ]
                    )}
                    {staticData(
                      "Cashier Email Address: ",
                      getSalesFormDataState.data?.cashier_data[0].fieldData[
                        "email"
                      ]
                    )}
                  </div>
                  <div className="flex-1">
                    {staticData(
                      "Date: ",
                      formatDate(
                        getSalesFormDataState.data?.cashier_data[0].fieldData[
                          "entry_date"
                        ] as string
                      )
                    )}
                    {staticData(
                      "Store Name: ",
                      getSalesFormDataState.data?.cashier_data[0].fieldData[
                        "store"
                      ]
                    )}

                    {getSalesFormDataState.data?.cashier_data[0].fieldData[
                      "transaction_date"
                    ] !== null &&
                      staticData(
                        "Date of transaction: ",
                        formatDate(
                          getSalesFormDataState.data?.cashier_data[0].fieldData[
                            "transaction_date"
                          ] as string
                        )
                      )}
                  </div>
                </div>
                <Divider />
              </>
            )}

          <div className="flex w-full p-5">{dataSaveAndValidation()}</div>

          {userType !== "view" && (
            <div className="flex flex-row justify-center p-5 space-x-5">
              {userType === "cashier"
                ? tabValue === 5 && (
                    <Button
                      type="submit"
                      onClick={() => (grade.current = "0")}
                      variant="contained"
                    >
                      submit
                    </Button>
                  )
                : tabValue === 5 && (
                    <>
                      <Button
                        type="submit"
                        sx={STOCK_ORDERING_BUTTON_STYLE}
                        onClick={() => (grade.current = "2")}
                        variant="contained"
                      >
                        Disapprove
                      </Button>
                      <Button
                        type="submit"
                        sx={STOCK_ORDERING_BUTTON_STYLE}
                        onClick={() => (grade.current = "1")}
                        variant="contained"
                      >
                        Approve
                      </Button>
                    </>
                  )}
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 w-full lg:hidden border border-gray-200 bg-white shadow-sm rounded-md p-3 mt-5">
        <span className="text-xl font-semibold">
          {getSalesActiveFieldsState.data?.field_data[tabValue].section}
        </span>

        <div className="flex space-x-3 overflow-y-auto">
          <div
            className={` ${
              userType === "cashier" ? "w-full" : "shrink-0 w-2/3 md:w-1/2"
            }`}
          >
            <FormFieldData
              formLabel="cashier"
              disabled={userType !== "cashier"}
              salesActiveFieldState={getSalesActiveFieldsState.data}
              activeStep={tabValue}
              disableFeedback
              formState={cashierFormState || {}}
              setFormState={(data: SubmitFormParam["formState"] | undefined) =>
                setCashierFormState(data || {})
              }
            />
          </div>
          <div
            className={`shrink-0 w-2/3 md:w-1/2 ${
              hidePanel(userType).tc ? "hidden" : ""
            }`}
          >
            <FormFieldData
              formLabel="tc"
              disabled={userType !== "tc"}
              disableFieldLabel
              disabledSubSection
              disableFeedback
              disableFieldName={fieldToHide}
              salesActiveFieldState={getSalesActiveFieldsState.data}
              activeStep={tabValue}
              formState={tcFormState || {}}
              setFormState={(data: SubmitFormParam["formState"] | undefined) =>
                setTcFormState(data || {})
              }
            />
          </div>
          <div
            className={`shrink-0 w-2/3 md:w-1/2 ${
              hidePanel(userType).manager ? "hidden" : ""
            }`}
          >
            <FormFieldData
              formLabel="manager"
              disableFieldLabel
              disabledSubSection
              disableFeedback
              disableFieldName={fieldToHide}
              salesActiveFieldState={getSalesActiveFieldsState.data}
              activeStep={tabValue}
              formState={managerFormState || {}}
              setFormState={(data: SubmitFormParam["formState"] | undefined) =>
                setManagerFormState(data || {})
              }
            />
          </div>
        </div>
        <div className="flex flex-row justify-center p-5 space-x-5">
          {userType === "cashier" ? (
            <Button
              type="submit"
              onClick={() => (grade.current = "0")}
              variant="contained"
            >
              submit
            </Button>
          ) : (
            <>
              <Button
                type="submit"
                onClick={() => (grade.current = "2")}
                variant="contained"
              >
                Disapprove
              </Button>
              <Button
                type="submit"
                onClick={() => (grade.current = "1")}
                variant="contained"
              >
                Approve
              </Button>
            </>
          )}
        </div>

        <MobileStepper
          variant="text"
          steps={6}
          position="static"
          activeStep={tabValue}
          nextButton={
            <Button
              size="small"
              onClick={() => setTabValue((prevValue) => prevValue + 1)}
              disabled={tabValue === 6 - 1}
            >
              Next
              {theme.direction === "rtl" ? <GrFormPrevious /> : <GrFormNext />}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => setTabValue((prevValue) => prevValue - 1)}
              disabled={tabValue === 0}
            >
              {theme.direction === "rtl" ? <GrFormNext /> : <GrFormPrevious />}
              Back
            </Button>
          }
        />
      </div>
    </form>
  );
}
