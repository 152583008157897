import { IoMdClose } from "react-icons/io";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { UploadFile } from "features/shared/presentation/components/upload-file";
import {
  openPopupScroll,
  selectpopupScroll,
  togglePopupScroll,
} from "../slices/popup-scroll.slice";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { isValidFile } from "../components/stock-ordering-utils";

interface UploadDeliveryRecieptModalProps {
  open: boolean;
  onClose: () => void;
  setUploadedReciept: ((file: File | string) => void) | undefined;
  isButtonAvailable: boolean;
  title?: string;
  isImage?: boolean;
}

export function UploadDeliveryRecieptModal(
  props: UploadDeliveryRecieptModalProps
) {
  const dispatch = useAppDispatch();
  const [uploadedReceipt, setUploadedReciept] = useState<File | string>("");
  const popupModalState = useAppSelector(selectpopupScroll);

  // useEffect(() => {
  //   if (popupModalState.status === false) {
  //     dispatch(openPopupScroll());
  //   }
  // }, [props.open]);

  // enable if it will be used again
  useEffect(() => {
    dispatch(togglePopupScroll());
  }, [props.open]);

  const handleSubmit = () => {
    props.setUploadedReciept?.(uploadedReceipt);
    document.body.classList.remove("overflow-hidden");
    props.onClose();
  };

  const [errorMessage, setErrorMessage] = useState("");

  if (props.open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
    return null;
  }

  return (
    <>
      <div
        id="place-order-modal"
        className="fixed inset-0 -top-5 z-30 flex items-start justify-center overflow-auto bg-black bg-opacity-30 backdrop-blur-sm"
      >
        <div className="w-[97%] lg:w-[25%] my-5 rounded-[10px]">
          <div className="bg-secondary rounded-t-[10px] flex items-center justify-between p-4">
            <span className="text-2xl text-white">
              {props.title === "" || props.title === undefined
                ? "Upload Sales Invoice"
                : "Upload " + props.title}
            </span>
            <button
              className="text-2xl text-white"
              onClick={() => {
                document.body.classList.remove("overflow-hidden");
                props.onClose();
              }}
            >
              <IoMdClose />
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="p-4 bg-white border-b-2 border-l-2 border-r-2 border-secondary space-y-5">
              <UploadFile
                image={uploadedReceipt}
                onChange={(file) => {
                  if (file.type !== "image/png") {
                    if (props.isImage) {
                      if (file.type === "image/jpeg") {
                        setUploadedReciept(file);
                        setErrorMessage("");
                      } else {
                        setErrorMessage("Please upload sales invoice as image");
                      }
                    } else {
                      console.log(file.size);
                      if (file.type === "application/zip") {
                        if (file.size > 2000000) {
                          setErrorMessage(
                            "Please check the file the size!. File size must be below 2mb or 2000kb. Current size (" +
                              file.size +
                              "kb)"
                          );
                          setUploadedReciept("");
                        } else {
                          setUploadedReciept(file);
                          setErrorMessage("");
                        }
                      } else {
                        setErrorMessage("Invalid file!. Must be a .zip file!");
                      }
                    }
                  } else {
                    setErrorMessage("The system does not accept png image");
                  }
                }}
                description="DeliveryReciept"
              />
              {errorMessage === "" ? (
                <h4 className="mt-1 text-sm leading-5 text-secondary">
                  <strong>Note: </strong>
                  Maximum file size is 2MB.
                </h4>
              ) : (
                <h4 className="mt-1 text-sm leading-5 text-secondary">
                  <strong>Error: </strong>
                  {errorMessage}.
                </h4>
              )}

              {props.isButtonAvailable ? (
                <div>
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    sx={{
                      bgcolor: "#CC5801",
                      "&:hover": {
                        bgcolor: "#9C4100",
                      },
                    }}
                    variant="contained"
                  >
                    Upload
                  </Button>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
