import { useEffect } from "react";
import { MdDashboardCustomize, MdOutlinePayment } from "react-icons/md";
import { TbChecks, TbLogout } from "react-icons/tb";
import { SiPlatformdotsh } from "react-icons/si";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { selectTeiHubLinkSideBar } from "../../slices/tei-hub-link-sidebar.slice";
import {
  LogoutHubLinkState,
  logoutHubLink,
  resetLogoutHubLink,
  selectLogoutHubLink,
} from "../../slices/tei-hub-link-logout.slice";
import {
  selectTeiHubLinkSession,
  teiHubLinkSession,
} from "../../slices/tei-hub-link-session.slice";
import { CgShoppingBag } from "react-icons/cg";
import { MdOutlineCancel, MdOutlineCheckCircleOutline } from "react-icons/md";
import { CiViewList } from "react-icons/ci";

export function TeiHubLinkDrawerMenu() {
  const { clientType } = useParams();
  const salesSidebar = useAppSelector(selectTeiHubLinkSideBar);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);
  const logoutHubLinkState = useAppSelector(selectLogoutHubLink);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const menuitems = [
    {
      text: "Orders",
      path: `${clientType}`,
      icon: <CgShoppingBag size={20} />,
      enable: true,
    },
    {
      text: "Completed orders",
      path: `completed/${clientType}`,
      icon: <MdOutlineCheckCircleOutline size={20} />,
      enable: teiHubLinkSessionState.data?.hublink.is_Logistic === false,
    },
    {
      text: "Cancelled orders",
      path: `cancelled/${clientType}`,
      icon: <MdOutlineCancel size={20} />,
      enable: teiHubLinkSessionState.data?.hublink.is_Logistic === false,
    },
    {
      text: "SOA",
      path: `soa/${clientType}`,
      icon: <CiViewList size={20} />,
      enable: teiHubLinkSessionState.data?.hublink.is_Logistic === false,
    },
    {
      text: "Logistic Receive",
      path: `dispatched/${clientType}`,
      icon: <CiViewList size={20} />,
      enable: teiHubLinkSessionState.data?.hublink.is_Logistic === true,
    },
  ];

  useEffect(() => {
    if (logoutHubLinkState.status === LogoutHubLinkState.success) {
      dispatch(teiHubLinkSession());
      dispatch(resetLogoutHubLink());
      navigate("/hublink");
    }
  }, [logoutHubLinkState, dispatch, navigate]);

  return (
    <div className="relative flex flex-col pb-4 m-0 mt-3 text-sm text-white">
      <nav>
        <ul>
          <li className="flex flex-col">
            {menuitems.map((item, index) => {
              const { text, path, icon, enable } = item;
              const key = index;
              return (
                <>
                  {enable ? (
                    <NavLink
                      key={key}
                      to={path}
                      className={(navData) =>
                        navData.isActive
                          ? "flex bg-white text-secondary"
                          : "flex text-white"
                      }
                    >
                      <span className="flex items-center px-4 ">
                        <span className="flex px-[0.5rem] py-[0.85rem] space-x-4 items-center">
                          {icon}
                          <span
                            className={`whitespace-pre duration-300 ${
                              !salesSidebar.status &&
                              "opacity-0 overflow-hidden"
                            }`}
                          >
                            {text}
                          </span>
                        </span>
                      </span>
                    </NavLink>
                  ) : null}
                </>
              );
            })}
          </li>

          <li>
            <button
              onClick={() => {
                dispatch(logoutHubLink());
              }}
              className="flex w-full"
            >
              <span className="flex items-center px-4 ">
                <span className="flex px-[0.5rem] py-[0.85rem] space-x-4 items-center">
                  <TbLogout size={20} />

                  <span
                    className={`whitespace-pre duration-300 ${
                      !salesSidebar.status && "opacity-0 overflow-hidden"
                    }`}
                  >
                    Logout
                  </span>
                </span>
              </span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}
