import React from "react";
import { AuditFormContent } from "../components";

export function AuditForm() {
  return (
    <div>
      <AuditFormContent />
    </div>
  );
}
