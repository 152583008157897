import { Divider, Button } from "@mui/material";
import { createQueryParams } from "features/config/helpers";
import {
  useQuery,
  useAppDispatch,
  useAppSelector,
} from "features/config/hooks";
import { TeiHubLinkDataFilterModel } from "features/hublink/core/domain/tei-hub-link.model";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import { DataTable, DataList } from "features/shared/presentation/components";
import {
  Column,
  DataTableRow,
  DataTableCell,
} from "features/shared/presentation/components/data-table";
import { useState, useEffect } from "react";
import { CiFilter } from "react-icons/ci";
import { useParams, useNavigate } from "react-router-dom";
import {
  selectTeiHubLinkCancelledOrder,
  TeiHubLinkCancelledOrder,
  teiHubLinkCancelledOrder,
  resetTeiHubLinkCancelledOrder,
} from "../slices/tei-hub-link-cancelled-orders.slice";
import {
  selectTeiHubLinkStores,
  teiHubLinkStores,
} from "../slices/tei-hub-link-stores.slice";
import { TeiHubLinkDataFilter } from "./tei-hub-link-data-filter";
import {
  dateSetup,
  mobileCustomStatus,
  dateTypeMapper,
  StoreIdMapper,
} from "./tei-hub-link-utils";

interface listDataParam {
  id: string;
  trackingNumber: string;
  status: string;
  storeName: string;
  companyName: string;
  placementDate: string;
  expectedDeliveryDate: string;
  actualDeliveryDate: string;
}

interface tableDataParam {
  id: string;
  number: number;
  trackingNumber: string;
  status: string;
  storeName: string;
  companyName: string;
  placementDate: string;
  expectedDeliveryDate: string;
  actualDeliveryDate: string;
  werehouse: string;
}

export function TeiHubLinkCancelledOrders() {
  const columns: Array<Column> = [
    { id: "number", label: "#" },

    { id: "tracking_number", label: "Tracking Number" },
    { id: "company_name", label: "Store Company" },
    { id: "store_name", label: "Store" },
    {
      id: "created_at",
      label: "Placement date",
    },
    { id: "expected_delivery", label: "Expected Delivery Date" },
    { id: "actual_delivery", label: "Actual Delivery Date" },
    { id: "warehouse_name", label: "werehouse" },
    { id: "status", label: "status" },
  ];

  const { clientType } = useParams();

  const query = useQuery();
  const pageNo = query.get("page_no");
  const perPage = query.get("per_page");
  const orderBy = query.get("order_by");
  const order = query.get("order");
  const search = query.get("search");
  const store = query.get("store");
  const dateType = query.get("dateType");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const cancelledOrderState = useAppSelector(selectTeiHubLinkCancelledOrder);
  const storeState = useAppSelector(selectTeiHubLinkStores);

  const [openFilter, setOpenFilter] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (
      !cancelledOrderState.data &&
      cancelledOrderState.status !== TeiHubLinkCancelledOrder.success &&
      clientType !== null
    ) {
      const queryParams = createQueryParams({
        clientType: clientType,
      });

      dispatch(teiHubLinkCancelledOrder(queryParams));
    } else if (
      cancelledOrderState.status === TeiHubLinkCancelledOrder.inProgress
    ) {
      const queryParams = createQueryParams({
        page_no: pageNo,
        per_page: perPage,
        order_by: orderBy,
        order: order,
        clientType: clientType,
        search: search,
        store: store,
        dateType: dateType,
        startDate: startDate,
        endDate: endDate,
      });

      dispatch(teiHubLinkCancelledOrder(queryParams));
    }
  }, [
    dispatch,
    cancelledOrderState.data,
    clientType,
    search,
    order,
    orderBy,
    perPage,
    pageNo,
    store,
    dateType,
    startDate,
    endDate,
  ]);

  const dataFilter = (dataFilter: {
    type: string;
    value?: string | number | boolean;
  }) => {
    const params = {
      page_no: dataFilter.type === "onPageChange" ? dataFilter.value : pageNo,
      per_page:
        dataFilter.type === "onRowsPerPageChange" ? dataFilter.value : perPage,
      order_by:
        dataFilter.type === "onRequestSort" ? dataFilter.value : orderBy,
      order:
        dataFilter.type === "onRequestSort"
          ? orderBy === dataFilter.value && order === "asc"
            ? "desc"
            : "asc"
          : order,
      search:
        dataFilter.type === "onSearch"
          ? dataFilter.value === ""
            ? null
            : dataFilter.value
          : search,
      store: store,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
    };
    const queryParams = createQueryParams(params);

    dispatch(resetTeiHubLinkCancelledOrder());

    navigate({
      pathname: "",
      search: queryParams,
    });
  };

  const tableData = (data: tableDataParam) => {
    return (
      <DataTableRow
        onClick={() => {
          dispatch(resetTeiHubLinkCancelledOrder());

          navigate(`/hublink/${clientType}/order/${data.trackingNumber}`);
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&:hover": { backgroundColor: "#f5f5f5" },
        }}
      >
        <DataTableCell>{data.number}</DataTableCell>
        <DataTableCell>{data.trackingNumber}</DataTableCell>
        <DataTableCell>{data.companyName}</DataTableCell>
        <DataTableCell>{data.storeName}</DataTableCell>
        <DataTableCell>{dateSetup(data.placementDate, true)}</DataTableCell>
        <DataTableCell>
          {dateSetup(data.expectedDeliveryDate, true)}
        </DataTableCell>
        <DataTableCell>
          {data.actualDeliveryDate
            ? dateSetup(data.actualDeliveryDate, true)
            : "TBA"}
        </DataTableCell>
        <DataTableCell>{data.werehouse}</DataTableCell>
        <DataTableCell>{data.status}</DataTableCell>
      </DataTableRow>
    );
  };

  const listData = (data: listDataParam) => {
    return (
      <div
        onClick={() =>
          navigate(`/hublink/${clientType}/order/${data.trackingNumber}`)
        }
        className="flex flex-col border border-gray-200 rounded-md shadow-sm p-2 bg-white space-y-1"
      >
        <div className="flex justify-between text-normal">
          <span className="normal-case font-bold">{`#${data.id} ${data.trackingNumber}`}</span>
          {mobileCustomStatus(data.status)}
        </div>

        <div className="flex flex-col">
          <span className="normal-case text-base font-semibold">
            {data.storeName}
          </span>
          <span className="text-sm">{data.companyName}</span>
        </div>

        <Divider />
        <div className="flex flex-wrap gap-2">
          <div className="flex flex-col text-xs capitalize">
            <span className="font-semibold">Placement date:</span>
            <span>{dateSetup(data.placementDate, true)}</span>
          </div>
          <div className="flex flex-col text-xs capitalize">
            <span className="font-semibold">Expected Delivery Date:</span>
            <span>{dateSetup(data.expectedDeliveryDate, true)}</span>
          </div>
          {data.actualDeliveryDate && (
            <div className="flex flex-col text-xs capitalize">
              <span className="font-semibold">Actual Delivery Date:</span>
              <span>
                {data.actualDeliveryDate
                  ? dateSetup(data.actualDeliveryDate, true)
                  : "TBA"}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const customButton = () => {
    return (
      <>
        <Button
          startIcon={<CiFilter />}
          variant="contained"
          sx={STOCK_ORDERING_BUTTON_STYLE}
          onClick={(event) => {
            const storeParam = createQueryParams({
              clientType: clientType,
            });
            dispatch(teiHubLinkStores(storeParam));
            setOpenFilter(event.currentTarget);
          }}
        >
          Advance Filter
        </Button>
      </>
    );
  };

  const customFilterPill = () => {
    return (
      <div className="flex space-x-2">
        {dateType && startDate && endDate && (
          <div className="border border-gray-300 rounded-full w-fit px-3 py-1 shadow-sm text-xs space-x-2">
            <span
              className="cursor-pointer"
              onClick={() => {
                const queryParams = createQueryParams({
                  page_no: pageNo,
                  per_page: perPage,
                  order_by: orderBy,
                  order: order,
                  search: search,
                  store: store,
                  dateType: null,
                  startDate: null,
                  endDate: null,
                });
                dispatch(resetTeiHubLinkCancelledOrder());
                navigate({
                  pathname: "",
                  search: queryParams,
                });
              }}
            >
              X
            </span>
            <span>{`${dateTypeMapper(dateType)}: ${dateSetup(
              startDate ?? "",
              false
            )} to ${dateSetup(endDate ?? "", false)}`}</span>
          </div>
        )}
        <div className="flex flex-wrap gap-2">
          {store &&
            storeState.data &&
            JSON.parse(store)?.map(
              (storeDetail: string | number, index: number) => (
                <div
                  key={index}
                  className="border border-gray-300 rounded-full w-fit px-3 py-1 shadow-sm text-xs space-x-2"
                >
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      const modifiedStores = [
                        ...JSON.parse(store).slice(0, index),
                        ...JSON.parse(store).slice(index + 1),
                      ];
                      const queryParams = createQueryParams({
                        page_no: pageNo,
                        per_page: perPage,
                        order_by: orderBy,
                        order: order,
                        search: search,
                        store:
                          modifiedStores.length !== 0
                            ? JSON.stringify(modifiedStores)
                            : null,
                        dateType: dateType,
                        startDate: startDate,
                        endDate: endDate,
                      });
                      dispatch(resetTeiHubLinkCancelledOrder());
                      navigate({
                        pathname: "",
                        search: queryParams,
                      });
                    }}
                  >
                    X
                  </span>
                  <span>{StoreIdMapper(storeDetail, storeState.data)}</span>
                </div>
              )
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="border border-gray-200 rounded-md w-full bg-white p-2">
        <div className="hidden lg:block">
          <DataTable
            order={order === "asc" ? "asc" : "desc"}
            orderBy={orderBy ?? "last_updated"}
            search={search ?? ""}
            emptyMessage={`No cancelled orders yet. 😁`}
            onSearch={(val) => dataFilter({ type: "onSearch", value: val })}
            onRequestSort={(column_selected) =>
              column_selected !== "number" &&
              dataFilter({ type: "onRequestSort", value: column_selected })
            }
            columns={columns}
            onRowsPerPageChange={(event) =>
              perPage !== event.target.value &&
              dataFilter({
                type: "onRowsPerPageChange",
                value: event.target.value,
              })
            }
            onPageChange={(event, newPage) => {
              dataFilter({
                type: "onPageChange",
                value:
                  newPage !== (pageNo ? parseInt(pageNo) : null) && newPage,
              });
            }}
            totalRows={cancelledOrderState.data?.pagination.totalRows ?? 25}
            perPage={Number(cancelledOrderState.data?.pagination.perPage)}
            page={pageNo ? parseInt(pageNo) : 1}
            customButton={<>{customButton()}</>}
            customPill={<>{customFilterPill()}</>}
          >
            {cancelledOrderState.data?.orders?.map((order, index) =>
              tableData({
                number: index + 1,
                trackingNumber: order.trackingNumber,
                status: order.processName,
                storeName: order.storeName,
                companyName: order.companyName,
                placementDate: order.createDate,
                expectedDeliveryDate: order.expectedDeliveryDate,
                actualDeliveryDate: order.actualDeliveryDate ?? "",
                werehouse: order.warehouseName,
                id: order.id,
              })
            )}
          </DataTable>
        </div>

        <div className="block lg:hidden">
          <DataList
            search={search ?? ""}
            emptyMessage={`No cancelled orders yet. 😁`}
            onSearch={(val) => dataFilter({ type: "onSearch", value: val })}
            onRowsPerPageChange={(event) =>
              perPage !== event.target.value &&
              dataFilter({
                type: "onRowsPerPageChange",
                value: event.target.value,
              })
            }
            onPageChange={(event, newPage) => {
              dataFilter({
                type: "onPageChange",
                value:
                  newPage !== (pageNo ? parseInt(pageNo) : null) && newPage,
              });
            }}
            totalRows={Number(cancelledOrderState.data?.pagination.totalRows)}
            perPage={Number(cancelledOrderState.data?.pagination.perPage)}
            page={pageNo ? parseInt(pageNo) : 1}
          >
            <div className="space-y-2 mt-2">
              {cancelledOrderState.data?.orders?.map((order, index) =>
                listData({
                  id: order.id,
                  trackingNumber: order.trackingNumber,
                  status: order.statusName,
                  storeName: order.storeName,
                  companyName: order.companyName,
                  placementDate: order.createDate,
                  expectedDeliveryDate: order.expectedDeliveryDate,
                  actualDeliveryDate: order.actualDeliveryDate ?? "",
                })
              )}
            </div>
          </DataList>
        </div>
      </div>

      <TeiHubLinkDataFilter
        anchor={openFilter}
        onClose={() => setOpenFilter(null)}
        filter={(data: TeiHubLinkDataFilterModel | string) => {
          if (typeof data !== "string" && clientType) {
            const storeIds = data.store?.flatMap((store) =>
              store.flatMap((stores) => stores.storeCode)
            );

            console.log(JSON.stringify(storeIds));

            const queryParams = createQueryParams({
              page_no: pageNo,
              per_page: perPage,
              order_by: orderBy,
              order: order,
              search: search,
              store: storeIds ? JSON.stringify(storeIds) : null,
              dateType: data.type ?? null,
              startDate: data.start ?? null,
              endDate: data.end ?? null,
            });

            dispatch(resetTeiHubLinkCancelledOrder());

            navigate({
              pathname: "",
              search: queryParams,
            });

            // dispatch(teiHubLinkOrders(queryParams));
          } else {
            navigate({
              pathname: "",
            });
          }
        }}
      />
    </>
  );
}
