import { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import {
  MaterialInput,
  MaterialInputAutoComplete,
  MaterialInputPassword,
  MaterialPhoneInput,
} from "features/shared/presentation/components";
import { useNavigate } from "react-router-dom";
import {
  selectTeiHubLinkCreateUser,
  TeiHubLinkCreateUserState,
  resetTeiHubLinkCreateUser,
  teiHubLinkCreateUser,
} from "../slices/tei-hub-link-create-user-param.slice";
import { Checkbox, FormGroup } from "@mui/material";
import {
  TeiHubLinkAllGroups,
  resetTeiHubLinkAllGroups,
  selectTeiHubLinkAllGroups,
  teiHubLinkAllGroups,
} from "../slices/tei-hub-link-all-group.slice";
import {
  TeiHubLinkAllGroupsModel,
  TeiHubLinkAllStoresModel,
} from "features/hublink/core/domain/tei-hub-link-group.model";
import { TeiHubLinkStoreModel } from "features/hublink/core/domain/tei-hub-link.model";
import { createQueryParams } from "features/config/helpers";
import {
  TeiHubLinkAllStores,
  resetTeiHubLinkAllStores,
  selectTeiHubLinkAllStores,
  teiHubLinkAllStores,
} from "../slices/tei-hub-link-all-stores.slice";

interface formStateData {
  firstName: "";
  lastName: "";
  company: "";
  email: "";
  phoneNumber: "";
  password: "";
  confirmPassword: "";
  hubLink_group: Array<number> | null;
  hubLink_store: Array<number> | null;
}

export function TeiHubLinkCreateUserPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formState, setFormState] = useState<formStateData>({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    hubLink_group: null,
    hubLink_store: null,
  });

  const createAdminUserState = useAppSelector(selectTeiHubLinkCreateUser);
  const groupDataState = useAppSelector(selectTeiHubLinkAllGroups);
  const storeDataState = useAppSelector(selectTeiHubLinkAllStores);

  useEffect(() => {
    if (
      !groupDataState.data &&
      groupDataState.status !== TeiHubLinkAllGroups.success
    ) {
      dispatch(teiHubLinkAllGroups());
    }
  }, [dispatch, groupDataState.data]);

  useEffect(() => {
    if (
      storeDataState.status !== TeiHubLinkAllStores.success &&
      formState.hubLink_group?.some((id) => id === 1 || id === 3)
    ) {
      const storeParam = createQueryParams({
        type: formState.hubLink_group[0],
      });
      dispatch(teiHubLinkAllStores(storeParam));
    }
  }, [storeDataState.data, formState.hubLink_group]);

  useEffect(() => {
    if (storeDataState.status === TeiHubLinkAllStores.success) {
      dispatch(resetTeiHubLinkAllStores());
    }

    if (groupDataState.status === TeiHubLinkAllGroups.success) {
      dispatch(resetTeiHubLinkAllGroups());
    }
  }, [dispatch, storeDataState.status, groupDataState.status]);

  useEffect(() => {
    if (createAdminUserState.status === TeiHubLinkCreateUserState.success) {
      dispatch(resetTeiHubLinkCreateUser());
      // navigate("/hublink");
    }
  }, [createAdminUserState, navigate, dispatch]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    dispatch(teiHubLinkCreateUser(formState));
    e.preventDefault();
  };

  const handleInputChange = (evt: any) => {
    const value = evt.target.value;
    setFormState({
      ...formState,
      [evt.target.name]: value,
    });
  };

  const userGroups = (
    title: string,
    groupData: Array<TeiHubLinkAllGroupsModel> | undefined
  ) => {
    return (
      <div className="flex flex-col mt-5">
        <FormGroup>
          <span className="text-xl">{title}</span>

          <div className="flex flex-wrap">
            {groupData?.map((group, index) => (
              <div
                key={index}
                className="flex items-center justify-start space-x-1 text-sm text-secondary lg:text-base"
              >
                <Checkbox
                  color="primary"
                  value={group.id}
                  onChange={(event) => {
                    setFormState((prevFormState) => {
                      let groups = prevFormState["hubLink_group"] ?? [];

                      if (groups.includes(group.id)) {
                        groups = groups.filter((e) => e !== group.id);
                      } else {
                        groups = [...groups, group.id];
                      }

                      return {
                        ...prevFormState,
                        ["hubLink_group"]: groups,
                      };
                    });
                  }}
                />
                <span>{group.name}</span>
              </div>
            ))}
          </div>
        </FormGroup>
      </div>
    );
  };

  const userStoreEnrollment = (options: TeiHubLinkAllStoresModel[]) => {
    return (
      <div className="flex flex-col space-y-2">
        <span className="text-xl">Enroll a store</span>

        <MaterialInputAutoComplete
          colorTheme={"black"}
          multiple={true}
          options={options ?? []}
          size="small"
          getOptionLabel={(option) => option.store_name ?? ""}
          isOptionEqualToValue={(option, value) =>
            option.store_name === value.store_name
          }
          onChange={(e, selectedOption) => {
            const selectedIds = selectedOption.map(
              (option: any) => option.store_code
            );

            setFormState({
              ...formState,
              ["hubLink_store"]: selectedIds,
            });
          }}
          filterSelectedOptions
        />
      </div>
    );
  };

  return (
    <>
      <div className="px-96 py-10 bg-paper">
        <div className="border border-gray-200 shadow-md bg-white rounded-md p-5">
          <span className="text-secondary text-3xl font-['Bebas_Neue'] flex-1">
            Create User
          </span>

          <div className="pb-10 space-y-6">
            <span>Please enter the user's information below.</span>

            <form onSubmit={onSubmit} className="flex flex-col space-y-4">
              <MaterialInput
                colorTheme="black"
                required
                label="First Name"
                name="firstName"
                value={formState.firstName}
                onChange={handleInputChange}
              />
              <MaterialInput
                colorTheme="black"
                required
                label="Last Name"
                name="lastName"
                value={formState.lastName}
                onChange={handleInputChange}
              />
              <MaterialInput
                colorTheme="black"
                required
                label="Company Name"
                name="company"
                value={formState.company}
                onChange={handleInputChange}
              />
              <MaterialInput
                colorTheme="black"
                required
                label="E-mail"
                name="email"
                value={formState.email}
                onChange={handleInputChange}
              />
              <MaterialPhoneInput
                colorTheme="black"
                onChange={handleInputChange}
                value={formState.phoneNumber}
                name="phoneNumber"
              />
              <MaterialInputPassword
                colorTheme="black"
                required
                onChange={handleInputChange}
                value={formState.password}
                name="password"
                label="Password"
              />
              <MaterialInputPassword
                colorTheme="black"
                required
                onChange={handleInputChange}
                value={formState.confirmPassword}
                name="confirmPassword"
                label="Confirm Password"
              />

              {userGroups("Tei Hublink Groups", groupDataState.data)}

              {formState.hubLink_group?.some((id) => id === 1 || id === 3) &&
                userStoreEnrollment(storeDataState.data ?? [])}

              <button
                type="submit"
                className="px-4 py-2 text-white rounded-lg bg-button w-fit"
              >
                Create User
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
