import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  ToggleButton,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import {
  DataTableRow,
  DataTableCell,
} from "features/shared/presentation/components/data-table";
import React, { useCallback, useEffect, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { selectTeiHubLinkSession } from "../slices/tei-hub-link-session.slice";
import { StockOrderHandleQuantity } from "features/stock-ordering/presentation/components";
import { selectTeiHubLinkOrder } from "../slices/tei-hub-link-order.slice";
import {
  RemarksProductModel,
  TeiHubLinkOrderModel,
} from "features/hublink/core/domain/tei-hub-link.model";
import { calculateSubTotalPerRow, dateSetup } from "./tei-hub-link-utils";
import { FaExclamationTriangle } from "react-icons/fa";
import { TeiHubLinkReturnRefundParam } from "features/hublink/core/tei-hub-link.param";
import {
  selectTeiHubLinkReturnRefundData,
  teiHubLinkReturnRefundData,
} from "../slices/tei-hub-link-return-refund-data.slice";

export function TeiHubLinkOrderInformationProductContent() {
  const dispatch = useAppDispatch();

  const orderState = useAppSelector(selectTeiHubLinkOrder);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);
  const completeOrderState = useAppSelector(selectTeiHubLinkReturnRefundData);

  const [row, setRows] = useState<TeiHubLinkOrderModel["productInformation"]>(
    orderState.data?.productInformation ?? []
  );
  const [openRemarks, setOpenRemarks] = useState(false);
  const [productReturnRefund, setProductReturnRefund] = useState<
    TeiHubLinkReturnRefundParam["productReturnRefund"]
  >(
    new Array(orderState.data?.productInformation.length).fill({
      damaged: false,
      missing: false,
      reason: "",
      productSku: "",
    })
  );

  useEffect(() => {
    if (
      productReturnRefund?.some(
        (product) => product?.missing === true || product?.damaged
      )
    ) {
      dispatch(
        teiHubLinkReturnRefundData({
          data: {
            triggerAction: true,
            productData: row,
            productReturnRefund: productReturnRefund,
          },
        })
      );
    } else {
      dispatch(
        teiHubLinkReturnRefundData({
          data: { triggerAction: false, productData: row },
        })
      );
    }
  }, [productReturnRefund]);

  const quantityPicker = (index: number, orderQuantity: string) => {
    if (
      orderState.data?.orderInformation.processId === 4 &&
      teiHubLinkSessionState.data?.hublink.is_Shell
    ) {
      return (
        <>
          <DataTableCell align="center">{orderQuantity}</DataTableCell>
          <DataTableCell align="center">
            <StockOrderHandleQuantity
              rows={row}
              setRows={(rowData) => {
                setRows(rowData);

                handleProductQuantityChange(
                  index,
                  rowData[index]?.product_quantity
                );
              }}
              rowsIndex={index}
              currentValue={row[index]?.product_quantity}
              propertyKey={"product_quantity"}
            />
          </DataTableCell>
        </>
      );
    } else return <DataTableCell align="center">{orderQuantity}</DataTableCell>;
  };

  const handleProductQuantityChange = useCallback(
    (index: number, quantity: number) => {
      const productQuantity =
        orderState.data?.productInformation[index].product_quantity ?? "";
      const hasChanged = Number(quantity) !== Number(productQuantity);

      setProductReturnRefund((prev) => {
        const updatedPreviousQuantities = [...(prev ?? [])];
        updatedPreviousQuantities[index] = {
          missing: hasChanged,
          damaged: productReturnRefund?.[index]?.damaged,
          reason: productReturnRefund?.[index].reason,
          productSku: productReturnRefund?.[index].productSku,
        };
        return updatedPreviousQuantities;
      });
    },

    [orderState]
  );

  const RemarksTableData = (
    openRemarks: boolean,
    remarksData: RemarksProductModel[] | undefined
  ) => {
    return (
      <DataTableRow>
        <DataTableCell
          colSpan={
            orderState.data?.orderInformation.processId === 4 &&
            teiHubLinkSessionState.data?.hublink.is_Shell
              ? 9
              : 8
          }
        >
          <Collapse in={openRemarks} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, paddingX: 10 }}>
              <span className="text-lg">Remarks</span>
              <Table size="small" aria-label="remarks">
                <TableHead>
                  <DataTableRow>
                    <DataTableCell sx={{ color: "white" }}>Date</DataTableCell>
                    <DataTableCell sx={{ color: "white" }}>
                      Process Name
                    </DataTableCell>
                    <DataTableCell sx={{ color: "white" }}>Name</DataTableCell>
                    <DataTableCell sx={{ color: "white" }}>
                      Remarks
                    </DataTableCell>
                  </DataTableRow>
                </TableHead>
                <TableBody>
                  {remarksData?.map((remarks) => (
                    <DataTableRow>
                      <DataTableCell width={150}>
                        {dateSetup(remarks.date, false)}
                      </DataTableCell>
                      <DataTableCell width={150}>
                        {remarks.processName}
                      </DataTableCell>
                      <DataTableCell width={220}>{remarks.name}</DataTableCell>
                      <DataTableCell>{remarks.reason}</DataTableCell>
                    </DataTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </DataTableCell>
      </DataTableRow>
    );
  };

  const handleToggleChange = (index: number, productSku: string) => () => {
    setProductReturnRefund((prev) => {
      const updatedReturnRefund = [...(prev ?? [])];
      updatedReturnRefund[index] = {
        missing: productReturnRefund?.[index]?.missing,
        damaged: !productReturnRefund?.[index]?.damaged,
        reason: productReturnRefund?.[index].reason,
        productSku: productSku,
      };
      return updatedReturnRefund;
    });
  };

  const handleDamagedReasonChange = (
    value: string,
    index: number,
    productSku: string
  ) => {
    setProductReturnRefund((prev) => {
      const updatedReturnRefund = [...(prev ?? [])];
      updatedReturnRefund[index] = {
        missing: productReturnRefund?.[index]?.missing,
        damaged: productReturnRefund?.[index]?.damaged,
        reason: value,
        productSku: productSku,
      };
      return updatedReturnRefund;
    });
  };

  return (
    <>
      {orderState.data?.productInformation.map((product, index) => {
        return (
          <React.Fragment key={product.skuCode}>
            <TableRow
              sx={{
                "& > *": {
                  borderBottom:
                    product.remarks?.length !== 0 ||
                    productReturnRefund?.[index]?.damaged
                      ? "none !important"
                      : "",
                },
              }}
            >
              <DataTableCell align="left">{index + 1}</DataTableCell>
              <DataTableCell align="center">{product.imgLink}</DataTableCell>
              <DataTableCell align="left">{product.productName}</DataTableCell>
              <DataTableCell align="center">{product.skuCode}</DataTableCell>
              <DataTableCell align="center">{product.cost}</DataTableCell>
              {quantityPicker(index, product.product_quantity)}
              <DataTableCell align="center">
                {calculateSubTotalPerRow(
                  product.cost,
                  product.product_quantity
                )}
              </DataTableCell>
              {orderState.data?.orderInformation.processId === 4 &&
              teiHubLinkSessionState.data?.hublink.is_Shell ? (
                <DataTableCell>
                  <ToggleButton
                    value="check"
                    selected={productReturnRefund?.[index]?.damaged}
                    onChange={handleToggleChange(index, product.skuCode)}
                  >
                    <FaExclamationTriangle
                      className="text-yellow-500"
                      size={20}
                    />
                  </ToggleButton>
                </DataTableCell>
              ) : (
                <DataTableCell>
                  <IconButton
                    sx={{
                      display: product.remarks?.length !== 0 ? "block" : "none",
                    }}
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenRemarks(!openRemarks)}
                  >
                    {openRemarks ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </IconButton>
                </DataTableCell>
              )}
            </TableRow>
            {(productReturnRefund?.[index]?.damaged ||
              productReturnRefund?.[index]?.missing) && (
              <DataTableRow>
                <DataTableCell colSpan={5} />
                <DataTableCell colSpan={4}>
                  <textarea
                    required
                    className="w-full h-full text-base p-1 border border-black rounded-md"
                    maxLength={500}
                    value={productReturnRefund?.[index]?.reason}
                    onChange={(event) =>
                      handleDamagedReasonChange(
                        event.target.value,
                        index,
                        product.skuCode
                      )
                    }
                    placeholder="Enter remarks for return/refund"
                  />
                </DataTableCell>
              </DataTableRow>
            )}

            {product.remarks?.length !== 0 &&
              RemarksTableData(openRemarks, product.remarks)}
          </React.Fragment>
        );
      })}
    </>
  );
}
