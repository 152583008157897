import styled from "@emotion/styled";
import {
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import { TeiHubLinkOrderModel } from "features/hublink/core/domain/tei-hub-link.model";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { dateSetup } from "./tei-hub-link-utils";

interface TeiHubOrderStatusStepperProps {
  processId: number | undefined;
  content: TeiHubLinkOrderModel["orderStatusInformation"];
}

const steps = [
  { id: 1, processName: "New Order" },
  { id: 2, processName: "Accept Order" },
  { id: 3, processName: "Dispatched Order" },
  { id: 4, processName: "Received by courier" },
  { id: 5, processName: "Received Order" },
  { id: 6, processName: "Order Complete" },
];

export function TeiHubOrderStatusStepper(props: TeiHubOrderStatusStepperProps) {
  const { trackingNum, clientType } = useParams();

  const [shouldSlide, setShouldSlide] = useState(false);

  useEffect(() => {
    if (trackingNum && clientType) {
      const delay = setTimeout(() => {
        setShouldSlide(true);
      }, 300);

      return () => {
        clearTimeout(delay);
      };
    }
  }, []);

  const QontoConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#CC5801",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#CC5801",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#eaeaf0",
      borderLeftWidth: 3,
      borderBottomLeftRadius: 1,
      display: "none",
    },
  }));

  const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
    ({ ownerState }) => ({
      color: "#eaeaf0",
      display: "flex",
      paddingLeft: 9,
      alignItems: "center",
      ...(ownerState.active && {
        color: "#CC5801",
      }),

      "& .QontoStepIcon-completedIcon": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#CC5801",
      },

      "& .QontoStepIcon-circle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
      },
    })
  );

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className="QontoStepIcon-completedIcon " />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const isCancelled = props.processId === 6;
  const isReturnRefund = props.processId === 7;

  const isCancelledOrReturnRefund = props.content.map((label, index) => {
    const { processName } = label;
    return (
      <Step key={index}>
        <StepLabel StepIconComponent={QontoStepIcon}>{processName}</StepLabel>
        {props?.content?.[index]?.firstName &&
          props?.content?.[index]?.lastName &&
          props?.content?.[index]?.created_at && (
            <StepContent
              sx={{
                borderColor: "#CC5801",
                borderLeftWidth: 3,
                borderTopLeftRadius: 1,
              }}
              transitionDuration={1}
              TransitionComponent={() => (
                <div
                  className={`relative left-14 transition-all duration-700 ${
                    shouldSlide && "-translate-x-14"
                  }`}
                >
                  <div className="flex flex-col text-xs">
                    <span>
                      {dateSetup(props?.content?.[index]?.created_at, true)}
                    </span>
                    <span>{`by ${props?.content?.[index]?.firstName} ${props?.content?.[index]?.lastName}`}</span>
                  </div>
                </div>
              )}
            />
          )}
      </Step>
    );
  });

  const isNormalStep = steps.map((label, index) => {
    const { processName, id } = label;
    return (
      <Step key={id}>
        <StepLabel StepIconComponent={QontoStepIcon}>{processName}</StepLabel>
        {props?.content?.[index]?.firstName &&
          props?.content?.[index]?.lastName &&
          props?.content?.[index]?.created_at && (
            <StepContent
              sx={{
                borderColor: "#CC5801",
                borderLeftWidth: 3,
                borderTopLeftRadius: 1,
              }}
              transitionDuration={1}
              TransitionComponent={() => (
                <div
                  className={`relative left-14 transition-all duration-700 ${
                    shouldSlide && "-translate-x-14"
                  }`}
                >
                  <div className="flex flex-col text-xs">
                    <span>
                      {dateSetup(props?.content?.[index]?.created_at, true)}
                    </span>
                    <span>{`by ${props?.content?.[index]?.firstName} ${props?.content?.[index]?.lastName}`}</span>
                  </div>
                </div>
              )}
            />
          )}
      </Step>
    );
  });

  return (
    <div className="flex-1">
      <div className="px-5">
        <span className="text-gray-400">Order Status</span>

        <Stepper
          activeStep={props.processId}
          orientation="vertical"
          connector={<QontoConnector />}
        >
          {!isCancelled
            ? isReturnRefund
              ? isCancelledOrReturnRefund
              : isNormalStep
            : isCancelledOrReturnRefund}
        </Stepper>
      </div>
    </div>
  );
}
