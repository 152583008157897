import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "features/config/store";
import { OrderInformationModel } from "features/hublink/core/domain/tei-hub-link.model";

interface InitialState {
  data: OrderInformationModel | undefined;
}

const initialState: InitialState = {
  data: undefined,
};

export const teiHubLinkOrderDataSlice = createSlice({
  name: "teiHubLinkOrderData",
  initialState,
  reducers: {
    teiHubLinkOrderData: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

export const selectTeiHubLinkOrderData = (state: RootState) =>
  state.teiHubLinkOrderData;

export const { teiHubLinkOrderData } = teiHubLinkOrderDataSlice.actions;

export default teiHubLinkOrderDataSlice.reducer;
