import { Button, TextField } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useAppDispatch } from "features/config/hooks";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import {
  MaterialDateTimeInput,
  MaterialInput,
} from "features/shared/presentation/components";
import {
  openMessageModal,
  closeMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import { useState } from "react";
import { updateMinimumTotalAmount } from "../slices/update-minimum-total-amount.slice";
import { minimumOrderAmountParam } from "features/stock-ordering/core/stock-ordering.params";
import { MinimumTotalAmountHistory } from "../modals/minimum-total-amount-history.modal";
import { getMinimumTotalAmountHistory } from "../slices/get-minimum-total-amount-history.slice";
import { getMinimumTotalAmountData } from "../slices/get-minimum-total-amount.slice";

export function StockOrderSettingsMinimumOrderAmountContent() {
  const dispatch = useAppDispatch();
  const [openEffectivitycalendarCalendar, setOpenEffectivitycalendarCalendar] =
    useState(false);
  const [openChangeHistory, setOpenChangeHistory] = useState(false);

  const [minimumOrderAmountData, setMinimumOrderAmount] =
    useState<minimumOrderAmountParam>({
      totalAmount: "",
      effectivityDate: "",
    });

  const handleOnChange = (
    value: string | number | Date | dayjs.Dayjs | null | undefined
  ) => {
    if (value) {
      const formattedDate = dayjs(value).format("YYYY-MM-DD HH:mm:ss");

      if (dayjs(formattedDate).isValid()) {
        setMinimumOrderAmount({
          ...minimumOrderAmountData,
          effectivityDate: formattedDate ?? "",
        });
      }
    }
  };

  const handleOnClick = () => {
    dispatch(
      openMessageModal({
        message: `This process is ireversible. Are you sure you want to continue?`,
        buttons: [
          {
            color: "#CC5801",
            text: "Yes",
            onClick: () => {
              dispatch(updateMinimumTotalAmount(minimumOrderAmountData));
              dispatch(closeMessageModal());
              window.location.reload();
            },
          },
          {
            color: "#22201A",
            text: "No",
            onClick: () => {
              dispatch(closeMessageModal());
            },
          },
        ],
      })
    );
  };

  return (
    <div className="block rounded-lg bg-white p-6 text-surface shadow-secondary-1 space-y-3">
      <span className="text-secondary text-2xl font-['Bebas_Neue'] flex-1">
        Set minimum order amount
      </span>
      <div className="flex-1 space-y-3 lg:flex lg:space-y-0 lg:space-x-5">
        <div className="flex-1 w-full space-y-2 lg:w-1/3">
          <span className="flex text-md font-semibold items-center">
            Set Amount:
          </span>
          <MaterialInput
            required
            label="Amount"
            colorTheme={"black"}
            fullWidth
            value={minimumOrderAmountData?.totalAmount}
            onChange={(event) => {
              const inputValue = event.target.value;
              const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              const truncatedValue = numericValue.slice(0, 11);
              setMinimumOrderAmount({
                ...minimumOrderAmountData,
                totalAmount: truncatedValue,
              });
            }}
            name={"priceInput"}
            size="small"
          />
        </div>

        <div className="flex-1 w-full space-y-2 lg:w-1/3">
          <span className="flex text-md font-semibold items-center">
            Effectivity Date:
          </span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MaterialDateTimeInput
              label="Effectivity date"
              size="small"
              fullWidth
              onChange={(
                date: string | number | Date | dayjs.Dayjs | null | undefined
              ) => handleOnChange(date)}
              value={
                minimumOrderAmountData?.effectivityDate
                  ? dayjs(minimumOrderAmountData?.effectivityDate)
                  : null
              }
              colorTheme={"black"}
              openCalendar={openEffectivitycalendarCalendar}
              setOpenCalendar={(val) => setOpenEffectivitycalendarCalendar(val)}
            />
          </LocalizationProvider>
        </div>

        <div className="flex-1 space-y-2 w-full lg:w-1/3">
          <Button
            variant="outlined"
            size="small"
            fullWidth
            onClick={() => {
              dispatch(getMinimumTotalAmountData());
              dispatch(getMinimumTotalAmountHistory());
              setOpenChangeHistory(true);
            }}
          >
            Check change history
          </Button>
          <Button
            variant="contained"
            size="small"
            fullWidth
            sx={STOCK_ORDERING_BUTTON_STYLE}
            onClick={handleOnClick}
          >
            Update
          </Button>
        </div>
      </div>

      <MinimumTotalAmountHistory
        open={openChangeHistory}
        onClose={(value) => setOpenChangeHistory(value)}
      />
    </div>
  );
}
