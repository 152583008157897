import { useParams } from "react-router-dom";
import { HublinkHead, TeiHubLinkOrderInformationContent } from "../components";

export function TeiHubLinkOrderInformation() {
  const { trackingNum, clientType } = useParams();

  return (
    <>
      <HublinkHead
        HublinkBreadCrumbsProps={{
          home: {
            title: "Home",
            url: `/hublink/${clientType}`,
          },
          className: "lg:h-[200px]",
          pageTitles: [
            {
              name: `Order: ${trackingNum}`,
              url: `/hublink/${clientType}/order/${trackingNum}`,
            },
          ],
        }}
      />
      <div className="p-8">
        <TeiHubLinkOrderInformationContent />
      </div>
    </>
  );
}
