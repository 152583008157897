import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { RootState } from "features/config/store";
import { TeiHubLinkCreateUserParam } from "features/hublink/core/tei-hub-link.param";
import {
  teiHubLinkCreateUserRepository,
  teiHubLinkCreateUserResponse,
} from "features/hublink/data/tei-hub-link.repository";

export enum TeiHubLinkCreateUserState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: TeiHubLinkCreateUserState;
  message: string;
}

const initialState: InitialState = {
  status: TeiHubLinkCreateUserState.initial,
  message: "",
};

export const teiHubLinkCreateUser = createAsyncThunk(
  "teiHubLinkCreateUser",
  async (param: TeiHubLinkCreateUserParam, { rejectWithValue }) => {
    try {
      const response: teiHubLinkCreateUserResponse =
        await teiHubLinkCreateUserRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const teiHubLinkCreateUserSlice = createSlice({
  name: "teiHubLinkCreateUser",
  initialState,
  reducers: {
    resetTeiHubLinkCreateUser: (state) => {
      state.status = TeiHubLinkCreateUserState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teiHubLinkCreateUser.pending, (state) => {
        state.status = TeiHubLinkCreateUserState.inProgress;
      })
      .addCase(teiHubLinkCreateUser.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = TeiHubLinkCreateUserState.success;
          state.message = message;
        }
      })
      .addCase(teiHubLinkCreateUser.rejected, (state, action) => {
        state.status = TeiHubLinkCreateUserState.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectTeiHubLinkCreateUser = (state: RootState) =>
  state.teiHubLinkCreateUser;
export const { resetTeiHubLinkCreateUser } = teiHubLinkCreateUserSlice.actions;

export default teiHubLinkCreateUserSlice.reducer;
