import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { selectpopupScroll } from "features/stock-ordering/presentation/slices/popup-scroll.slice";
import { identity } from "lodash";
import { FormEvent, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  LoginHubLinkState,
  loginHubLink,
  resetLoginHubLinkState,
  selectLoginHubLink,
} from "../slices/tei-hub-link-login.slice";
import {
  selectTeiHubLinkSession,
  teiHubLinkSession,
} from "../slices/tei-hub-link-session.slice";
import { resetTeiHubLinkOrders } from "../slices/tei-hub-link-orders.slice";

interface TeiHubLinkLoginModalProps {
  open: boolean;
  onClose: () => void;
}

export function TeiHubLinkLoginModal(props: TeiHubLinkLoginModalProps) {
  const dispatch = useAppDispatch();
  const loginHubLinkState = useAppSelector(selectLoginHubLink);
  const popupModalState = useAppSelector(selectpopupScroll);

  const [identity, setIdentity] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [seePassword, setSeePassword] = useState(false);

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    dispatch(resetTeiHubLinkOrders());

    dispatch(
      loginHubLink({
        identity,
        password,
      })
    );

    props.onClose();
    e.preventDefault();
  };

  useEffect(() => {
    if (loginHubLinkState.status === LoginHubLinkState.success) {
      dispatch(teiHubLinkSession());
    }
  }, [loginHubLinkState, dispatch]);

  if (props.open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
    return null;
  }

  return (
    <div
      className={`fixed inset-0 z-30 flex items-start justify-center ${
        !popupModalState.status &&
        "overflow-auto bg-black bg-opacity-30 backdrop-blur-sm"
      } `}
    >
      <div className="mt-10 bg-white flex flex-col space-y-10 justify-center items-center">
        <div className="bg-white w-96 shadow-xl rounded p-5">
          <h1 className="text-3xl font-medium">Tei Hub Ordering</h1>
          {loginHubLinkState.message ? (
            <span
              className="text-sm"
              dangerouslySetInnerHTML={{
                __html: loginHubLinkState.message,
              }}
            />
          ) : (
            <p className="text-sm mt-1">
              Please login with your email/username and password below.
            </p>
          )}
          <form className="space-y-5 mt-5" onSubmit={handleOnSubmit}>
            <input
              type="text"
              className="w-full h-12 border border-gray-800 rounded px-3"
              placeholder="E-mail"
              value={identity}
              onChange={(e) => {
                setIdentity(e.target.value);
              }}
            />
            <div className="w-full flex items-center  border border-gray-800 rounded ">
              <input
                type={seePassword ? "text" : "password"}
                className="w-4/5 h-12 px-3"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {seePassword ? (
                <FaEye
                  onClick={() => {
                    setSeePassword(false);
                  }}
                  className="text-lg cursor-pointer ml-[25px]"
                />
              ) : (
                <FaEyeSlash
                  onClick={() => {
                    setSeePassword(true);
                  }}
                  className="text-lg cursor-pointer ml-[25px]"
                />
              )}
            </div>

            <button
              type="submit"
              className="text-center w-full bg-primary rounded-full text-white py-3 font-medium"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
