import { useQuery } from "features/config/hooks";
import {
  HublinkHead,
  TeiHubLinkOrderContent,
  TeiHubLinkPlaceOrderContent,
} from "../components";
import { TeiHubLinkOrderConfirmation } from "../components/tei-hub-link-order-confirmation-content";
import { createQueryParams } from "features/config/helpers";
import { useNavigate, useParams } from "react-router-dom";

export function TeiHubLinkCreateNewOrderPage() {
  const navigate = useNavigate();
  const query = useQuery();
  const phase = query.get("phase");
  // const clientType = query.get("clientType");

  const { clientType } = useParams();

  return (
    <>
      <HublinkHead
        HublinkBreadCrumbsProps={{
          home: {
            title: "Orders",
            url: `/hublink/${clientType}`,
          },
          className: "lg:h-[200px]",
          pageTitles: [
            {
              name: "Create new order",
              url: `/hublink/${clientType}/create-new-order?phase=${phase}`,
            },
          ],
        }}
      />
      <div className="p-3">
        {phase === "placement" && <TeiHubLinkPlaceOrderContent />}
        {phase === "confirmation" && (
          <TeiHubLinkOrderConfirmation
            orderPlaced={(orderPlaced) => {
              if (orderPlaced) {
                const queryParams = createQueryParams({});

                navigate({
                  pathname: "/hublink/shell",
                  search: queryParams,
                });
              }
            }}
          />
        )}
      </div>
    </>
  );
}
