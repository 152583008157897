import { useAppSelector } from "features/config/hooks";
import { selectTeiHubLinkOrder } from "../slices/tei-hub-link-order.slice";
import { selectTeiHubLinkSession } from "../slices/tei-hub-link-session.slice";
import { TeiHubLinkSupplierInformation } from "./tei-hub-link-supplier-information";
import { dateSetup } from "./tei-hub-link-utils";
import { useParams } from "react-router-dom";

export function TeiHubLinkStoreInformation() {
  const { clientType } = useParams();

  const orderState = useAppSelector(selectTeiHubLinkOrder);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);

  const {
    storeName,
    storeAddress,
    storeContactNumber,
    trackingNumber,
    processId,
    warehouseAddress,
    warehouseContactNumber,
    warehouseName,
    expectedDeliveryDate,
    statusName,
  } = orderState.data?.orderInformation || {};

  return (
    <>
      <div className="flex-1 space-y-2">
        <div className="flex text-xl font-semibold">
          <span>Order No.: </span>
          <span>{trackingNumber}</span>
        </div>

        <div>
          <span className="text-gray-400">Store Information</span>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className="text-normal">{storeName}</span>
              <span className="text-sm">{storeAddress} </span>
            </div>
            <div className="flex flex-col text-sm">
              <span className="text-gray-400">Contact Information</span>
              <span>{storeContactNumber}</span>
            </div>
          </div>
        </div>

        {/* {processId === 4 && teiHubLinkSessionState.data?.hublink.is_Shell && (
          <>
            <div className="flex flex-col">
              <span className="text-gray-400">Supplier Information</span>
              <span className="text-normal">{warehouseName}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-gray-400">Werehouse</span>
              <span className="text-normal">{warehouseName}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-gray-400">Expected Delivery Date</span>
              <span className="text-normal">
                {dateSetup(expectedDeliveryDate ?? "", true)}
              </span>
            </div>
            {clientType === "supplier" && (
              <div className="flex flex-col">
                <span className="text-gray-400">Order Status</span>
                <span className="text-normal">{statusName}</span>
              </div>
            )}
          </>
        )} */}
      </div>
    </>
  );
}
