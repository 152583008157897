import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Collapse,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { useEffect, useState } from "react";
import { MdExpandMore, MdOutlineFileDownload } from "react-icons/md";
import {
  TeiHubLinkSoa,
  resetTeiHubLinkSoa,
  selectTeiHubLinkSoa,
  teiHubLinkSoa,
} from "../slices/tei-hub-link-soa.slice";
import React from "react";
import { SoaPeriodData } from "features/hublink/core/domain/tei-hub-link-soa.model";
import { dateSetup } from "./tei-hub-link-utils";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { REACT_APP_DOMAIN_URL } from "features/shared/constants";
import { MaterialInputAutoComplete } from "features/shared/presentation/components";
import {
  selectTeiHubLinkSession,
  teiHubLinkSession,
} from "../slices/tei-hub-link-session.slice";
import {
  TeiHubLinkStores,
  resetTeiHubLinkStores,
  selectTeiHubLinkStores,
  teiHubLinkStores,
} from "../slices/tei-hub-link-stores.slice";
import { createQueryParams } from "features/config/helpers";
import { useParams } from "react-router-dom";

export function TeiHubLinkSoaContent() {
  const { clientType } = useParams();

  const teiHubLinkSoaState = useAppSelector(selectTeiHubLinkSoa);
  const teiHubLinkSessionState = useAppSelector(selectTeiHubLinkSession);
  const teiHubLinkStoresState = useAppSelector(selectTeiHubLinkStores);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTeiHubLinkSoa());
  }, []);

  useEffect(() => {
    if (teiHubLinkSessionState.data?.hublink.is_Shell) {
      if (
        teiHubLinkSoaState.data === undefined &&
        teiHubLinkSoaState.status !== TeiHubLinkSoa.success
      ) {
        dispatch(teiHubLinkSoa(""));
      }
    } else if (teiHubLinkSessionState.data?.hublink.is_Supplier) {
      if (
        teiHubLinkStoresState.data === undefined &&
        teiHubLinkStoresState.status !== TeiHubLinkStores.success
      ) {
        const queryStoreParams = createQueryParams({
          clientType: clientType,
        });

        dispatch(teiHubLinkStores(queryStoreParams));
      }
    }
  }, [teiHubLinkSoaState.data, teiHubLinkSessionState.data?.hublink.user_id]);

  const getAllTotalAmount = (date: string) => {
    const orders = teiHubLinkSoaState.data?.[date];

    const orderPeriod = Object.keys(orders ?? {}).map(
      (orderPeriod) => teiHubLinkSoaState.data?.[date]?.[orderPeriod]
    );

    const orderTotalAmount: number[] = [];
    const orderQuantityAmount: number[] = [];

    orderPeriod.map((period) => {
      const totalPerOrder = period?.product_information.reduce(
        (acc, cur) => acc + cur.cost * cur.product_quantity,
        0
      );

      const totalOrderedQuantity = period?.product_information.reduce(
        (acc, cur) => acc + cur.product_quantity,
        0
      );

      orderTotalAmount.push(totalPerOrder ?? 0);
      orderQuantityAmount.push(totalOrderedQuantity ?? 0);
    });

    const total = orderTotalAmount.reduce((arr, curr) => arr + curr, 0);
    const quantity = orderQuantityAmount.reduce((arr, curr) => arr + curr, 0);
    const totalOrders = orderPeriod.length;
    return { total, quantity, totalOrders };
  };

  console.log(teiHubLinkSoaState);

  return (
    <div className="space-y-3">
      <div className="flex">
        <span className="text-secondary text-xl font-['Bebas_Neue'] flex-1 lg:text-3xl">
          {"tei hub link > SOA"}
        </span>
      </div>

      {teiHubLinkSessionState.data?.hublink.is_Supplier && (
        <div className="px-5 py-5 space-y-4">
          <span>{`Select store:`}</span>
          <MaterialInputAutoComplete
            colorTheme={"black"}
            options={teiHubLinkStoresState.data?.stores ?? []}
            size="small"
            getOptionLabel={(option) => option.storeName}
            isOptionEqualToValue={(option, value) =>
              option.storeName === value.storeName
            }
            onChange={(event, selectedStore) => {
              const querySoaParam = createQueryParams({
                id: selectedStore.storeCode,
              });
              dispatch(teiHubLinkSoa(querySoaParam));
            }}
          />
        </div>
      )}

      <div className="px-5 py-5">
        {Object.keys(teiHubLinkSoaState.data ?? {}).map((SoaData, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex w-full justify-between">
                  <div className="font-bold text-base">{SoaData}</div>
                  <div>
                    <div className="flex px-20 space-x-3">
                      <div>
                        <span className="font-semibold">Total Orders: </span>
                        <span>{getAllTotalAmount(SoaData).totalOrders}</span>
                      </div>
                      <div>
                        <span className="font-semibold">Total Quantity: </span>
                        <span>{getAllTotalAmount(SoaData).quantity}</span>
                      </div>
                      <div>
                        <span className="font-semibold">Total Amount: </span>
                        <span>
                          {getAllTotalAmount(SoaData).total.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span className="font-semibold">Date</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="font-semibold">Tracking number</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="font-semibold">Store Name</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="font-semibold">
                            Total Ordered Quantity
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="font-semibold">Total Amount</span>
                        </TableCell>
                        <TableCell>
                          <span className="font-semibold">
                            Download Delivery Receipt
                          </span>
                        </TableCell>
                        <TableCell />
                        {/* 
                        🚧 currently in progress 
                      
                      <TableCell>Download SOA</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(
                        teiHubLinkSoaState.data?.[SoaData] ?? {}
                      ).map((SoaPeriodData) => (
                        <SoaProductData
                          row={
                            teiHubLinkSoaState.data?.[SoaData]?.[
                              SoaPeriodData
                            ] as SoaPeriodData
                          }
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

function SoaProductData(props: { row: SoaPeriodData }) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const getTotalOrderedProduct = row.product_information.reduce(
    (acc, curr) => acc + curr.product_quantity,
    0
  );

  const getTotalAmount = row.product_information.reduce(
    (acc, curr) => acc + curr.cost * curr.product_quantity,
    0
  );

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "none !important" } }}>
        <TableCell component="th" scope="row">
          {dateSetup(row.order_information.created_at, true)}
        </TableCell>
        <TableCell align="center">
          {row.order_information.tracking_number}
        </TableCell>
        <TableCell align="center">{row.order_information.store_name}</TableCell>
        <TableCell align="center">{getTotalOrderedProduct}</TableCell>
        <TableCell align="center">{getTotalAmount}</TableCell>
        <TableCell align="center">
          <Button
            variant="text"
            startIcon={<MdOutlineFileDownload className="text-button" />}
            onClick={() => {
              const link = `${REACT_APP_DOMAIN_URL}api/hub-link/download/updated_delivery_receipt/${row.order_information.tracking_number}`;
              window.open(link, "_blank");
            }}
          >
            <span className="text-button capitalize">Download</span>
          </Button>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginX: 1, marginY: 3 }}>
              <Typography variant="h6" gutterBottom component="div">
                Order Information
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="font-semibold">Product Sku</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-semibold">Product Quantity</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-semibold">Product Cost</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-semibold">Subtotal</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.product_information.map((productData) => (
                    <TableRow key={productData.skuCode}>
                      <TableCell component="th" scope="row">
                        {productData.skuCode}
                      </TableCell>
                      <TableCell align="center">
                        {productData.product_quantity}
                      </TableCell>
                      <TableCell align="center">{productData.cost}</TableCell>
                      <TableCell align="center">
                        {productData.sub_total}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
