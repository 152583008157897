import React from "react";
import { AllCashDepositContents } from "../components/all-cash-deposit-content";
import { SalesHead } from "../components/sales-head";

export function AllCashDepositPage() {
  return (
    <>
      <SalesHead
        SalesBreadCrumbsProps={{
          home: {
            title: "Home",
            url: "/admin/sales/cash-deposit",
          },
          className: "lg:h-[200px]",
          pageTitles: [
            { name: "Cash Deposit", url: "/admin/sales/cash-deposit" },
          ],
        }}
      />
      <div className="p-4">
        <AllCashDepositContents />
      </div>
    </>
  );
}
