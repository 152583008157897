import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import { SalesActiveFieldsModel } from "features/sales/core/domain/active-fields.model";
import { SalesAllFormDataModel } from "features/sales/core/domain/sales-all-form-data.model";
import { SubmitFormParam } from "features/sales/core/sales.param";
import { MaterialInput } from "features/shared/presentation/components";
import { updateFormState } from "./sales-utils";
import { useQuery } from "features/config/hooks";

interface SalesTaskFormDataComparisonProps {
  type: string;
  formLabel: string;
  activeStep: number;
  salesActiveFieldState: SalesActiveFieldsModel | undefined;
  salesFormStateData: SalesAllFormDataModel | undefined;
  setFormState: (prevData: SubmitFormParam["formState"] | undefined) => void;
  formState: SubmitFormParam["formState"];
}

export function SalesTaskFormDataComparison(
  props: SalesTaskFormDataComparisonProps
) {
  const query = useQuery();
  const isPending = query.get("pending");

  const filteredActiveFields = props.salesActiveFieldState?.field_data[
    props.activeStep
  ].field.map((field) =>
    field.field_data.filter(
      (fieldData) =>
        fieldData.name !== "cashier_name" &&
        fieldData.name !== "entry_date" &&
        fieldData.name !== "store" &&
        fieldData.name !== "shift" &&
        fieldData.name !== "email" &&
        fieldData.name !== "transaction_date"
    )
  );

  const dataEntryCashier = (fieldName: string) => {
    const cashierData =
      props.salesFormStateData?.cashier_data[props.activeStep];
    return cashierData?.fieldData === null
      ? 0
      : cashierData?.fieldData[fieldName];
  };

  const dataEntryTc = (fieldName: string) => {
    const cashierData = props.salesFormStateData?.tc_data[props.activeStep];
    return cashierData?.fieldData === null
      ? 0
      : cashierData?.fieldData[fieldName];
  };

  const dataEntryManager = (fieldName: string) => {
    const cashierData =
      props.salesFormStateData?.manager_data[props.activeStep];
    return cashierData?.fieldData === null
      ? 0
      : cashierData?.fieldData[fieldName];
  };

  const handleOnChange = (
    sectionName: string,
    fieldName: string,
    val: string | Date | null,
    dataType: string
  ) => {
    if (typeof val === "string") {
      if (dataType === "number") {
        val = val.replace(/[^0-9.]/g, "");
      }
    }

    props.setFormState(
      updateFormState(props.formState, { sectionName, fieldName, val })
    );
  };

  const formStateFieldValue = (sectionName: string, fieldName: string) => {
    return props.formState?.[sectionName]?.[fieldName]?.value ?? 0;
  };

  const comparisonResult = (fieldName: string) => {
    if ((dataEntryCashier(fieldName) ?? 0) === (dataEntryTc(fieldName) ?? 0)) {
      return (
        <Box
          sx={{
            fontWeight: 600,
            border: "2px solid #00FF00",
            display: "inline-block",
            borderRadius: "0.25rem",
            padding: "0.5rem 1rem",
            backgroundColor: "#00FF00",
            color: "black",
          }}
        >
          Match
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            fontWeight: 600,
            border: "2px solid #FF0000",
            display: "inline-block",
            borderRadius: "0.25rem",
            padding: "0.5rem 1rem",
            backgroundColor: "#FF0000",
            color: "black",
          }}
        >
          Mismatch
        </Box>
      );
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Field Name
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Cashier's Entry
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Team Leader's Check
            </TableCell>
            {(props.type === "manager" || props.type === "view") && (
              <>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Manager's Check
                </TableCell>
                {!isPending && (
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Comparison
                  </TableCell>
                )}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredActiveFields?.map((field) =>
            field.map((fieldData, index) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ fontWeight: "Medium" }}>
                  {fieldData.field_name}
                </TableCell>
                <TableCell align="center">
                  {(dataEntryCashier(fieldData.name) as string) ?? 0}
                </TableCell>
                <TableCell align="center">
                  {props.type === "tc" ? (
                    <MaterialInput
                      className="w-2/5"
                      colorTheme={"black"}
                      name={fieldData.name}
                      size="small"
                      value={formStateFieldValue(
                        fieldData.section_name,
                        fieldData.name
                      ).toString()}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleOnChange(
                          fieldData.section_name,
                          event.target.name,
                          event.target.value,
                          fieldData.datatype
                        )
                      }
                    />
                  ) : (
                    (dataEntryTc(fieldData.name) as string) ?? 0
                  )}
                </TableCell>
                {props.type === "manager" && (
                  <>
                    <TableCell align="center">
                      <MaterialInput
                        className="w-2/5"
                        colorTheme={"black"}
                        name={fieldData.name}
                        value={formStateFieldValue(
                          fieldData.section_name,
                          fieldData.name
                        ).toString()}
                        size="small"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleOnChange(
                            fieldData.section_name,
                            event.target.name,
                            event.target.value,
                            fieldData.datatype
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      {comparisonResult(fieldData.name)}
                    </TableCell>
                  </>
                )}
                {props.type === "view" && (
                  <>
                    <TableCell align="center">
                      {(props.type === "view" &&
                        (dataEntryManager(fieldData.name) as string)) ??
                        0}
                    </TableCell>

                    {!isPending && (
                      <TableCell align="right">
                        {comparisonResult(fieldData.name)}
                      </TableCell>
                    )}
                  </>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
