import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createQueryParams } from "features/config/helpers";
import { DataList } from "features/shared/presentation/components";
import {
  useAppDispatch,
  useAppSelector,
  useQuery,
} from "features/config/hooks";
import {
  Column,
  DataTable,
  DataTableCell,
  DataTableRow,
} from "features/shared/presentation/components/data-table";
import {
  getAllCashDeposit,
  resetGetAllCashDepositStatus,
  selectGetAllCashDeposit,
} from "../slices/get-all-cash-deposit.slice";
import { MdAssignmentAdd } from "react-icons/md";
import { List, ListItem } from "@mui/material";

// Table Columns
const columns: Array<Column> = [
  { id: "entryDate", label: "Entry Date" },
  { id: "refId", label: "Reference ID" },
  { id: "cashierName", label: "Cashier Name" },
  { id: "shift", label: "Shift" },
  { id: "cashDeposits", label: "Cash Deposits" },
];

export function AllCashDepositContents() {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const pageNo = query.get("page_no");
  const perPage = query.get("per_page");
  const status = query.get("status");
  const id = query.get("id");
  const orderBy = query.get("order_by");
  const order = query.get("order");
  const search = query.get("search");

  const getAllCashDepositStates = useAppSelector(selectGetAllCashDeposit);

  useEffect(() => {
    const query = createQueryParams({
      page_no: pageNo,
      per_page: perPage,
      status: status,
      order_by: orderBy,
      order: order,
      search: search,
    });
    dispatch(getAllCashDeposit(query));
  }, [dispatch, pageNo, status, perPage, orderBy, order, search]);

  return (
    <>
      <div className="flex flex-col px-4 lg:flex-row lg:items-end">
        <span className="text-secondary text-3xl font-['Bebas_Neue'] flex-1">
          Dashboard
        </span>
        <div className="flex flex-col space-y-1 lg:flex-row lg:space-x-4 lg:space-y-0">
          <div>
            <Link
              to="../cash-deposit/pending"
              className="inline-flex items-center px-4 tracking-wide bg-button font-['Varela_Round'] text-white py-1 text-xs rounded-md font-700"
            >
              <MdAssignmentAdd size={20} />
              <span>&nbsp;&nbsp;Pending Cash Deposit</span>
            </Link>
          </div>
        </div>
      </div>

      {getAllCashDepositStates.data?.cash_deposits ? (
        <>
          {/* Mobile View */}
          <div className="p-4 lg:hidden">
            <DataList
              search={search ?? ""}
              emptyMessage="No data yet."
              onSearch={(val) => {
                const params = {
                  page_no: null,
                  per_page: perPage,
                  status: status,
                  order_by: orderBy,
                  order: order,
                  search: val === "" ? null : val,
                };

                const queryParams = createQueryParams(params);

                navigate({
                  pathname: "",
                  search: queryParams,
                });
              }}
              onRowsPerPageChange={(event) => {
                if (perPage !== event.target.value) {
                  const params = {
                    page_no: pageNo,
                    per_page: event.target.value,
                    status: status,
                    search: search,
                  };

                  const queryParams = createQueryParams(params);

                  dispatch(resetGetAllCashDepositStatus());
                  navigate({
                    pathname: "",
                    search: queryParams,
                  });
                }
              }}
              onPageChange={(event, newPage) => {
                const pageNoInt = pageNo ? parseInt(pageNo) : null;
                if (newPage !== pageNoInt) {
                  const params = {
                    page_no: newPage,
                    per_page: perPage,
                    status: status,
                    search: search,
                  };

                  const queryParams = createQueryParams(params);

                  dispatch(resetGetAllCashDepositStatus());
                  navigate({
                    pathname: "",
                    search: queryParams,
                  });
                }
              }}
              totalRows={getAllCashDepositStates.data.pagination.total_rows}
              perPage={getAllCashDepositStates.data.pagination.per_page}
              page={pageNo ? parseInt(pageNo) : 1}
            >
              <hr className="mt-4" />

              {getAllCashDepositStates.data.cash_deposits.map((row, i) => (
                <div
                  key={i}
                  className="flex justify-between items-center px-4 py-2 border-b"
                >
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-600">
                      <strong>Entry Date:</strong> {row.entry_date}
                    </span>
                    <span className="text-xs text-gray-600">
                      <strong>Reference ID:</strong> {row.user_ref_id}
                    </span>
                    <span className="text-xs text-gray-600">
                      <strong>Entry Date:</strong> {row.entry_date}
                    </span>
                    <span className="text-xs text-gray-600">
                      <strong>Cashier Name:</strong> {row.cashier_name}
                    </span>
                    <span className="text-xs text-gray-600">
                      <strong>Shift:</strong> {row.shift}
                    </span>
                  </div>
                </div>
              ))}
            </DataList>
          </div>

          {/* Desktop View */}
          <div className="hidden p-4 lg:block">
            <DataTable
              order={order === "asc" ? "asc" : "desc"}
              orderBy={orderBy ?? "id"}
              emptyMessage="No data yet."
              search={search ?? ""}
              onSearch={(val) => {
                const params = {
                  page_no: pageNo,
                  per_page: perPage,
                  status: status,
                  order_by: orderBy,
                  order: order,
                  search: val === "" ? null : val,
                };

                const queryParams = createQueryParams(params);

                navigate({
                  pathname: "",
                  search: queryParams,
                });
              }}
              onRequestSort={(column_selected) => {
                // if (
                //   column_selected !== "actions" &&
                //   column_selected !== "dateCreated" // 👈 REMOVE THIS LINE, if okay na sa database hehe: 👤 Dale
                // ) {
                //   const isAsc = orderBy === column_selected && order === "asc";
                //   const params = {
                //     page_no: pageNo,
                //     per_page: perPage,
                //     status: status,
                //     order_by: column_selected,
                //     order: isAsc ? "desc" : "asc",
                //     search: search,
                //   };
                //   const queryParams = createQueryParams(params);
                //   dispatch(resetGetCashDepositStatus());
                //   navigate({
                //     pathname: "",
                //     search: queryParams,
                //   });
                // }
              }}
              columns={columns}
              onRowsPerPageChange={(event) => {
                if (perPage !== event.target.value) {
                  const params = {
                    page_no: pageNo,
                    per_page: event.target.value,
                    status: status,
                    order_by: orderBy,
                    order: order,
                    search: search,
                  };

                  const queryParams = createQueryParams(params);

                  dispatch(resetGetAllCashDepositStatus());
                  navigate({
                    pathname: "",
                    search: queryParams,
                  });
                }
              }}
              onPageChange={(event, newPage) => {
                const pageNoInt = pageNo ? parseInt(pageNo) : null;
                if (newPage !== pageNoInt) {
                  const params = {
                    page_no: newPage,
                    per_page: perPage,
                    status: status,
                    order_by: orderBy,
                    order: order,
                    search: search,
                  };

                  const queryParams = createQueryParams(params);

                  dispatch(resetGetAllCashDepositStatus());
                  navigate({
                    pathname: "",
                    search: queryParams,
                  });
                }
              }}
              totalRows={getAllCashDepositStates.data.pagination.total_rows}
              perPage={getAllCashDepositStates.data.pagination.per_page}
              page={pageNo ? parseInt(pageNo) : 1}
            >
              {getAllCashDepositStates.data.cash_deposits.map((row, i) => (
                <>
                  <DataTableRow key={i}>
                    <DataTableCell>{row.entry_date}</DataTableCell>
                    <DataTableCell>{row.user_ref_id}</DataTableCell>
                    <DataTableCell>{row.cashier_name}</DataTableCell>
                    <DataTableCell>{row.shift}</DataTableCell>
                    <DataTableCell>
                      <List
                        sx={{
                          listStyleType: "disc",
                          listStylePosition: "inside",
                        }}
                      >
                        <ListItem sx={{ display: "list-item" }}>
                          <strong>Snackshop: </strong>
                          {row.snackshop_cash_deposit}
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <strong>Catering: </strong>
                          {row.catering_cash_deposit}
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <strong>Reseller: </strong>
                          {row.reseller_cash_deposit}
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <strong>Bulk Wholesale Inhouse: </strong>
                          {row.bulk_wholesale_inhouse_deposit}
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <strong>Offsite Selling: </strong>
                          {row.offsite_selling_deposit}
                        </ListItem>
                      </List>
                    </DataTableCell>
                  </DataTableRow>
                </>
              ))}
            </DataTable>
          </div>
        </>
      ) : null}
    </>
  );
}
