import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { LoginHubLinkParam } from "features/hublink/core/tei-hub-link.param";
import {
  LoginHubLinkResponse,
  LoginHubLinkRepository,
} from "features/hublink/data/tei-hub-link.repository";

export enum LoginHubLinkState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: LoginHubLinkState;
  message: string;
}

const initialState: InitialState = {
  status: LoginHubLinkState.initial,
  message: "",
};

export const loginHubLink = createAsyncThunk(
  "loginHubLink",
  async (param: LoginHubLinkParam, { rejectWithValue }) => {
    try {
      const response: LoginHubLinkResponse = await LoginHubLinkRepository(
        param
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const loginHubLinkSlice = createSlice({
  name: "loginHubLink",
  initialState,
  reducers: {
    resetLoginHubLinkState: (state) => {
      state.status = LoginHubLinkState.initial;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginHubLink.pending, (state) => {
        state.status = LoginHubLinkState.inProgress;
      })
      .addCase(loginHubLink.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;

          state.status = LoginHubLinkState.success;
          state.message = message;
        }
      })
      .addCase(loginHubLink.rejected, (state, action) => {
        state.status = LoginHubLinkState.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectLoginHubLink = (state: RootState) => state.loginHubLink;
export const { resetLoginHubLinkState } = loginHubLinkSlice.actions;

export default loginHubLinkSlice.reducer;
