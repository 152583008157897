import { Button, CircularProgress } from "@mui/material";
import { STOCK_ORDERING_BUTTON_STYLE } from "features/shared/constants";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Html5Qrcode } from "html5-qrcode";

interface TeiHubLinkQrCodeReaderProps {
  open: Boolean;
  onClose: () => void;
}

export function TeiHubLinkQrCodeReader(props: TeiHubLinkQrCodeReaderProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [stopScan, setStopScan] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [decodedLink, setDecodedLink] = useState("");

  useEffect(() => {
    if (decodedLink !== "") {
      window.location.href = decodedLink;
    }
  }, [decodedLink]);

  useEffect(() => {
    if (props.open) {
      setLoading(true);
      const config = {
        fps: 10,
        qrbox: { width: 250, height: 250 },
      };

      const videoConstraint = { facingMode: "user" };

      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length) {
            const cameraId = devices[0].id;

            const html5QrCode = new Html5Qrcode("teiHubLinkQrReader", false);

            html5QrCode
              .start(
                { facingMode: "environment" },
                config,
                (decodedText, result) => {
                  setDecodedLink(decodedText);
                  html5QrCode.clear();
                },
                (errorMessage) => {
                  if (stopScan) {
                    setLoading(false);

                    html5QrCode.pause();
                    html5QrCode
                      .stop()
                      .then(() => {
                        html5QrCode.clear();
                        setStopScan(false);

                        window.location.reload();
                      })
                      .catch((err) => {
                        setLoading(false);
                        setErrorMessage(err.message);
                      });
                  }
                }
              )
              .then(() => setLoading(false))
              .catch((err) => {
                setLoading(false);
                setErrorMessage(err.message);
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage(err.message);
        });
    }
  }, [props.open, stopScan]);

  if (props.open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-30 overflow-auto backdrop-blur-sm">
      <div
        className={`w-full lg:h-fit lg:w-[700px] mt-10 border border-white bg-white rounded-md p-5 space-y-2`}
      >
        {decodedLink !== "" ? (
          <>
            {/* <Button
              variant="contained"
              fullWidth
              size="medium"
              sx={STOCK_ORDERING_BUTTON_STYLE}
              onClick={() => (window.location.href = decodedLink)}
            >
              Order Receive
            </Button> */}
            <div className="flex justify-center py-10">
              <CircularProgress />
            </div>

            <Button
              variant="contained"
              fullWidth
              size="medium"
              sx={STOCK_ORDERING_BUTTON_STYLE}
              onClick={() => window.location.reload()}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            {loading && <CircularProgress />}

            <div id="teiHubLinkQrReader"></div>
            {errorMessage !== "" && (
              <span className="text-red-500">{`Problem encountered: ${errorMessage}`}</span>
            )}

            <Button
              variant="contained"
              fullWidth
              size="medium"
              sx={STOCK_ORDERING_BUTTON_STYLE}
              onClick={() => window.location.reload()}
            >
              Cancel Scan
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
