import {
  StockOrderHead,
  StockOrderSettingsGeneralContent,
  StockOrderingTransferContent,
} from "../components";

export function TransferPage() {
  return (
    <>
      <StockOrderHead
        StockOrderBreadCrumbsProps={{
          home: {
            title: "Home",
            url: "/admin/material/dashboard",
          },
          className: "lg:h-[200px]",
          pageTitles: [
            {
              name: "Transfer stocks",
              url: "/admin/material/transfer",
            },
          ],
        }}
      />
      <div className="p-8">
        <StockOrderingTransferContent />
      </div>
    </>
  );
}
