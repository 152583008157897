import { useParams } from "react-router-dom";
import { HublinkHead, TeiHubLinkCompletedOrders } from "../components";

export function TeiHubLinkCompleted() {
  const { clientType } = useParams();

  return (
    <>
      <HublinkHead
        HublinkBreadCrumbsProps={{
          home: {
            title: "Completed Orders",
            url: `/hublink/completed/${clientType}`,
          },
          className: "lg:h-[200px]",
          pageTitles: [],
        }}
      />
      <div className="p-3">
        <TeiHubLinkCompletedOrders />
      </div>
    </>
  );
}
